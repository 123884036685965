import API_ENDPOINTS from '../constants/apiEndpoints'
import apiClient from '../services/apiClient'

import {
  STATS_UPDATE_VALUES,
  STATS_UPDATE_RECEIVE,
  GET_STATS_SUGGESTIONS,
  GET_STATS_DEVICES,
  GET_STATS_VIEWS
} from '../constants';

export const updateStats = (values) => {
  return {
    type: STATS_UPDATE_VALUES,
    values
  };
}

export const saveStats = (id, params) => (dispatch) => {
  return apiClient.patch(API_ENDPOINTS.STATS.UPDATE(id), params)
  .then(response => {
    dispatch(saveStatsReceive(response.data))
  })
}

const saveStatsReceive = (json) => {
  return {
    type: STATS_UPDATE_RECEIVE,
    json
  };
}

export const getStatsSuggestions = (params) => (dispatch) => {
  apiClient.get(API_ENDPOINTS.STATS.SUGGESTIONS, {
    params: {}
  })
  .then(response => {
    dispatch(requestGetStatsSuggestions(response.data))
  })
}

const requestGetStatsSuggestions = (json) => {
  return {
    type: GET_STATS_SUGGESTIONS,
    json
  };
}

export const getStatsViews = (params) => (dispatch) => {
  apiClient.get(API_ENDPOINTS.STATS.LOCATION_VIEWS, {
    params: {}
  })
  .then(response => {
    dispatch(requestGetStatsViews(response.data))
  })
}

const requestGetStatsViews = (json) => {
  return {
    type: GET_STATS_VIEWS,
    json
  };
}

export const getStatsDevices = (params) => (dispatch) => {
  apiClient.get(API_ENDPOINTS.STATS.DEVICES, {
    params: {}
  })
  .then(response => {
    dispatch(requestGetStatsDevices(response.data))
  })
}

const requestGetStatsDevices = (json) => {
  return {
    type: GET_STATS_DEVICES,
    json
  };
}
