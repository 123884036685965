import React, { Component } from 'react';
import { connect } from 'react-redux';

export class StatsHeader extends Component {
  render() {
    return (
      <div className="page-header clearfix">
        <div className="page-header-title">
          Analytics
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state
  }
};

export default connect(mapStateToProps)(StatsHeader);
