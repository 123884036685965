import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getStatsSuggestions, getStatsViews, getStatsDevices, updateStats, saveStats } from '../../actions/statsActions';
import LocatorSpinner from '../locator/LocatorSpinner';
import { createFlash } from '../../shared/createFlash';
import API_ENDPOINTS from '../../constants/apiEndpoints';

export class StatsPage extends Component {
  componentDidMount() {
    this.props.getStatsSuggestions()
    this.props.getStatsViews()
    this.props.getStatsDevices()
  }

  handleChangeInput = (name) => {
    return event => {
      const { target: { value } } = event;
      this.props.updateStats({ [name]: value });
    }
  }

  saveStatsForm = (e) => {
    const { currentCompany } = window.PAGEDATA
    e.preventDefault();
    e.stopPropagation();

    this.props.updateStats({
      isSaving: true
    });

    this.props.saveStats( currentCompany.id, {
      google_analytics_id: this.props.stats.googleAnalyticsId
    }).then(() => {
      createFlash('Your settings have been saved!', 'success')
    })
  }

  googleAnalyticsIdValid = () => {
    if (!this.props.stats.googleAnalyticsId || this.props.stats.googleAnalyticsId === '') return true;
    return this.props.stats.googleAnalyticsId && this.props.stats.googleAnalyticsId.includes('G-');
  }

  downloadCSV = () => {
    const url = API_ENDPOINTS.STATS.CSV;
    window.location.href = url;
  }

  get renderAutosuggests() {
    return (
      <div className="autosuggest-items">
        {this.props.stats.autosuggest_counts.map((group) => {
          const name = encodeURIComponent(group[0])
          const placesId = group[1][0].google_maps_places_id

          return (
            <a href={`https://www.google.com/maps/search/?api=1&query=${name}&query_place_id=${placesId}`} className="autosuggest-item row" key={group[0]} target="_blank">
              <div className="col-lg-9 overflow-ellipsis">
                {group[0]}
                <span className="ion-android-open autosuggest-item-link-icon"/>
              </div>
              <div className="col-lg-3">
                <div className="autosuggest-item-count">
                  {group[1].length}
                </div>
              </div>
            </a>
          )
        })}
      </div>
    )
  }

  get renderAutosuggestEmptyState() {
    return (
      <div className="autosuggest-empty-banner">
        <div>
          No search data available yet.
        </div>
      </div>
    )
  }

  get renderAutosuggestListOrEmptyState() {
    const autosuggestsExist = this.props.stats.autosuggest_counts && this.props.stats.autosuggest_counts.length
    return autosuggestsExist ? this.renderAutosuggests : this.renderAutosuggestEmptyState
  }

  get renderViews() {
    return (
      <div className="stats-viewed-items">
        {this.props.stats.location_views.map((location) => {
          return (
            <div className="stats-viewed-item row" key={location.id}>
              <div className="col-lg-9 overflow-ellipsis">
                {location.title}
                <div className="stats-viewed-item-address overflow-ellipsis">
                  {location.address_full}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="stats-viewed-item-count">
                  {location.view_count}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  get renderViewedEmptyState() {
    return (
      <div className="autosuggest-empty-banner">
        <div>
          No location data available yet.
        </div>
      </div>
    )
  }

  get renderViewedListOrEmptyState() {
    const viewsExist = this.props.stats.location_views && this.props.stats.location_views.length
    return viewsExist ? this.renderViews : this.renderViewedEmptyState
  }

  get renderDevices() {
    const { devices, devicesTotalCount } = this.props.stats;
    return (
      <div>
        <div className="devices-items">
          {Object.keys(devices).map((id, key) => {
            return (
              <div className="device-item row" key={key} target="_blank">
                <div className="col-lg-9 overflow-ellipsis">
                  {devices[id].label}
                </div>
                <div className="col-lg-3">
                  <div className="device-item-count">
                    {devices[id].percentage}%
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className="stats-index-device-counter">
          from {devicesTotalCount ? `${String(devicesTotalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : 0} total map views
        </div>
      </div>
    )
  }

  render() {
    const { isFetchingSuggestions, isFetchingDevices, isFetchingViews } = this.props.stats;

    return (
      <div className="stats-index">
        <div className="wrapper relative">
          <div className="stats-index-content">
            <div className="row">
              <div className="col-md-7 pbxl-xs pbn-md prxl-md">
                <div className="pbxl">
                  <h3>
                    Most Used Suggestions
                  </h3>
                  <div>
                    <div className="autosuggest-column-names-section">
                      <div className="autosuggest-column-names clearfix">
                        <div className="autosuggest-column-name name">Location Name</div>
                        <div className="autosuggest-column-name usage">Times Used</div>
                      </div>
                    </div>
                  </div>
                  {isFetchingSuggestions ? (
                    <div className="paxl text-center">
                      <LocatorSpinner/>
                    </div>
                  ) : this.renderAutosuggestListOrEmptyState}
                </div>
                <div>
                  <div className="stats-index-locations">
                    <h3>
                      Most Viewed Locations
                    </h3>
                    <button 
                      onClick={this.downloadCSV} 
                      className="btn btn-primary"
                      style={{ marginBottom: '15px' }}
                    >
                      Download CSV
                    </button>
                  </div>
                  <div>
                    <div className="autosuggest-column-names-section">
                      <div className="autosuggest-column-names clearfix">
                        <div className="autosuggest-column-name name">Location Name</div>
                        <div className="autosuggest-column-name usage">Times Viewed</div>
                      </div>
                    </div>
                  </div>
                  {isFetchingViews ? (
                    <div className="paxl text-center">
                      <LocatorSpinner/>
                    </div>
                  ) : this.renderViewedListOrEmptyState}
                </div>
              </div>
              <div className="col-md-5">
                <div className="pbxl">
                  <h3>
                    Device Type
                  </h3>
                  <div>
                    <div className="autosuggest-column-names-section">
                      <div className="autosuggest-column-names clearfix">
                        <div className="autosuggest-column-name name">Type</div>
                        <div className="autosuggest-column-name usage">% Used</div>
                      </div>
                    </div>
                  </div>
                  {isFetchingDevices ? (
                    <div className="paxl text-center">
                      <LocatorSpinner/>
                    </div>
                  ) : this.renderDevices}
                </div>
                <form onSubmit={this.saveStatsForm}>
                  <div>
                    <h3>
                      Google Analytics
                    </h3>
                    <div className="stats-index-ga-section">
                      <div className="pbl">
                        <p>
                          Add your Google Analytics account Tracking ID to integrate your normal website tracking into your Google Analytics interface. <a href="https://closeby.zendesk.com/hc/en-us/articles/360008368059" target="_blank">More info</a>
                        </p>
                      </div>
                      <div className="row pbl">
                        <div className="col-sm-7">
                          <input type="text" className="form-control" placeholder="G-123456789" onChange={this.handleChangeInput('googleAnalyticsId')} value={this.props.stats.googleAnalyticsId} />
                        </div>
                        <div className="col-sm-5 pll-sm">
                          <button type="submit" className="btn btn-primary btn-block" disabled={this.props.stats.isSaving}>Save</button>
                        </div>
                      </div>
                      {this.googleAnalyticsIdValid() ? (
                        <div>
                          <p>
                            This will automatically track map and location views as Pageviews with the base url path <code>/closeby/</code>.
                          </p>
                          <p>
                            Clicking a search suggestion or one of the location links (get directions, phone number, email or website) will trigger Events under the <code>Closeby-Navigation</code> category.
                          </p>
                          <p>
                            <i><small>Note: this will not track analytics while viewing your map on Closeby.  It will only track data when displayed on your website.</small></i>
                          </p>
                        </div>
                      ) : (
                        <div className="stats-index-ga-error">
                          Invalid ID: Please enter a GA4 Measurement ID in the format G-XXXXXXXXXX to enable tracking.
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state
  }
};

export default connect(mapStateToProps, { getStatsSuggestions, getStatsViews, getStatsDevices, updateStats, saveStats })(StatsPage);
