import PropTypes from 'prop-types'
import React, { Component } from 'react'
import URLS from '../../constants/urls'

export default class PaymentBannerNotice extends Component {
  render() {
    const { isFree, isTrialing, hasWixConnected } = this.props

    let bannerCopy = 'Please enter a new payment method to continue using Closeby.'

    if (isFree && hasWixConnected) {
      bannerCopy = 'Limited to 5 locations on the free plan.'
    } else if (isFree) {
      bannerCopy = 'On the free plan you are limited to 5 locations. Click here to upgrade your plan.'
    } else if (isTrialing) {
      bannerCopy = 'You\'re currently on the 14-day free trial. Click here to upgrade your plan.'
    }

    return (
      <div className="subscription-payment-banner">
        <a href={URLS.SELECT_PLAN.INDEX} className="subscription-payment-banner-link">
          { bannerCopy }
        </a>
      </div>
    )
  }
}

PaymentBannerNotice.propTypes = {
  isFree: PropTypes.bool,
  isTrialing: PropTypes.bool,
  hasWixConnected: PropTypes.bool,
}
