import WebpackerReact from 'webpacker-react'

import CardUpdate from './components/subscriptions/CardUpdate'
import IntegrationsIndex from './components/integrations/IntegrationsIndex'
import LocationsIndex from './components/locations/LocationsIndex'
import MapsIndex from './components/maps/MapsIndex'
import MapsEditor from './components/maps/MapsEditor'
import MapsEmbed from './components/maps/MapsEmbed'
import MarketingAbout from './components/marketing/MarketingAbout'
import MarketingFeatures from './components/marketing/MarketingFeatures'
import MarketingIndex from './components/marketing/MarketingIndex'
import PostsList from './components/locations/PostsList'
import StatsIndex from './components/stats/StatsIndex'
import SubscriptionsIndex from './components/subscriptions/SubscriptionsIndex'
import StripeCustomerDetails from './components/subscriptions/StripeCustomerDetails'
import Plan from './components/subscriptions/Plan'
import SelectPlan from './components/subscriptions/SelectPlan'
import Toggle from './components/subscriptions/Toggle'

WebpackerReact.setup({
  CardUpdate,
  IntegrationsIndex,
  LocationsIndex,
  MapsIndex,
  MapsEditor,
  MapsEmbed,
  MarketingAbout,
  MarketingFeatures,
  MarketingIndex,
  PostsList,
  StatsIndex,
  SubscriptionsIndex,
  StripeCustomerDetails,
  Plan,
  SelectPlan,
  Toggle
})
