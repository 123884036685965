import React, { useState } from "react";
import PropTypes from "prop-types";
import Plan from './Plan';
import Toggle from "./Toggle";

const SelectPlan = ({
  currentPlan = '',
}) => {
  const [isMonthly, setIsMonthly] = useState(true);

  const handleToggleChange = () => {
    setIsMonthly(!isMonthly);
  };

  const currentPlanMonthly = currentPlan.includes('month');
  const selectedPlan = (isMonthly ? currentPlanMonthly : !currentPlanMonthly);

  return (
    <div className="subscription-plans">
      <div className="subscription-plans__title">
        Billing & Payment
        <div className="subscription-plans__title-toggle">
          Switch to annual billing
          <Toggle
            checked={!isMonthly}
            setChecked={setIsMonthly}
            onChange={handleToggleChange}
          />
        </div>
      </div>
      <div className="subscription-plans__options">
        <Plan
          type='Up to 100 Locations'
          num_locations='100'
          price={isMonthly ? 9.99 : 99.99}
          monthly={isMonthly}
          selectedPlan={selectedPlan && currentPlan.includes('100')}
        />
        <Plan
          type='10,000 Locations'
          num_locations='10k'
          price={isMonthly ? 19.99 : 199.99}
          monthly={isMonthly}
          selectedPlan={selectedPlan && currentPlan.includes('10k')}
        />
      </div>
    </div>
  );
};

SelectPlan.propTypes = {
  currentPlan: PropTypes.string
};

export default SelectPlan;
