import PropTypes from "prop-types"
import React, { Component } from "react"

function StripeNotLoaded () {
  return (
    <div className='text-danger'>
      Could not load Stripe.js. If you have a browser extension blocking scripts please disable it.
    </div>
  )
}

const style = {
  base: {
    color: '#32325d',
    lineHeight: '18px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
}

export default class CcInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingPromo: false,
      coupon: ''
    }
  }

  get hasStripe () {
    return (typeof window.Stripe !== 'undefined')
  }

  componentDidMount () {
    if (this.hasStripe) {
      this.stripe = new Stripe(process.env.STRIPE_PUB_KEY)
      const elements = this.stripe.elements()
      this.card = elements.create('card', {style})
      this.card.mount(this.cardEl)
    }
  }

  handleInputChange = (name) => {
    return event => {
      this.setState({ [name]: event.target.value });
    }
  }

  togglePromoCode = () => {
    this.setState({ showingPromo: !this.state.showingPromo })
  }

  get renderPromoCode () {
    const { currentCard } = this.props
    const { showingPromo } = this.state

    if (currentCard && Object.keys(currentCard).length) return

    return (
      <div className="subscription-cc-promo-code">
        {showingPromo ? (
          <label className="form-control-label-with-icon">
            <i className="ion-pricetag form-control-icon"></i>
            <input
              type="text"
              className="form-control with-icon"
              placeholder="Promo Code"
              value={this.state.coupon}
              onChange={this.handleInputChange('coupon')}
            />
          </label>
        ) : (
          <a
            href="javascript:void(0)"
            className="subscription-cc-promo-link"
            onClick={this.togglePromoCode}
          >
            Add Promo Code
          </a>
        )}

      </div>
    )
  }

  onCtaClick (evt) {
    evt.preventDefault()
    const { onSubmit } = this.props
    const { isSubmitting } = this.state
    if (isSubmitting) {
      console.error('Skipping onCtaClick already submitting')
      return
    }

    this.setState({ isSubmitting: true })
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        this.setState({ errorMessage: result.error.message })
      }
      this.setState({ errorMessage: null })
      onSubmit(result, this.state.coupon)
    })
  }

  render() {
    const { ctaText, onSubmit, subTitle } = this.props
    const { errorMessage, isLoading, isSubmitting } = this.state

    if (!this.hasStripe) return <StripeNotLoaded />

    return (
      <div className="subscription-cc-wrapper">
        { errorMessage ? (
          <div className="text-danger" role="alert">
            { errorMessage }
          </div>
        ) : (
          <label htmlFor="card-element" className="subscription-cc-label">
            <div className="mbx">
              Enter a credit or debit card.
            </div>
            { subTitle }
          </label>
        ) }

        <div ref={(node) => this.cardEl = node}>
        </div>

        {this.renderPromoCode}

        <div className="subscription-cc-cta">
          <a className="btn btn-primary btn-block" href="javascript:void(0)" onClick={this.onCtaClick.bind(this)} disabled={isSubmitting}>
            {isSubmitting ? 'Loading...' : ctaText}
          </a>
        </div>
      </div>
    )
  }
}

CcInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  ctaText: PropTypes.string.isRequired,
  currentCard: PropTypes.object,
}
