import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Provider } from 'react-redux';

import store, { stateInitializer } from '../../store';

import MapsEditorForm from './MapsEditorForm';

export default class MapsEditor extends Component {
  constructor(props) {
    super(props);

    stateInitializer({
      mapsEditor: {
        ...props,
        categories_attributes: []
      }
    });
  }

  componentDidMount() {
    Track.event('Map theming form viewied')
  }

  render() {
    return (
      <Provider store={store}>
        <MapsEditorForm />
      </Provider>
    );
  }
}

MapsEditor.propTypes = {
  auto_refresh: PropTypes.bool.isRequired,
  button_setting: PropTypes.number.isRequired,
  button_style: PropTypes.string.isRequired,
  default_viewport_sw_longitude: PropTypes.number,
  default_position: PropTypes.string,
  default_viewport_label: PropTypes.string,
  default_viewport_sw_longitude: PropTypes.number,
  default_viewport_sw_latitude: PropTypes.number,
  default_viewport_ne_longitude: PropTypes.number,
  default_viewport_ne_latitude: PropTypes.number,
  enable_location_cluster: PropTypes.bool.isRequired,
  enable_single_finger_scroll: PropTypes.bool.isRequired,
  categories: PropTypes.array,
  css_overrides: PropTypes.object.isRequired,
  font_family: PropTypes.string,
  hide_location_categories: PropTypes.bool.isRequired,
  hours_format: PropTypes.string.isRequired,
  mapId: PropTypes.number.isRequired,
  launcher_text: PropTypes.string.isRequired,
  list_style: PropTypes.string.isRequired,
  location_detail_preference: PropTypes.string.isRequired,
  location_reposition: PropTypes.bool.isRequired,
  marker_background_url: PropTypes.string,
  marker_style: PropTypes.string.isRequired,
  mobile_style: PropTypes.string.isRequired,
  open_as_new_tab: PropTypes.bool.isRequired,
  primary_color: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  results_limit: PropTypes.number.isRequired,
  s3MarkerFormData: PropTypes.object.isRequired,
  s3MarkerPostUrl: PropTypes.string.isRequired,
  s3MarkerPostHost: PropTypes.string.isRequired,
  scroll_zoom_enabled: PropTypes.bool.isRequired,
  show_closed_days: PropTypes.string.isRequired,
  show_fullscreen: PropTypes.bool.isRequired,
  show_results_summary: PropTypes.bool.isRequired,
  shopify_launcher_script_required: PropTypes.bool.isRequired,
  style: PropTypes.string.isRequired,
  suggestion_countries: PropTypes.array.isRequired,
  suggestion_range: PropTypes.string.isRequired,
  suggestion_strict: PropTypes.string.isRequired,
  suggestion_type: PropTypes.string.isRequired,
  text_overrides: PropTypes.object.isRequired,
  mapbox_client_key: PropTypes.string.isRequired,
  hasWixConnected: PropTypes.bool.isRequired,
};
