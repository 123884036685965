import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SUPPORT_EMAIL } from '../../constants'
import URLS from '../../constants/urls'

export default class ImportCsvInstructions extends Component {
  state = {
    disabled: true
  }

  handleFileChange = () => {
    if (this.fileInput && this.fileInput.value.length) {
      this.setState({ disabled: false })
    }
  }

  get renderImportButton () {
    const { showUploadForm, someAreProcessing } = this.props
    return (
      someAreProcessing ?
      <div className="text-muted">Processing...</div> :
      <a className="btn btn-default" href="javascript:void(0)" onClick={showUploadForm}>
        Import New CSV File
      </a>
    )
  }

  get renderCsvUploadForm () {
    const { importTasks } = this.props
    const buttonDisabled = !this.fileInput || this.fileInput.value.length === 0;

    return (
      <form onSubmit={(e) => this.uploadFileForImport(e)}>
        <label className="form-label integrations-form-label">
          Select a file to upload:
        </label>
        {importTasks.length ? (
          <div className="integrations-form-warning">
            <b>Warning:</b> If you've made any changes to your locations on the website, be sure to re-download your updated <a href={URLS.INTEGRATIONS.CSV_TEMPLATE} target="_blank">CSV template</a> before editing. Otherwise, you risk overwriting or deleting your updated locations.
          </div>
        ) : ''}
        <div className="row">
          <div className="col-sm-8">
            <input ref={(node) => this.fileInput = node} onChange={this.handleFileChange} type="file" className="form-control" />
          </div>
          <div className="col-sm-4 text-right pll-sm">
            <button type="submit" className="btn btn-block btn-primary" disabled={this.state.disabled}>Import CSV</button>
          </div>
        </div>
      </form>
    )
  }

  get renderForm () {
    const { isUploading } = this.props
    return (
      <div className="integrations-import-file-section">
        { isUploading ? (
          <div className="text-muted text-center">
            Uploading CSV file...
          </div>
        ) : this.renderCsvUploadForm }
      </div>
    )
  }

  uploadFileForImport (event) {
    event.preventDefault()
    this.props.onFilePicked(this.fileInput)
  }


  render () {
    const { showUploadForm, hidingUploadForm } = this.props
    return (
      <div>
        <h1>
          CSV Import
        </h1>
        <div className="page-section-note">
          Follow the instructions below to import your locations with CSV.<br/>Limited to 10,000 locations at this time.
        </div>
        <div className="integrations-instructions">
          <div className="csv-instructions-list-item">
            <div className="csv-instructions-list-item-number">
              1.
            </div>
            Download your <a href={URLS.INTEGRATIONS.CSV_TEMPLATE} target="_blank">CSV template</a> with your existing locations or download the <a href={URLS.INTEGRATIONS.CSV_TEMPLATE_SAMPLE} target="_blank">sample CSV template</a>.
          </div>
          <div className="csv-instructions-list-item">
            <div className="csv-instructions-list-item-number">
              2.
            </div>
            In a spreadsheet app (Google Sheets, Excel, etc), open the downloaded file and edit the locations.
            <div className="csv-instructions-list-item-notice">
              Note: Do not edit the id column. For new locations, leave the id column blank.
            </div>
          </div>
          <div className="csv-instructions-list-item">
            <div className="csv-instructions-list-item-number">
              3.
            </div>
            When you're done editing the CSV, export the file (make sure you export the file in a CSV format) and import it below.
          </div>
          <div className="mtl">
            { hidingUploadForm ?  this.renderImportButton : this.renderForm }
          </div>
        </div>
      </div>
    )
  }
}

ImportCsvInstructions.propTypes = {
  importTasks: PropTypes.array,
  hidingUploadForm: PropTypes.bool.isRequired,
  onFilePicked: PropTypes.func.isRequired,
  showUploadForm: PropTypes.func.isRequired
}
