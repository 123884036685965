import PropTypes from 'prop-types';
import React, { Component } from 'react';
import orderby from 'lodash.orderby';
import Select from 'react-select';

import { hoursList } from '../../shared/hoursList';

function isLocationHoursEqual (locationHour1, locationHour2) {
  return (
    locationHour1.day === locationHour2.day &&
    locationHour1.time_open === locationHour2.time_open &&
    locationHour1.time_close === locationHour2.time_close
  )
}

class HoursPicker extends Component {
  constructor (props) {
    super(props)

    this.state = {
      day: this.daysOptions()[1],
      timeOpen: this.hoursOptions('timeOpen')[18],
      timeClose: this.hoursOptions('timeClose')[34],
    }
  }

  daysOptions() {
    return Object.keys(days).map((dayKey) => {
      const label = days[dayKey]
      const value = dayKey

      return {
        value: parseInt(value, 10),
        label: label,
        type: 'day'
      }
    })
  }

  hoursOptions(type) {
    return Object.keys(hoursList).map((hourKey) => {
      const label = hoursList[hourKey]
      const value = hourKey

      return {
        value: value,
        label: label,
        type: type
      }
    })
  }

  onAddHoursChange = (selectedOption) => {
    this.setState({ [selectedOption.type]: selectedOption })
  }

  setError (error) {
    console.error(error)
    this.setState({
      error: error.message || 'Something went wrong'
    })
  }

  addHours = () => {
    this.setState({error: null})
    const { day, timeOpen, timeClose } = this.state
    const { locationHours = [] } = this.props
    const newHours = {
      day: day.value,
      time_open: timeOpen.value,
      time_close: timeClose.value
    }
    const alreadyHave = locationHours.filter((locationHour) => {
      return isLocationHoursEqual(locationHour, newHours)
    })
    if (alreadyHave.length) {
      return this.setError(new Error('Those hours have already been added.'))
    }
    locationHours.push(newHours)
    this.props.onChange({
      location_hours: locationHours
    })
  }

  removeLocationHour (locationHour) {
    this.setState({error: null})
    const { locationHours = [] } = this.props
    if (locationHour.id) {
      locationHour._destroy = 1
    } else {
      const _locationHours = locationHours.filter((_locationHour) => {
        return !isLocationHoursEqual(_locationHour, locationHour)
      })
      this.props.onChange({
        location_hours: _locationHours
      })
    }
  }

  renderHoursList() {
    let { locationHours = [] } = this.props
    locationHours = orderby(locationHours, ['day', 'time_open']).filter((lh) => !lh._destroy)


    if (locationHours.length) {
      return (
        <div className="location-edit-form-hours-list">
          <div>
            {locationHours.map((locationHour, index) => {
              return (
                <div key={index} className="location-edit-form-hours-item">
                  <span style={{paddingRight: '10px'}}>{days[locationHour.day]}</span>
                  <div className="location-edit-form-hours-item-time">
                    {hoursList[locationHour.time_open]} - {hoursList[locationHour.time_close]}
                  </div>
                  <a href='javascript:void(0)' onClick={(e) => this.removeLocationHour(locationHour)} className="location-edit-form-hours-item-remove">
                    <i className="ion-close"></i>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <div className="location-edit-form-no-hours">
          No hours added yet.
        </div>
      )
    }


  }

  render () {
    const { day, timeOpen, timeClose, error } = this.state

    return (
      <div>
        { error ? <div className='form-error-message'>{error}</div> : ''}

        {this.renderHoursList()}

        <div className="row">
          <div className="col-3 prx">
            <Select
              name="day"
              className="dropdown-sm dropdown-select"
              options={this.daysOptions()}
              value={day}
              onChange={this.onAddHoursChange}
              clearable={false}
              isSearchable={true}
              menuShouldScrollIntoView
              menuPlacement={'auto'}
            />
          </div>
          <div className="col-3 prx">
            <Select
              name="timeOpen"
              className="dropdown-sm dropdown-select"
              options={this.hoursOptions('timeOpen')}
              value={timeOpen}
              onChange={this.onAddHoursChange}
              clearable={false}
              isSearchable={true}
              menuShouldScrollIntoView
              menuPlacement={'auto'}
            />
          </div>
          <div className="col-3 prx">
            <Select
              name="timeClose"
              className="dropdown-sm dropdown-select"
              options={this.hoursOptions('timeClose')}
              value={timeClose}
              onChange={this.onAddHoursChange}
              clearable={false}
              isSearchable={true}
              menuShouldScrollIntoView
              menuPlacement={'auto'}
            />
          </div>
          <div className="col-3 pls">
            <a
              href="javascript:void(0)"
              className="btn btn-sm btn-block btn-primary"
              onClick={this.addHours}
            >Add hours</a>
          </div>
        </div>
      </div>
    )
  }
}

const days = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}

export default HoursPicker
