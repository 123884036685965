export const createFlash = (message, type = 'error') => {
  let classNames = 'alert ';
  switch (type) {
    case 'notice':
      classNames += 'alert-notice';
    case 'success':
      classNames += 'alert-success';
    case 'error':
      classNames += 'alert-error';
  }

  let html = `<div class='${classNames}'><a class='alert-close-icon ion-ios-close-empty' data-dismiss='alert' href='javascript:void(0)'></a>${message}</div>`

  if($('.alert').length === 0){
    $('body').append(html);
    setTimeout(function() {
      $('.alert').addClass('showing');
    },10);
    setTimeout(function() {
      $('.alert').addClass('hiding');
      setTimeout(function() {
        $('.alert').remove();
      }, 500);
    }, 5000);
  }
}
