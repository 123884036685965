import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import accordionUp from 'images/locations/up.png';
import accordionDown from 'images/locations/down.png';
import uploadIcon from 'images/locations/upload.png';
import deleteButton from 'images/locations/delete.png';

const MAX_IMAGES = 25;

const LocationEditSecondaryImages = ({
  hasImages,
  imageCounts,
  images,
  onImagesUpload,
  showing,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [hasSecondaryImages, setHasSecondaryImages] = useState(false);
  const [imageCount, setImageCount] = useState(imageCounts);
  const [imageArray, setImageArray] = useState(images);
  const fileInputRef = useRef(null);

  useEffect(() => { 
    setHasSecondaryImages(hasImages > 0);
    setImageCount(imageCounts);
    setImageArray(images);
  }, [showing]);

  const handleAddSecondaryImages = () => {
    setHasSecondaryImages(true);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    
    if (imageArray.length + files.length > MAX_IMAGES) {
      const availableSlots = MAX_IMAGES - imageArray.length;

      alert(`You can only add ${availableSlots} more ${availableSlots === 1 ? 'image' : 'images'}.`);
      files.splice(availableSlots);
    }
  
    if (files.length > 0) {
      const newImageArray = Array.prototype.slice.call(files);
      let imageSignedIdArray = imageArray.map((image) => (image.signed_id ? image.signed_id : image));
      imageSignedIdArray.push(...newImageArray);
  
      const imageObjects = newImageArray.map((file) => ({
        file: file,
        url: URL.createObjectURL(file),
      }));
  
      setImageArray(imageArray.concat(imageObjects));
      setImageCount(imageArray.length + imageObjects.length);
      onImagesUpload({ secondary_images: imageArray.concat(imageObjects) });
    }
  };

  const handleRemoveImage = (id, index) => {
    const newImages = imageArray.filter((image, i) => (
      (id !== undefined)
      ? (image.signed_id !== id)
      : (i !== index)
    ));

    setImageArray(newImages);
    setImageCount(imageCount - 1);
    onImagesUpload({ secondary_images: newImages });
  };

  return (
    <div className="location-edit-form__secondary-images">
      {hasSecondaryImages ? (
        <div className="secondary-images__content">
          <div className="secondary-images__header">
            <span className="secondary-images__title">Secondary Images</span>
            <img
              className="secondary-images__toggle-icon"
              src={isAccordionOpen ? accordionUp : accordionDown} 
              onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            />
          </div>
          {isAccordionOpen && (
            <>
              <div className="secondary-images__upload-section">
                <a className="secondary-images__upload-button" onClick={handleUploadClick}>
                  <img src={uploadIcon} />
                  <span className="secondary-images__upload-button-text">Upload images</span>
                </a>
                <input
                  type="file"
                  ref={fileInputRef}
                  multiple
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  disabled={imageCount >= MAX_IMAGES}
                  accept="image/*"
                />
                <span className="secondary-images__image-count">
                  {imageCount}/{MAX_IMAGES}
                </span>
              </div>
              <div className="secondary-images__images">
                {imageArray.map((image, index) => (
                  <div key={index} className="secondary-images__images-border">
                    <div className="secondary-images__image-container">
                      <img src={image.url} alt={image.filename} className="secondary-images__image" />
                      <img src={deleteButton} className='secondary-images__delete-icon' onClick={() => handleRemoveImage(image.signed_id, index)} />
                    </div>
                    <div className="secondary-images__images-filename">
                      {image.filename}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className="location-edit__secondary-images--enable"
          onClick={handleAddSecondaryImages}
          style={{ cursor: 'pointer' }}
        >
          Enable secondary images
        </div>
      )}
    </div>
  );
};

LocationEditSecondaryImages.propTypes = {
  hasImages: PropTypes.bool,
  imageCounts: PropTypes.number,
  images: PropTypes.array,
  onImagesUpload: PropTypes.func,
  showing: PropTypes.bool,
};

export default LocationEditSecondaryImages;
