import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SUPPORT_EMAIL } from '../../constants';

export default class MarketingIndex extends Component {
  state = {
    styleSelected: 'basic'
  }

  componentDidMount() {
    Track.event('Home page viewed');
  }

  toggleStyle = (style) => {
    return () => {
      this.setState({ styleSelected: style })
    }
  }

  render() {
    return (
      <section className="home-widget-section">
        <div className="wrapper">
          <div className="row">
            <div className="col-md-7">
              <div className="home-widget-style-preview">
                <div className="home-widget-styles row">
                  <div className={`home-widget-style ${this.state.styleSelected === 'basic' ? 'active' : ''}`} onClick={this.toggleStyle('basic')}>
                    Basic List
                  </div>
                  <div className={`home-widget-style ${this.state.styleSelected === 'image' ? 'active' : ''}`} onClick={this.toggleStyle('image')}>
                    Image List
                  </div>
                  <div className={`home-widget-style ${this.state.styleSelected === 'column' ? 'active' : ''}`} onClick={this.toggleStyle('column')}>
                    2-Column List
                  </div>
                </div>
                {this.state.styleSelected === 'basic' && (
                  <div className="home-widget-graphic">
                    <div className="home-widget-graphic-in basic">
                    </div>
                  </div>
                )}
                {this.state.styleSelected === 'image' && (
                  <div className="home-widget-graphic">
                    <div className="home-widget-graphic-in image">
                    </div>
                  </div>
                )}
                {this.state.styleSelected === 'column' && (
                  <div className="home-widget-graphic">
                    <div className="home-widget-graphic-in column">
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div className="home-widget-content">
                <div className="home-widget-header">
                  From basic stockists to advanced customizations.
                </div>
                <div className="home-widget-desc">
                  <p>
                    Along with a variety of map themes to choose from, you can change the entire layout of your map experience.
                  </p>
                  <p>
                    No need for the web developer or designer. Follow the step-by-step instructions and you can have a powerful tool in minutes.
                  </p>
                </div>
                <div className="home-widget-cta">
                  <a href={this.props.signupPath} className="btn btn-primary">Get Started Free</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

MarketingIndex.propTypes = {
  signupPath: PropTypes.string.isRequired
};
