import React from "react"
import PropTypes from 'prop-types'
import classNames from "classnames";

import checkmarkIcon from 'images/subscription/Icon.png';

const Plan = ({
  type,
  num_locations,
  price,
  monthly,
  disable = false,
  changePlan = false,
  selectedPlan = false,
}) => {
  const handleButtonClick = () => {
    const url = `/subscriptions?plan=loc-${num_locations}-${monthly ? 'month' : 'annual'}-service`;
    window.location.href = url;
  };

  const handlePlanChange = () => {
    const url = '/subscriptions/select_plan';
    window.location.href = url;
  };

  return (
    <div className="subscription-plan">
      <div>
        {changePlan && <div className="subscription-plan__current">Current:</div>}
        <div className="subscription-plan__title">{type}</div>
      </div>
      <div className="subscription-plan__divider"></div>
      <div className="subscription-plan__info">
        <div className="subscription-plan__info-div">
          <img className="subscription-plan__info-img" src={checkmarkIcon} alt="checkmark" />
          <p className="subscription-plan__info-text1">
            Full functionality for up to
            <span className="subscription-plan__info-text1--bold"> {num_locations} locations.</span>
          </p>
        </div>
        <p className="subscription-plan__info-text2">
          Take advantage of all customizations, embed options and bulk uploading.
        </p> 
        <div className="subscription-plan__info-text2">
          If you haven't already, add a&nbsp;
          <span className="subscription-plan__info-text2">
            <a href="https://www.mapbox.com/" target="_blank">
              Mapbox access token
            </a>
          </span> 
          &nbsp;on the&nbsp;
          <span className="subscription-plan__info-text2">
            <a href="/maps/install" target="_blank">
              Install page
            </a>
          </span>
          &nbsp;to activate your map - it's free for 50k loads per month.
        </div>
      </div>
      <div className="subscription-plan__divider"></div>
      <div className="subscription-plan__price-container">
        <div className="subscription-plan__price">
          <div className="subscription-plan__price-text">${price}/{monthly ? 'month' : 'year'}</div>
          <a 
            onClick={handleButtonClick}
            className={classNames({
              'subscription-plan__price-button--selected' : selectedPlan,
              'subscription-plan__price-button' : !selectedPlan,
              'subscription-plan__price-button--disabled' : disable,
            })}
          >
            <div 
              className={classNames({
                'subscription-plan__price-button-text' : !selectedPlan,
                'subscription-plan__price-button-text--selected' : selectedPlan
              })}
            >
              {selectedPlan ? 'Applied' : 'Select'}
            </div>
          </a>

          {changePlan && (
            <a onClick={handlePlanChange} className='subscription-plan__price-button'>
              <div className="subscription-plan__price-button-text">Change</div>
            </a>
          )}
        </div>
        <div 
          className={classNames({
            'subscription-plan__discount' : !disable,
            'subscription-plan__discount--disabled' : disable
          })}
        >
          {monthly ? 'Or save 17% with annual billing' : "Saving 17% with annual billing" }
        </div>
      </div>
    </div>
  );
};

Plan.propTypes = {
  type: PropTypes.string.isRequired,
  num_locations: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  monthly: PropTypes.bool.isRequired,
  disable: PropTypes.bool,
  changePlan: PropTypes.bool,
  selectedPlan: PropTypes.bool,
};

export default Plan;
