import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import apiClient from '../services/apiClient'
import API_ENDPOINTS from '../constants/apiEndpoints';
import {
  MAPS_EDITOR_UPDATE,
  MAPS_EDITOR_SAVE
} from '../constants';

export const updateMapsEditor = (values) => {
  return {
    type: MAPS_EDITOR_UPDATE,
    values
  };
}

export const saveMapEditor = (id, params) => (dispatch) => {
  let mapParams = Object.assign({}, params.map, { googleFontFamilies: null });

  if (
    mapParams.text_overrides &&
    mapParams.text_overrides['no_locations_json'] &&
    mapParams.text_overrides['no_locations_json'].hasOwnProperty('_immutable')
  ) {
    let wysiwygContent = mapParams.text_overrides['no_locations_json'].getCurrentContent();
    mapParams.text_overrides['no_locations_json'] = convertToRaw(wysiwygContent);
    mapParams.text_overrides['no_locations'] = draftToHtml(mapParams.text_overrides['no_locations_json']);
  } else if (
    mapParams.text_overrides &&
    mapParams.text_overrides['no_locations'] &&
    mapParams.text_overrides['no_locations'].replace(/<\/?[^>]+(>|$)/g, '').length > 0
  ) {
    mapParams.text_overrides['no_locations_json'] = mapParams.text_overrides['no_locations_json'];
    mapParams.text_overrides['no_locations'] = mapParams.text_overrides['no_locations']
  } else {
    mapParams.text_overrides['no_locations_json'] = null;
    mapParams.text_overrides['no_locations'] = null;
  }

  if (mapParams.suggestion_countries && Array.isArray(mapParams.suggestion_countries)) {
    mapParams.suggestion_countries = mapParams.suggestion_countries.join(',');
  }

  return apiClient
    .patch(API_ENDPOINTS.MAPS_EDITOR.UPDATE(id), { map: mapParams })
    .then((resp) => resp.data)
}

export const saveMapboxKey = (id, params) => {
  let mapParams = Object.assign({}, params.map, { googleFontFamilies: null });

  return apiClient
    .patch(API_ENDPOINTS.MAPS_EDITOR.UPDATE(id), { map: mapParams })
    .then((resp) => resp.data)
}

export const fetchGoogleFonts = key => (dispatch) => {
  return apiClient.get(API_ENDPOINTS.GOOGLE_FONTS.INDEX(key))
  .then((resp) => {
    const json = resp.data
    if (!json || !json.items) return null;

    const families = json.items.map(item => {
      return {
        label: item.family,
        value: item.family,
        files: item.files
      }
    })

    dispatch(updateMapsEditor({ googleFontFamilies: families }))
    return json
  })
}
