import React, { Component } from 'react';
import URLS from '../../constants/urls'

export class MapsInstallHeader extends Component {
  render() {
    return (
      <div className="page-header clearfix">
        <div className="page-header-title">
          Map Installation
        </div>
        <div className="page-header-actions">
          <a href={URLS.MAPS.INDEX} className="btn btn-primary float-right">Back to Map Dashboard</a>
          <a href={URLS.FAQ.SETUP} target="_blank" className="btn btn-default float-right mrs">View FAQ</a>
          <a href={URLS.MARKETING.FEATURES} className="page-header-link nth-link" target="_blank">
            <i className="page-header-link-icon ion-help-buoy"></i>Features
          </a>
        </div>
      </div>
    );
  }
}

export default MapsInstallHeader
