import PropTypes from 'prop-types'
import imgixUrl from '../../services/imgixUrl'
import React, { PureComponent } from 'react'

import { createFlash } from "../../shared/createFlash";

export class LocationBannerUploader extends PureComponent {
  state = {
    uploading: false
  }

  componentDidMount() {
    const { name, s3FormData, s3PostUrl, s3PostHost, onBannerChange, fileUrl } = this.props;

    $(this.fileInput).fileupload({
      fileInput: this.fileInput,
      url: s3PostUrl,
      type: 'POST',
      autoUpload: true,
      maxFileSize: 10000000,   // 10 MB
      formData: s3FormData,
      paramName: 'file',      //  S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType: 'XML',        //  S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      start: (e) => {
        this.setState({ uploading: true });
      },
      submit: function (e, data) {
        $.each(data.files, function(i, file) {
          file.uploadName = encodeURI(file.name.replace(/\s+/g, '_').replace(/\+/g, '-'));
        });
      },
      done: (e, data) => {
        //  Extract key and generate URL from response
        const uploaderKey = s3FormData.key.replace('${filename}', '');
        let key = $(data.jqXHR.responseXML).find('Key').text();
        const url = encodeURI(`//${s3PostHost}/${key}`);
        const filename = key.replace(uploaderKey, '');

        this.setState({ uploading: false });
        onBannerChange({ [name]: url });
      },
      fail: (e, data) => {
        createFlash('Sorry there was a problem uploading your image.', 'error')
      }
    });
  }

  handleRemoveBackground = (e) => {
    const { name, onRemoveBanner } = this.props;

    e.preventDefault()
    onRemoveBanner({ [name]: '' })
    this.fileInput.value = ''
  }

  render() {
    const { name, fileUrl } = this.props;

    return (
      <div className={`pages-edit-banner-image ${ fileUrl ? 'with-image' : '' }`}>
        {this.props.fileUrl && this.props.fileUrl.length ? (
          <a
            href="javascript:void(0)"
            className="pages-edit-banner-image-delete clearfix"
            onClick={ this.handleRemoveBackground }
          >
            <span className="ion-android-close"></span>
          </a>
        ) : (
          <div className="pages-edit-banner-image-placeholder">
            Upload an image.
            <div className="pages-edit-banner-image-placeholder-line2">
              Original dimensions will be shown in your widget.
            </div>
          </div>
        )}
        <a
          href="javascript:void(0)"
          className="pages-edit-banner-image-display clearfix"
          onClick={() => { this.fileInput.click() }}
          style={{ backgroundImage: `url(${imgixUrl(fileUrl, {w: 600})})` }}
        >
        </a>
        {this.state.uploading ? (
          <div className="pages-edit-banner-image-uploading">
            <div className="pages-edit-banner-image-uploading-copy">Uploading...</div>
          </div>
        ) : ''}
        <input
          type="file"
          name={ name }
          className="hidden"
          ref={(input) => { this.fileInput = input }}
        />
      </div>
    );
  }
}

LocationBannerUploader.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  s3FormData: PropTypes.object.isRequired,
  s3PostUrl: PropTypes.string.isRequired,
  s3PostHost: PropTypes.string.isRequired,
  onBannerChange: PropTypes.func.isRequired,
  onRemoveBanner: PropTypes.func
}

export default LocationBannerUploader;
