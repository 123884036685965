export const hoursList = {
  '00:00': '12AM',
  '00:15': '12:15AM',
  '00:30': '12:30AM',
  '00:45': '12:45AM',
  '01:00': '1AM',
  '01:15': '1:15AM',
  '01:30': '1:30AM',
  '01:45': '1:45AM',
  '02:00': '2AM',
  '02:15': '2:15AM',
  '02:30': '2:30AM',
  '02:45': '2:45AM',
  '03:00': '3AM',
  '03:15': '3:15AM',
  '03:30': '3:30AM',
  '03:45': '3:45AM',
  '04:00': '4AM',
  '04:15': '4:15AM',
  '04:30': '4:30AM',
  '04:45': '4:45AM',
  '05:00': '5AM',
  '05:15': '5:15AM',
  '05:30': '5:30AM',
  '05:45': '5:45AM',
  '06:00': '6AM',
  '06:15': '6:15AM',
  '06:30': '6:30AM',
  '06:45': '6:45AM',
  '07:00': '7AM',
  '07:15': '7:15AM',
  '07:30': '7:30AM',
  '07:45': '7:45AM',
  '08:00': '8AM',
  '08:15': '8:15AM',
  '08:30': '8:30AM',
  '08:45': '8:45AM',
  '09:00': '9AM',
  '09:15': '9:15AM',
  '09:30': '9:30AM',
  '09:45': '9:45AM',
  '10:00': '10AM',
  '10:15': '10:15AM',
  '10:30': '10:30AM',
  '10:45': '10:45AM',
  '11:00': '11AM',
  '11:15': '11:15AM',
  '11:30': '11:30AM',
  '11:45': '11:45AM',
  '12:00': '12PM',
  '12:15': '12:15PM',
  '12:30': '12:30PM',
  '12:45': '12:45PM',
  '13:00': '1PM',
  '13:15': '1:15PM',
  '13:30': '1:30PM',
  '13:45': '1:45PM',
  '14:00': '2PM',
  '14:15': '2:15PM',
  '14:30': '2:30PM',
  '14:45': '2:45PM',
  '15:00': '3PM',
  '15:15': '3:15PM',
  '15:30': '3:30PM',
  '15:45': '3:45PM',
  '16:00': '4PM',
  '16:15': '4:15PM',
  '16:30': '4:30PM',
  '16:45': '4:45PM',
  '17:00': '5PM',
  '17:15': '5:15PM',
  '17:30': '5:30PM',
  '17:45': '5:45PM',
  '18:00': '6PM',
  '18:15': '6:15PM',
  '18:30': '6:30PM',
  '18:45': '6:45PM',
  '19:00': '7PM',
  '19:15': '7:15PM',
  '19:30': '7:30PM',
  '19:45': '7:45PM',
  '20:00': '8PM',
  '20:15': '8:15PM',
  '20:30': '8:30PM',
  '20:45': '8:45PM',
  '21:00': '9PM',
  '21:15': '9:15PM',
  '21:30': '9:30PM',
  '21:45': '9:45PM',
  '22:00': '10PM',
  '22:15': '10:15PM',
  '22:30': '10:30PM',
  '22:45': '10:45PM',
  '23:00': '11PM',
  '23:15': '11:15PM',
  '23:30': '11:30PM',
  '23:45': '11:45PM',
}
