import React from 'react';

import WixInstallation from './WixInstallation'
import ShopifyInstallation from './ShopifyInstallation'
import DefaultInstallation from './DefaultInstallation'

const renderInstructions = ({ ...props }) => {
  const { shopifyConnected, valueKey, hasWixConnected } = props;

  if (shopifyConnected) return <ShopifyInstallation valueKey={valueKey} {...props} />;
  if (hasWixConnected) return <WixInstallation valueKey={valueKey} {...props} />;

  return <DefaultInstallation valueKey={valueKey} {...props} />;
};

const MapsEmbedContent = (props) => {
  const { mapInstalled, valueKey } = props

  return (
    <div className="page-setup-section clearfix">
      <div className="page-setup-content">
        <h2 className="page-setup-section-title">
          Install Your Map
          <i className={`page-setup-section-title-completed ion-checkmark-circled ${ (valueKey || mapInstalled) ? '' : 'incomplete'}`}/>
        </h2>
        {renderInstructions({ ...props })}
      </div>
    </div>
  );
}

export default MapsEmbedContent;
