export const DEFAULT_BROWSER_FONTS = [
  {
    label: 'Arial',
    value: 'Arial, Helvetica, sans-serif',
    files: {},
  },
  {
    label: 'FF Meta Serif',
    value: 'ff meta serif, Georgia, serif',
    files: {},
  },
  {
    label: 'Helvetica',
    value: 'Helvetica, Arial, sans-serif',
    files: {},
  },
  {
    label: 'Gellix',
    value: 'Gellix, Helvetica, Arial, sans-serif',
    files: {},
  },
  {
    label: 'Georgia',
    value: 'Georgia, serif',
    files: {},
  },
  {
    label: 'Proxima Nova',
    value: 'Proxima Nova, Helvetica, Arial, sans-serif',
    files: {},
  },
  {
    label: 'Tahoma',
    value: 'Tahoma,Geneva, sans-serif',
    files: {},
  },
  {
    label: 'Times New Roman',
    value: 'Times New Roman, Times, serif',
    files: {},
  },
  {
    label: 'Verdana',
    value: 'Verdana, Geneva, sans-serif',
    files: {},
  },
];
