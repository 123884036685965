import PropTypes from "prop-types"
import React, { Component } from "react"
import CcInput from './CcInput'

function CardSummary ({ card }) {
  if (!card) return null
  const { last4, exp_month, exp_year, brand } = card
  return (
    <div className="subscriptions-payment-current">
      <div className="subscriptions-payment-current-header">
        Current payment method:
      </div>
      <div className="subscriptions-payment-current-desc">
        {brand} ending in {last4} (expires {exp_month}/{exp_year})
      </div>
    </div>
  )
}

export default class CardUpdate extends Component {
  constructor(props) {
    super(props)
    if (typeof window.Stripe !== 'undefined') {
      this.stripe = Stripe(process.env.STRIPE_PUB_KEY)
    }
  }

  updateCard ({ token }) {
    var hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripe_token')
    hiddenInput.setAttribute('value', token.id)
    this.formEl.appendChild(hiddenInput)
    this.formEl.submit()
  }

  render() {
    const {  authenticityToken, currentCard } = this.props
    return (
      <div className="col-md-12">
        <CardSummary card={currentCard} />
        <form ref={(node) => this.formEl = node} action="/subscriptions/update_card" method="post">
          <input type='hidden' name='authenticity_token' value={authenticityToken} />
          <CcInput
            onSubmit={this.updateCard.bind(this)}
            currentCard={currentCard}
            ctaText='Update Payment Method'
            subTitle='Your old one will no longer be charged.'
          />
        </form>
      </div>
    )
  }
}

CardUpdate.propTypes = {
  authenticityToken: PropTypes.string.isRequired
}
