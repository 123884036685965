import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Provider } from 'react-redux';

import store, { stateInitializer } from '../../store';
import PaymentBannerNotice from '../subscriptions/PaymentBannerNotice';
import MapsHeader from './MapsHeader';
import LocatorWrapper from '../locator/LocatorWrapper';

export default class MapsIndex extends Component {
  constructor(props) {
    super(props);

    stateInitializer({
      locator: {
        ...props,
        isFetching: true,
        location: this.props.specificLocation,
        locations: [],
        markers: [],
        popups: [],
        selectedCategories: [],
        showRefreshList: false
      },
      embed: {
        pageEmbed: props.pageEmbed,
        showingMobileList: false,
      },
    });
  }

  componentDidMount () {
    if (/signed_up=true/.test(window.location.search)) {
      Track.event('Signed up');
    }
  }

  render() {
    const {
      defaultViewport,
      doesMapGeolocate,
      hasWixConnected,
      isFree,
      isTrialing,
      mapboxClientKey,
      mapKey
    } = this.props

    return (
      <Provider store={store}>
        <div>
          <MapsHeader mapKey={mapKey} />

          {this.props.showPaymentMethodBanner && (
            <PaymentBannerNotice isFree={isFree} isTrialing={isTrialing} hasWixConnected={hasWixConnected} />
          )}

          <div className="wrapper-1300">
            <div className="maps-index-locator">
              <LocatorWrapper
                defaultViewport={defaultViewport}
                doesMapGeolocate={doesMapGeolocate}
                mapboxClientKey={mapboxClientKey}
                setMapCenter={true}
              />
            </div>
          </div>
        </div>
      </Provider>
    );
  }
}

MapsIndex.propTypes = {
  autoRefresh: PropTypes.bool.isRequired,
  buttonSetting: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string.isRequired,
  defaultPosition: PropTypes.string.isRequired,
  primaryColor: PropTypes.string.isRequired,
  hoursFormat: PropTypes.string.isRequired,
  scrollZoomEnabled: PropTypes.bool.isRequired,
  showFullscreen: PropTypes.bool.isRequired,
  style: PropTypes.string.isRequired,
  suggestionCountries: PropTypes.array.isRequired,
  suggestionType: PropTypes.string.isRequired,
  listStyle: PropTypes.string.isRequired,
  locationDetailPreference: PropTypes.string.isRequired,
  locationReposition: PropTypes.bool.isRequired,
  mapKey: PropTypes.string.isRequired,
  mapboxClientKey: PropTypes.string.isRequired,
  markerBackgroundUrl: PropTypes.string,
  markerStyle: PropTypes.string.isRequired,
  mobileStyle: PropTypes.string.isRequired,
  pageEmbed: PropTypes.bool,
  showPaymentMethodBanner: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  isTrialing: PropTypes.bool.isRequired,
  doesMapGeolocate: PropTypes.bool.isRequired,
  enableLocationCluster: PropTypes.bool.isRequired,
  hasWixConnected: PropTypes.bool.isRequired,
};
