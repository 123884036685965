import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateMapsEditor } from '../../actions/mapsEditorActions';
import { MapsEditorMarkerImage } from './MapsEditorMarkerImage';

export class MapsEditorLocationImageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location_image: props.location_image,
    }
  }

  handleImageChange = (image) => {
    const { categories_attributes, id } = this.props;

    let categoryExistsInState = false,
        newCategoryState = categories_attributes.map(category => {
          if (category?.id === id) {
            categoryExistsInState = true;

            return { ...category, location_image: image };
          }

          return category;
        });

    if (!categoryExistsInState) {
      newCategoryState.push({
        id: this.props.id,
        location_image: image
      });
    }

    this.props.updateMapsEditor({ categories_attributes: newCategoryState });

    this.setState({
      location_image: image
    });
  }

  render () {
    const {
      key,
      name,
      s3MarkerFormData,
      s3MarkerPostUrl,
      s3MarkerPostHost,
    } = this.props;
    const imageMaxFileSize = 10000000;

    return (
      <div className="row maps-editor-category-row" key={key}>
        <div className="col-8 maps-editor-category-name">
          {name}
        </div>
        <div className="col-4 maps-editor-category-inputs">
          <MapsEditorMarkerImage
            name={`category-image-${key}`}
            value={this.state.location_image}
            s3MarkerFormData={s3MarkerFormData}
            s3MarkerPostUrl={s3MarkerPostUrl}
            s3MarkerPostHost={s3MarkerPostHost}
            handleChange={this.handleImageChange}
            maxFileSize={imageMaxFileSize}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  };
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorLocationImageForm);

