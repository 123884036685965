import PropTypes from 'prop-types';
import React, { Component } from 'react';

import URLS from '../../constants/urls'

import isValidMapboxClientKey from '../../utils/mapbox';

export default class DefaultInstallation extends Component {
  constructor(props) {
    super(props)
    this.state = { showIframePageEmbed: false };
  }

  handleToggleIframeCode = name => {
    return () => {
      this.setState({ [name]: !this.state[name] });
    }
  }

  get renderInstallationOptions() {
    return (
      <div className="mtxl mbxl">
        <h3>
          Installation Overview
        </h3>
        <div>
          There are 2 approaches you can take for installing your map:
        </div>
        <div className="mtl">
          <ul>
            <li>
              <b><a href="#page-embed-install">Page Embed</a></b> - this will embed your map inside the content on specific pages and will always be showing when someone views that particular page.
            </li>
            <li>
              <b><a href="#shopify-install">Overlay</a></b> - this will open your map on top of the existing page and includes the optional launcher widget that floats in the corner of every page.
            </li>
          </ul>
        </div>
      </div>
    )
  }

  get renderJavascriptInstructions() {
    const { EMBED_HOST } = window.PAGEDATA
    const { enableLauncher } = this.props

    return (
      <div className="mbxl" id="overlay-install">
        <h3>
          Overlay Installation
        </h3>
        <div>
          Copy and paste the JavaScript code below to every page where you want your map to be available to visitors. Add it before the closing <code>&lt;/head&gt;</code> tag at the top of each page. For instructions on installing the widget on a Squarespace, you can follow <a href="https://closeby.zendesk.com/hc/en-us/articles/360006916254" target="_blank">these detailed instructions</a>.
        </div>
        <div className="code-snippet-wrapper">
          <div className="code-snippet-label">
            Add this code:
          </div>
          <pre className="code-snippet"><code>&lt;script&gt;window.closeby = {'{'} mapKey: &quot;{this.props.mapKey}&quot; {'}'};&lt;/script&gt;<br />
            &lt;script src=&quot;{EMBED_HOST}/v1.js&quot; async defer&gt;&lt;/script&gt;</code>
          </pre>
        </div>
        <div className="mtl">
          {enableLauncher ?
            'This will add the launcher in the bottom right corner of your website. If you do not want the default launcher and you would rather open the overlay yourself edit your theme and uncheck the "Enable Launcher" option.' :
            'The default launcher is disabled, use HTML code (see below) to open the overlay. If you want to re-enable the default launcher edit your theme and check the "Enable Launcher" option.'
          }
        </div>
      </div>
    )
  }

  get renderManualOpening() {
    const { enableLauncher } = this.props

    return (
      <div className="mbxl" id="manual-open-overlay">
        <h3>
          Manually Opening Overlay
        </h3>
        <div>
          {enableLauncher ?
            '' :
            <p className="text-success">
              Note: This step is required because you "Enable Launcher" is disabled for your map. If you want to skip this and use the default launcher instead edit your theme and check the box for "Enable Launcher".
            </p>
          }
          <p>
            Once the javascript code has been added, most users use the built-in toggle to open their map. Alternatively, you can manually trigger the map with your own custom HTML link.
          </p>
          <p>
            Add a link with a URL as <code>javascript:closeby-open</code> or <code>#closeby-open</code> to trigger your widget manually, as if it were an external website link. This can be useful if you have your own style or buttons that you want to use open the map.
          </p>
          <p>
            So instead of a normal link like <code>http://example.com</code>, simply add <code>javascript:closeby-open</code> as the URL and it will open the widget.
          </p>
        </div>
        <div className="code-snippet-wrapper">
          <div className="code-snippet-label">
            Example HTML link:
          </div>
          <pre className="code-snippet"><code>&lt;a href=&quot;javascript:closeby-open&quot;&gt;<br />
            &nbsp;&nbsp;View Locations<br />
            &lt;/a&gt;</code></pre>
        </div>
      </div>
    )
  }

  get renderPageEmbed() {
    const { showIframePageEmbed } = this.state;
    const { EMBED_HOST, WEB_HOST } = window.PAGEDATA

    return (
      <div className="mbxl" id="page-embed-install">
        <h3>
          Page Embed Installation
        </h3>
        <div>
          <div>
            <p>
              If you wish to embed your map within the content of your web page instead of using the overlay, the installation is simple. Just add the following code to your website:
            </p>
          </div>
          <div className="code-snippet-wrapper">
            <div className="code-snippet-label">
              Paste this code to embed your map into your website.

              <div className="code-snippet-label-switch" onClick={this.handleToggleIframeCode('showIframePageEmbed')}>
                {showIframePageEmbed ? 'Use Embed Code' : 'Use iFrame Code'}
              </div>
            </div>
            {showIframePageEmbed ? (
              <pre className="code-snippet"><code>&lt;iframe src=&quot;{WEB_HOST}/embed/{this.props.mapKey}&quot; width=&quot;100%&quot; height=&quot;600&quot; style=&quot;height:600px;border:none&quot; allow=&quot;geolocation&quot;&gt;&lt;/iframe&gt;</code></pre>
            ) : (
              <pre className="code-snippet"><code>&lt;div class=&quot;closeby-embed-{this.props.mapKey}&quot;&gt;&lt;/div&gt;<br />
                &lt;script src=&quot;{EMBED_HOST}/v1.js?closeby.mapKey={this.props.mapKey}&embed=true&height=700&quot; async defer&gt;&lt;/script&gt;</code>
              </pre>
            )}
          </div>
          <div className="mtl">
            <p>
              If you are manually installing your map to a Shopify store, view <a href="https://closeby.zendesk.com/hc/en-us/articles/360023478093" target="_blank">this article</a> for more information. For Wix, use <a target="_blank" href="https://closeby.zendesk.com/hc/en-us/articles/6037237985042">this article</a> for more details instructions.
            </p>
            {showIframePageEmbed ? (
              <p>
                To restrict a map to a specific category, you can do so by adding a url param <code>?category=[CATEGORY NAME]</code> when embedding your iframe. <a href="https://closeby.zendesk.com/hc/en-us/articles/360023316974" target="_blank">Learn more</a>
              </p>
            ) : (
              <p>
                To restrict a map to a specific category, you can do so by adding the <code>category: '[CATEGORY NAME]'</code> property to the options in your embed code. <a href="https://closeby.zendesk.com/hc/en-us/articles/360023316974" target="_blank">Learn more</a>
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { mapbox_client_key: mapboxClientKey, valueKey } = this.props;
    const mapboxClientKeyValid = (mapboxClientKey && isValidMapboxClientKey(mapboxClientKey) && !valueKey) || (valueKey && isValidMapboxClientKey(valueKey));

    return mapboxClientKeyValid ? (
      <>
        <div className="page-setup-header">
          By following these instructions, you can easily display your custom map to your visitors with all your locations.
        </div>

        {this.renderInstallationOptions}
        <hr className="mbxl" />

        {this.renderPageEmbed}
        <hr className="mbxl" />

        {this.renderJavascriptInstructions}
        {this.renderManualOpening}
      </>
    ) : (
      <div className="page-setup-header">
        Provide a valid Mapbox Access Token above to proceed with installation instructions.
      </div>
    )
  };
};
