import API_ENDPOINTS from '../constants/apiEndpoints'
import apiClient from '../services/apiClient'

export const create = (file) => {
  let data
  let config
  if (file) {
    data = new FormData()
    data.append('file', file)
    config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
    }
  }

  return apiClient.post(
    API_ENDPOINTS.IMPORT_TASKS.CREATE,
    data,
    config
  ).then((response) => response.data)
}

export const index = () => {
  return apiClient.get(
    API_ENDPOINTS.IMPORT_TASKS.INDEX
  ).then((response) => response.data)
}

export const show = (id) => {
  return apiClient.get(
    API_ENDPOINTS.IMPORT_TASKS.SHOW(id)
  ).then((response) => response.data)
}
