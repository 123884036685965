import PropTypes from "prop-types"
import React, { Component } from "react"
import CcInput from './CcInput'
import URLS from '../../constants/urls'
import { SUPPORT_EMAIL } from '../../constants';
import { getCsrfToken } from '../../services/apiClient'

import Plan from "./Plan";

export default class SubscriptionsIndex extends Component {
  constructor(props) {
    super(props)
    if (typeof window.Stripe !== 'undefined') {
      this.stripe = Stripe(process.env.STRIPE_PUB_KEY)
    }
  }

  subscriptionIsTrialing = () => {
    const { subscription } = this.props
    return subscription && subscription.status === 'trialing'
  }

  subscriptionNeedsPaymentMethod = () => {
    const { subscription } = this.props
    return subscription && (subscription.status === 'past_due' || subscription.status === 'canceled' || subscription.status === 'unpaid')
  }

  createSubscription = ({ token }, coupon) => {
    let hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripe_token')
    hiddenInput.setAttribute('value', token.id)
    this.formEl.appendChild(hiddenInput)

    if (coupon && coupon.length) {
      let couponInput = document.createElement('input')
      couponInput.setAttribute('type', 'hidden')
      couponInput.setAttribute('name', 'coupon')
      couponInput.setAttribute('value', coupon)
      this.formEl.appendChild(couponInput)
    }

    this.formEl.submit()
  }

  get shopifyBillingMethod() {
    const { authenticityToken, stripePlan } = this.props
    return (
      <div className="subscription-index-page">
        <div className="wrapper-md ptxl pbxl">
          <div className="ptxl-md pbxl-md">
            <div className="subscription-index-header-section">
              <h2 className="subscription-shopify-header">
                Activate Billing Through Shopify
              </h2>
              <div className="subscription-shopify-subheader">
                By activating billing with Shopify, you will be charged through the connected Shopify account.  If you have any questions with billing, feel free to <a href={`mailto:${SUPPORT_EMAIL}?subject=Closeby%20Billing`}>send an email</a> to our team.
              </div>
              <div className="row justify-content-center">
                <div>
                  <form action={`${URLS.SHOPIFY_SUBSCRIPTIONS.ENABLE}?plan=${stripePlan}`} method='post' className='mt-5'>
                    <input type='hidden' name='authenticity_token' value={authenticityToken} />
                    <button type='submit' className='btn btn-lg btn-primary'>
                      Activate Shopify Billing
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  get renderSubheaderCopy() {
    if (this.subscriptionIsTrialing()) {
      return 'Add a credit card to continue once your trial ends.'
    } else if (this.subscriptionNeedsPaymentMethod()) {
      return 'Reactivate your account by adding a new payment method.'
    } else {
      return 'Enter your payment details to get started.'
    }
  }

  get renderCCInput() {
    let ctaText,
        subTitle;

    if (this.subscriptionIsTrialing()) {
      ctaText = 'Save Payment Method'
      subTitle = 'You can cancel your subscription at any time.'
    } else if (this.subscriptionNeedsPaymentMethod()) {
      ctaText = 'Update Payment Method'
      subTitle = 'Your account will be immediately reactived.'
    } else {
      ctaText = 'Upgrade My Account'
      subTitle = 'You can cancel your subscription at any time.'
    }

    return (
      <div className="">
        <CcInput
          onSubmit={this.createSubscription}
          ctaText={ctaText}
          subTitle={subTitle}
        />
      </div>
    )
  }

  render() {
    const { 
      defaultBillingMethod,
      hasShopifyConnected,
      hasWixConnected,
      authenticityToken,
      planType,
      planMonthly,
      planNumLocations,
      planPrice,
      stripePlan
    } = this.props
    if (defaultBillingMethod === 'shopify') return this.shopifyBillingMethod

    return (
      <div className="subscription-index-page">
        <div className="wrapper-md">
          <div className="subscription-index-header-section">
            <h2 className="subscription-index-header">
              Power up your locator.
            </h2>
            <div className="subscription-index-subheader">
              {this.renderSubheaderCopy}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 prm-md">
              <Plan
                type={planType}
                num_locations={planNumLocations}
                price={planPrice}
                monthly={planMonthly}
                disable
              />
            </div>
            <div className="col-md-6 plm-md">
              <form ref={(node) => this.formEl = node} action={`/subscriptions?plan=${stripePlan}`} method="post">
                <input type='hidden' name='authenticity_token' value={authenticityToken} />
                {this.renderCCInput}
              </form>
            </div>
          </div>
          {
            hasShopifyConnected ?
            <div className="row">
              <div className="col-md-12 prm-md">
                <a href={URLS.SUBSCRIPTIONS.INDEX({default_billing_method: 'shopify'})} className='btn btn-lg btn-default mt-5'>
                  Switch to Shopify Billing
                </a>
              </div>
            </div> : null
          }
        </div>
      </div>
    )
  }
}

SubscriptionsIndex.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  hasPaymentMethod: PropTypes.bool.isRequired,
  hasShopifyConnected: PropTypes.bool.isRequired,
  hasWixConnected: PropTypes.bool.isRequired,
  defaultBillingMethod: PropTypes.string,
  subscription: PropTypes.object,
  planType: PropTypes.string,
  planPrice: PropTypes.number,
  planMonthly: PropTypes.bool,
  planNumLocations: PropTypes.string,
  stripePlan: PropTypes.string
}
