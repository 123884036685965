import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import MapsInstallHeader from './MapsInstallHeader';
import MapsMapboxContent from './MapsMapboxContent';
import MapsEmbedContent from './MapsEmbedContent';
import PaymentBannerNotice from "../subscriptions/PaymentBannerNotice";

const MapsEmbed = (props) => {
  useEffect(() => {
    Track.event('Map embed instructions viewed')
  }, [])

  const [key, setKey] = useState(props.mapbox_client_key);

  return (
    <div>
      <MapsInstallHeader />
      {props.isFree && props.hasWixConnected && <PaymentBannerNotice {...props} />}
      <div className="wrapper-1000">
        <div className="page-setup">
          <MapsMapboxContent setKey={setKey} {...props} />
          <MapsEmbedContent  valueKey={key} {...props} />
        </div>
      </div>
    </div>
  )
};

MapsEmbed.propTypes = {
  isFree: PropTypes.bool.isRequired,
  hasWixConnected: PropTypes.bool.isRequired,
};

export default MapsEmbed;
