import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Geosuggest from 'react-geosuggest';
import Select from 'react-select';
import Autosuggest from 'react-autosuggest';
import TagsInput from 'react-tagsinput';
import without from 'lodash.without';


import { COUNTRY_CODES } from '../../constants/index'
import URLS from '../../constants/urls'
import { updateMapsEditor, saveMapEditor } from '../../actions/mapsEditorActions';
import { createFlash } from '../../shared/createFlash';

import MapsEditorBrandSettings from './MapsEditorBrandSettings';
import MapsEditorStylePicker from './MapsEditorStylePicker';
import MapsEditorTextOverrides from './MapsEditorTextOverrides';
import MapsEditorAdvancedTheming from './MapsEditorAdvancedTheming';
import MapsEditorDefaultMap from './MapsEditorDefaultMap';

export class MapsEditorForm extends Component {
  state = {
    activeTab: 'general'
  };

  handleTabClick = (name) => {
    return () => {
      this.setState({ activeTab: name });
    }
  }

  handleColorChange = (color) => {
    this.props.updateMapsEditor({ primary_color: color.hex })
  }

  handleChange = (name) => {
    return event => {
      const { target: { value } } = event;
      if (name === 'default_position') {
        this.props.updateMapsEditor({ 
          default_viewport_sw_longitude: null,
          default_viewport_sw_latitude: null,
          default_viewport_ne_longitude: null,
          default_viewport_ne_latitude: null, 
          specific_location_id: null,
        });
      };
      this.props.updateMapsEditor({ [name]: value });
    }
  }

  handleSelectChange = (name) => {
    return (selectedOption) => {
      this.props.updateMapsEditor({ [name]: selectedOption.value });
    }
  }

  handleSpecificLocationChange = (fieldName) => (selectedOption) => {
    if (!selectedOption) {
      this.props.updateMapsEditor({
        specific_location_id: null,
        default_viewport_sw_longitude: null,
        default_viewport_sw_latitude: null,
        default_viewport_ne_longitude: null,
        default_viewport_ne_latitude: null,
      });
      return;
    }

    const { latitude, longitude } = selectedOption;

    if (!latitude || !longitude) return;

    const boundsOffset = 0.0015;

    const southwestCoordinates = [longitude - boundsOffset, latitude - boundsOffset];
    const northEastCoordinates = [longitude + boundsOffset, latitude + boundsOffset];

    this.props.updateMapsEditor({
      specific_location_id: selectedOption.value,
      default_viewport_sw_longitude: southwestCoordinates[0],
      default_viewport_sw_latitude: southwestCoordinates[1],
      default_viewport_ne_longitude: northEastCoordinates[0],
      default_viewport_ne_latitude: northEastCoordinates[1],
    });
  };

  handleCheckboxChange = (name) => {
    return event => {
      this.props.updateMapsEditor({ [name]: event.target.checked });
    }
  }

  handleEditorChange = (name) => {
    return value => {
      this.props.updateMapsEditor({ [name]: value });
    }
  }

  handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()

    this.props.saveMapEditor(this.props.mapId, { map: this.props })
    .then(json => {
      createFlash('Your theming changes have been saved!', 'success')
    }).catch((error) => {
      createFlash('Sorry, there was a problem saving your changes.', 'error')
    })
  }

  onViewportSuggestSelect = (suggestion) => {
    if (!suggestion) return
    const bounds = suggestion.gmaps.geometry && suggestion.gmaps.geometry.viewport

    if (!bounds) {
      return console.error('Expected suggestion.gmaps.geometry.bounds', suggestion)
    }

    const sw = bounds.getSouthWest()
    const ne = bounds.getNorthEast()

    if (!(sw && ne)) {
      return console.error('Expected bounds sw and ne', bounds)
    }

    if (sw && ne) {
      this.props.updateMapsEditor({
        default_viewport_label: suggestion.label,
        default_viewport_sw_longitude: sw.lng(),
        default_viewport_sw_latitude: sw.lat(),
        default_viewport_ne_longitude: ne.lng(),
        default_viewport_ne_latitude: ne.lat()
      })
    }
  }

  removeDefaultViewport = () => {
    this.props.updateMapsEditor({
      default_viewport_label: '',
      default_viewport_sw_longitude: '',
      default_viewport_sw_latitude: '',
      default_viewport_ne_longitude: '',
      default_viewport_ne_latitude: ''
    })
  }

  get renderDistanceUnits() {
    const { units } = this.props;

    return (
      <div>
        <div className="maps-editor-label">
          Distance Units
          <div className="maps-editor-sublabel">
            When users view your map this is the units of distance they will see.
          </div>
        </div>
        <div className="maps-editor-form-group form-radio-group">
          {
            [{value: 'mi', label: 'Miles (mi)'}, {value: 'km', label: 'Kilometers (km)'}].map((unit) => {
              return (
                <label className="form-radio-label" key={unit.value}>
                  <input
                    type="radio"
                    name={unit.value}
                    value={unit.value}
                    checked={units === unit.value}
                    onChange={this.handleChange('units')}
                    className="form-radio-input"
                  />
                  <div className="form-radio-label-copy">
                    {unit.label}
                  </div>
                </label>
              )
            })
          }
        </div>
      </div>
    )
  }

  get renderHoursFormat() {
    const { hours_format } = this.props;

    return (
      <div>
        <div className="maps-editor-label">
          Hours Formatting
        </div>
        <div className="maps-editor-form-group form-radio-group">
          {
            [{value: 'format_12_hours', label: '12-Hour Clock (12AM - 11:59PM)'}, {value: 'format_24_hours', label: '24-Hour Clock (00:00 - 23:59)'}].map((format) => {
              return (
                <label className="form-radio-label" key={format.value}>
                  <input
                    type="radio"
                    name={format.value}
                    value={format.value}
                    checked={hours_format === format.value}
                    onChange={this.handleChange('hours_format')}
                    className="form-radio-input"
                  />
                  <div className="form-radio-label-copy">
                    {format.label}
                  </div>
                </label>
              )
            })
          }
        </div>
      </div>
    )
  }

  get renderLocationCluster() {
    const { enable_location_cluster } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Cluster Locations
          <div className="maps-editor-sublabel">
            When enabled, this will group your location pins together at lower zoom levels. This is useful when you have many locations in a specific area that make individual pins difficult to see.
            {' '}<a href="https://closeby.zendesk.com/hc/en-us/articles/16083895811090" target="_blank">Learn More</a>
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="scroll_zoom_enabled"
              className="form-checkbox-input"
              checked={enable_location_cluster}
              onChange={this.handleCheckboxChange('enable_location_cluster')}
            />
            <div className="form-checkbox-label-copy">
              Enable location clustering when zoomed out
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderLocationReposition() {
    const { location_reposition } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Location Repositioning
          <div className="maps-editor-sublabel">
            When enabled, this will automatically reposition the map to center on the selected location when a user clicks on a location in the list.
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="location_reposition"
              className="form-checkbox-input"
              checked={location_reposition}
              onChange={this.handleCheckboxChange('location_reposition')}
            />
            <div className="form-checkbox-label-copy">
              Fly to location when selected in the list
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderResultsLimit() {
    const { results_limit, show_results_summary } = this.props;
    const options = [
      { value: 1000, label: '1000 Location Results' },
      { value: 3000, label: '3000 Location Results' },
      { value: 5000, label: '5000 Location Results' },
      { value: 10000, label: '10000 Location Results' },
    ];

    const summaryOptions = [
      { value: true, label: 'Show the location results summary' },
      { value: false, label: 'Hide the total location results summary' },
    ];

    return (
      <div className="maps-editor-form-group">
        <div className="maps-editor-label">
          Results Limit
          <div className="maps-editor-sublabel">
            This limit applies when there are more locations than can be shown in a specific map area (usually when showing larger areas like countries). A higher limit can result in slower loading times.
          </div>
        </div>
        <div>
          <Select
            name="results_limit"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === results_limit)}
            onChange={this.handleSelectChange('results_limit')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
        <div className="mtm">
          <div className="maps-editor-sublabel">
            Show the summary of locations found. This is displayed at the bottom of your map's location list (ie "Showing 200 within 50 mi").
          </div>
          <div className="mtm">
            <Select
              name="show_results_summary"
              className="dropdown-select"
              options={summaryOptions}
              value={summaryOptions.find(option => option.value === show_results_summary)}
              onChange={this.handleSelectChange('show_results_summary')}
              clearable={false}
              isSearchable={false}
              menuShouldScrollIntoView
              menuPlacement={'auto'}
            />
          </div>
        </div>
      </div>
    )
  }

  get renderLauncherText() {
    const {
      launcher_enabled,
      launcher_text,
      shopify_launcher_script_required
    } = this.props;

    if (!launcher_enabled || shopify_launcher_script_required) return;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Launcher Text
          <div className="maps-editor-sublabel">
            This is the text that will be displayed when using the default launcher in the corner of your website.
          </div>
        </div>
        <div>
          <input
            type="text"
            name="launcher_text"
            value={ launcher_text }
            onChange={ this.handleChange('launcher_text') }
            className="form-control"
            placeholder="Our Locations"
            maxLength="30"
            required
          />
        </div>
      </div>
    );
  }

  get renderLauncherSettings() {
    const {
      launcher_enabled,
      launcher_text,
      shopify_launcher_script_required,
      hasWixConnected
    } = this.props;

    if (hasWixConnected) return;

    return (
      <div>
        {shopify_launcher_script_required ? (
          <div className="pbxl">
            <div className="maps-editor-label">
              Enable Launcher
            </div>
            <div className="maps-editor-shopify-launcher-required">
              You must enable the "Shopify Overlay" on your <a href="/maps/install">map installation</a> settings to use the launcher on your Shopify store.
            </div>
          </div>
        ) : (
          <div className="pbxl">
            <div className="maps-editor-label">
              Enable Launcher
              <div className="maps-editor-sublabel">
                Note: Disabling the launcher will require you to manually open your map by using buttons/links on your website.
              </div>
            </div>
            <label className="form-checkbox-label">
              <input
                type="checkbox"
                name="launcher_enabled"
                className="form-checkbox-input"
                checked={ launcher_enabled }
                onChange={ this.handleCheckboxChange('launcher_enabled') }
              />
              <div className="form-checkbox-label-copy">
                Enable default launcher on my website
              </div>
            </label>
          </div>
        )}

        {this.renderLauncherText}
      </div>
    )
  }

  get renderOpenAsNewTab() {
    const { open_as_new_tab } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Custom Button Links
          <div className="maps-editor-sublabel">
            This option only applies when custom buttons are added to your locations.
          </div>
        </div>
        <label className="form-checkbox-label">
          <input
            type="checkbox"
            name="open_as_new_tab"
            className="form-checkbox-input"
            checked={ open_as_new_tab }
            onChange={ this.handleCheckboxChange('open_as_new_tab') }
          />
          <div className="form-checkbox-label-copy">
            Open custom button links in a new tab
          </div>
        </label>
      </div>
    )
  }

  get renderButtonSetting() {
    const {
      button_setting,
    } = this.props;

    const options = [
      { value: 'custom_button_overrides_directions', label: 'Override direction button with custom buttons' },
      { value: 'always_show_directions', label: 'Always show directions (even if custom button added)' },
      { value: 'always_hide_directions', label: 'Always hide directions (even if custom button added)' }
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Showing "Get Directions" Button
          <div className="maps-editor-sublabel">
            This setting will determine when the "Get Directions" button is shown on your map in relation to any custom buttons you add.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="button_setting"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === button_setting)}
            onChange={this.handleSelectChange('button_setting')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
      </div>
    )
  };

  get renderClosedDaysOption() {
    const { show_closed_days } = this.props;
    const options = [
      { value: 'do_not_show', label: 'Do not show closed days' },
      { value: 'show_weekends', label: 'Show weekends as closed when no hours set' },
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Showing Closed Days
          <div className="maps-editor-sublabel">
            This will show days as "Closed" if no hours have been set on a location.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="show_closed_days"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === show_closed_days)}
            onChange={this.handleSelectChange('show_closed_days')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
      </div>
    );
  }

  get renderHideLocationCategories() {
    const { hide_location_categories } = this.props;
    const options = [
      { value: false, label: 'Show categories when viewing location details' },
      { value: true, label: 'Hide categories when viewing location details' },
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Location Category Display
          <div className="maps-editor-sublabel">
            This will hide/show the categories when viewing a location in your map.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="hide_location_categories"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === hide_location_categories)}
            onChange={this.handleSelectChange('hide_location_categories')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
      </div>
    )
  }

  get renderDetailsPreference() {
    const {
      location_detail_preference
    } = this.props;

    return (
      <div>
        <div className="maps-editor-label">
          Popup Preference
          <div className="maps-editor-sublabel">
            Change the details shown on your map when a location's popup is displayed.
          </div>
        </div>
        <div className="maps-editor-form-group form-radio-group">
          {
            [{ value: 'details_hours_first', label: 'Prioritize days open (ie "Open Mon-Fri")' }, { value: 'details_phone_first', label: 'Prioritize phone number' }, { value: 'details_website_first', label: 'Prioritize website URL' }].map((option) => {
              return (
                <label className="form-radio-label" key={option.value}>
                  <input
                    type="radio"
                    name={option.value}
                    value={option.value}
                    checked={location_detail_preference === option.value}
                    onChange={this.handleChange('location_detail_preference')}
                    className="form-radio-input"
                  />
                  <div className="form-radio-label-copy">
                    {option.label}
                  </div>
                </label>
              )
            })
          }
        </div>
      </div>
    )
  }

  get renderScrollZoom() {
    const {
      scroll_zoom_enabled,
      show_fullscreen,
    } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Scroll Zoom & Full-Screen Viewing
          <div className="maps-editor-sublabel">
            Allow or prevent zooming on your map by scrolling on a trackpad (on laptops). On mobile, users must always use 2-finger gestures to zoom. "Full-screen viewing" allow users to view the map portion in full screen.
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="scroll_zoom_enabled"
              className="form-checkbox-input"
              checked={ scroll_zoom_enabled }
              onChange={ this.handleCheckboxChange('scroll_zoom_enabled') }
            />
            <div className="form-checkbox-label-copy">
              Enable scroll zoom on my map
            </div>
          </label>
        </div>
        <div>
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="show_fullscreen"
              className="form-checkbox-input"
              checked={ show_fullscreen }
              onChange={ this.handleCheckboxChange('show_fullscreen') }
            />
            <div className="form-checkbox-label-copy">
              Enable full-screen viewing on my map
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderSingleFingerScroll() {
    const {
      enable_single_finger_scroll
    } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Single Finger Scroll
          <div className="maps-editor-sublabel">
            By default, map interaction on a mobile device requires two fingers - "one finger to scroll the page, two fingers to scroll the map". If you set single finger scroll, mobile users will only be able to scroll the page outside of the map.
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="enable_single_finger_scroll"
              className="form-checkbox-input"
              checked={ enable_single_finger_scroll }
              onChange={ this.handleCheckboxChange('enable_single_finger_scroll') }
            />
            <div className="form-checkbox-label-copy">
              Enable single finger scroll view on my map
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderDefaultMapView() {
    const {
      appMapboxKey,
      default_viewport_label,
      default_viewport_sw_longitude,
      default_viewport_sw_latitude,
      default_viewport_ne_longitude,
      default_viewport_ne_latitude,
    } = this.props;

    return (
      <div>
        <div className="maps-editor-default-map-input">
          <Geosuggest
            autocomplete="off"
            placeholder='Search city, region or country'
            inputClassName='form-control'
            onSuggestSelect={this.onViewportSuggestSelect}
            initialValue={default_viewport_label ? default_viewport_label : ''}
            ref={node => { this.geoSuggestNode = node }}
          />
          {default_viewport_label && (
            <div className="maps-editor-default-map-remove" onClick={this.removeDefaultViewport}>
              Remove
            </div>
          )}
        </div>
        <MapsEditorDefaultMap
          southwestCoordinates={[default_viewport_sw_longitude, default_viewport_sw_latitude]}
          northEastCoordinates={[default_viewport_ne_longitude, default_viewport_ne_latitude]}
          appMapboxKey={appMapboxKey}
        />
      </div>
    );
  };

  get renderSpecificLocationMapView() {
    const {
      appMapboxKey,
      locations,
      default_viewport_sw_longitude,
      default_viewport_sw_latitude,
      default_viewport_ne_longitude,
      default_viewport_ne_latitude,
      specific_location_id,
    } = this.props;

    const locations_options = locations.map(({ id, address_full, latitude, longitude }) => ({
      value: id,
      label: address_full,
      latitude,
      longitude
    }));

    return (
      <div>
        <div className="maps-editor-default-map-input">
          <Select
            name="specific_location_id"
            className="dropdown-select"
            placeholder={"Select Location..."}
            options={locations_options}
            value={locations_options.find(({ value }) => value === specific_location_id)}
            onChange={this.handleSpecificLocationChange('specific_location_id')}
            isClearable={true}
            isSearchable={true}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
        <MapsEditorDefaultMap
          southwestCoordinates={[default_viewport_sw_longitude, default_viewport_sw_latitude]}
          northEastCoordinates={[default_viewport_ne_longitude, default_viewport_ne_latitude]}
          appMapboxKey={appMapboxKey}
        />
      </div>
    );
  };

  get renderDefaultMapPosition() {
    const {
      default_position,
    } = this.props;

    return (
      <div className="maps-editor-form-group">
        <div className="maps-editor-label">
          Default Map Position
        </div>
        <div className="maps-editor-form-group">
          <div className="form-radio-group">
            <label className="form-radio-label">
              <input
                type="radio"
                name="default_position"
                value="show_all"
                checked={default_position === 'show_all'}
                onChange={this.handleChange('default_position')}
                className="form-radio-input"
              />
              <div className="form-radio-label-copy">
                Show all locations
              </div>
            </label>
            <label className="form-radio-label">
              <input
                type="radio"
                name="default_position"
                value="geolocate"
                checked={default_position === 'geolocate'}
                onChange={this.handleChange('default_position')}
                className="form-radio-input"
              />
              <div className="form-radio-label-copy">
                Geolocate based on user's location
              </div>
            </label>
            <label className="form-radio-label">
              <input
                type="radio"
                name="default_position"
                value="viewport"
                checked={default_position === 'viewport'}
                onChange={this.handleChange('default_position')}
                className="form-radio-input"
              />
              <div className="form-radio-label-copy">
                Set a default map location
              </div>
            </label>
            <label className='form-radio-label'>
              <input
                type='radio'
                name='default_position'
                value='specific_location'
                checked={default_position === 'specific_location'}
                onChange={this.handleChange('default_position')}
                className='form-radio-input'
              />
              <div className='form-radio-label-copy'>
                One specific location
              </div>
            </label>
          </div>
          {default_position === 'specific_location' && (
            <div className="maps-editor-default-position-notice">
              <div className="maps-editor-default-position-copy">
                <p>Users automatically see one location selected when your widget loads.</p>
              </div>
              <div>
                {this.renderSpecificLocationMapView}
              </div>
            </div>
          )}
          {default_position === 'show_all' && (
            <div className="maps-editor-default-position-notice">
              <div className="maps-editor-default-position-details">
                Shows all your locations in your map by default.
              </div>
              <div className="maps-editor-default-position-copy">
                <p>This will show all your locations in the world when the map loads and requires seach/zooming to specific locations. Locations are sorted alphabetically.</p>
                <p>This may have performance challenges when displaying thousands of locations.</p>
                <p>You can also add a loading map position for your map below.</p>
              </div>
              <div>
                {this.renderDefaultMapView}
              </div>
            </div>
          )}
          {default_position === 'geolocate' && (
            <div className="maps-editor-default-position-notice">
              <div className="maps-editor-default-position-details">
                Request visitor's location to center your map.
              </div>
              <div className="maps-editor-default-position-copy">
                <p>This will ask your visitors to share their location, which will appear on your map. The user location color is always editable in your Advanced Styles.</p>
                <p>If they choose not to share their location, your map will use the default geographic position for your map defined below.</p>
              </div>
              <div>
                {this.renderDefaultMapView}
              </div>
            </div>
          )}
          {default_position === 'viewport' && (
            <div className="maps-editor-default-position-notice">
              <div className="maps-editor-default-position-details">
                Manually set a region to show in your map by default.
              </div>
              <div className="maps-editor-default-position-copy">
                This will set a default geographic position for your map.
              </div>
              <div>
                {this.renderDefaultMapView}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  get renderAutoRefresh() {
    const {
      auto_refresh
    } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Auto-Refresh Map Locations
          <div className="maps-editor-sublabel">
            By default, your map will prompt the user to refresh the search area when moving the map position. This is considered smoother experience for most maps, but in some cases such as hyper-localized map viewing, it may be preferable to always auto-refresh results instead.
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="scroll_zoom_enabled"
              className="form-checkbox-input"
              checked={ auto_refresh }
              onChange={ this.handleCheckboxChange('auto_refresh') }
            />
            <div className="form-checkbox-label-copy">
              Auto-refresh location results when the map is moved
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderAutocompleteCountry() {
    const {
      suggestion_countries
    } = this.props;

    const suggestionOptions = Object.keys(COUNTRY_CODES);

    function autocompleteRenderInput({ addTag, ...props }) {
      const handleOnChange = (e) => { e.preventDefault() };
      const suggestions = without(props.suggestions, ...suggestion_countries)

      return (
        <Autosuggest
          ref={props.ref}
          suggestions={suggestions}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={(suggestion) => suggestion}
          renderSuggestion={(suggestion) => <span>{COUNTRY_CODES[suggestion]} ({suggestion})</span>}
          inputValue=""
          inputProps={{ ...props, onChange: handleOnChange, onKeyPress: handleOnChange }}
          onSuggestionSelected={(e, { suggestion }) => {
            addTag(suggestion)
          }}
          onSuggestionsClearRequested={() => { }}
          onSuggestionsFetchRequested={() => { }}
        />
      )
    }

    return (
      <div className="maps-editor-form-group">
        <div className="maps-editor-label">
          Autocomplete Country Restriction
          <div className="maps-editor-sublabel">
            Select the countries you would like to restrict suggestions to. This is useful if you have locations in a specific country and want to prevent users from searching in other countries.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <TagsInput
            value={suggestion_countries}
            onChange={this.handleEditorChange('suggestion_countries')}
            onlyUnique
            addOnBlur
            inputProps={{
              placeholder: 'Select country',
              suggestions: suggestionOptions,
            }}
            renderInput={autocompleteRenderInput}
          />
        </div>
      </div>
    )
  }

  get renderGeneral() {
    const {
      suggestion_range,
      suggestion_strict,
      suggestion_type,
    } = this.props;

    return (
      <div className="maps-editor-form-group">
        <div className="row">
          <div className="col-md-6 prxl-md">

            {this.renderLauncherSettings}
            {this.renderScrollZoom}
            {this.renderSingleFingerScroll}

            {this.renderDefaultMapPosition}

            <div className="maps-editor-form-group">
              <div className="maps-editor-label">
                Autocomplete Suggestions
                <div className="maps-editor-sublabel">
                  Select the types of geographic locations you would like to be autocompleted when your visitors type in your map's search bar.
                </div>
              </div>
              <div className="maps-editor-form-group form-radio-group">
                {
                  [{value: 'suggestion_include_locality', label: 'Country, Region & City'}, {value: 'suggestion_include_zip', label: 'Country, Region, City & Postcode'}, {value: 'suggestion_include_address', label: 'Country, Region, City, Postcode & Address'}].map((option) => {
                    return (
                      <label className="form-radio-label" key={option.value}>
                        <input
                          type="radio"
                          name={option.value}
                          value={option.value}
                          checked={suggestion_type === option.value}
                          onChange={this.handleChange('suggestion_type')}
                          className="form-radio-input"
                        />
                        <div className="form-radio-label-copy">
                          {option.label}
                        </div>
                      </label>
                    )
                  })
                }
              </div>
            </div>
            {this.renderAutocompleteCountry}
            {this.renderButtonSetting}
            {this.renderOpenAsNewTab}

          </div>
          <div className="col-md-6">
            {this.renderAutoRefresh}
            {this.renderLocationCluster}
            {this.renderLocationReposition}
            {this.renderDetailsPreference}
            <div>
              <div className="maps-editor-label">
                Suggestion Range
                <div className="maps-editor-sublabel">
                  This configuration is used when an autocomplete suggestion is selected.  "Bounding Box" will let users see all locations within the suggested coordinates for a location, and "Near Center" will show all locations within a 50 mile range of the center point.
                </div>
              </div>
              <div className="maps-editor-form-group form-radio-group">
                {
                  [{value: 'bounding_box', label: 'Bounding Box (default)'}, {value: 'near_center', label: 'Near Center'}].map((option) => {
                    return (
                      <div>
                        <label className="form-radio-label" key={option.value}>
                          <input
                            type="radio"
                            name={option.value}
                            value={option.value}
                            checked={suggestion_range === option.value}
                            onChange={this.handleChange('suggestion_range')}
                            className="form-radio-input"
                          />
                          <div className="form-radio-label-copy">
                            {option.label}
                          </div>
                        </label>
                        {option.value === 'bounding_box' && suggestion_range === 'bounding_box' && (
                          <div className="mbm plm">
                            <label className="form-checkbox-label">
                              <input
                                type="checkbox"
                                name="suggestion_strict"
                                className="form-checkbox-input"
                                checked={ suggestion_strict }
                                onChange={ this.handleCheckboxChange('suggestion_strict') }
                              />
                              <div className="form-checkbox-label-copy">
                                Do not show nearby locations when no results are found.
                              </div>
                            </label>
                          </div>
                        )}
                        {option.value === 'bounding_box' && suggestion_range !== 'bounding_box' && (
                          <div className="mbs"/>
                        )}
                      </div>
                    )
                  })
                }
              </div>
            </div>
            {this.renderDistanceUnits}
            {this.renderHoursFormat}
            {this.renderResultsLimit}
            {this.renderClosedDaysOption}
            {this.renderHideLocationCategories}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="page-overlay clearfix">
        <div className="maps-editor-form-header">
          <div className="wrapper">
            <div className="maps-editor-form-header-in">
              <h2 className="page-overlay-title">
                Edit Theme
              </h2>
              <a className="btn btn-default mrs" href={URLS.MAPS.INDEX}>View My Map</a>
              <a className="btn btn-primary" onClick={this.handleSubmit}>Save Changes</a>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="page-overlay-content">
            <div className="page-overlay-content-tabs">
              <div className={`page-overlay-content-tab ${this.state.activeTab === 'general' ? 'active' : ''}`} onClick={this.handleTabClick('general')}>
                Map Options
              </div>
              <div className={`page-overlay-content-tab ${this.state.activeTab === 'brand' ? 'active' : ''}`} onClick={this.handleTabClick('brand')}>
                Branding
              </div>
              <div className={`page-overlay-content-tab ${this.state.activeTab === 'theme' ? 'active' : ''}`} onClick={this.handleTabClick('theme')}>
                Theme Style
              </div>
              <div className={`page-overlay-content-tab ${this.state.activeTab === 'text' ? 'active' : ''}`} onClick={this.handleTabClick('text')}>
                Text Defaults
              </div>
              <div className={`page-overlay-content-tab ${this.state.activeTab === 'advanced' ? 'active' : ''}`} onClick={this.handleTabClick('advanced')}>
                Advanced Styles
              </div>
            </div>
            {this.state.activeTab === 'general' && this.renderGeneral}
            {this.state.activeTab === 'brand' && <MapsEditorBrandSettings />}
            {this.state.activeTab === 'theme' && <MapsEditorStylePicker />}
            {this.state.activeTab === 'text' && <MapsEditorTextOverrides />}
            {this.state.activeTab === 'advanced' && <MapsEditorAdvancedTheming />}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor, saveMapEditor })(MapsEditorForm);
