import React, { Component } from 'react';
import { connect } from 'react-redux';
import URLS from '../../constants/urls'

import { showLocation, showGoogleSheetUneditableFlashError } from '../../actions/locationActions';
import LocationsSearch from './LocationsSearch';

export class LocationsListHeader extends Component {
  handleNewLocationClick = () => {
    this.props.showLocation({ 'showing': true, id: null });
  }

  handleNewLocationGoogleSyncClick = () => {
    window.open(this.props.googleSheetsUrl, "_blank");
  };

  renderAddButton = () => {
    const { hasGoogleSheetsSyncEnabled } = this.props.locations;

    if (hasGoogleSheetsSyncEnabled) {
      return (
        <a href="javascript:void(0)" className="btn btn-primary float-right" onClick={this.handleNewLocationGoogleSyncClick}>
          Add Location
        </a>
      )
    } else {
      return (
        <a href="javascript:void(0)" className="btn btn-primary float-right" onClick={this.handleNewLocationClick}>
          Add Location
        </a>
      )
    }
  }

  render() {
    return (
      <div className="page-header clearfix">
        <div className="page-header-title">
          Locations
        </div>
        <div className="page-header-actions">


          {this.renderAddButton()}
          <a href={URLS.INTEGRATIONS.CSV} className="btn btn-default float-right mrs">
            Import CSV
          </a>
          <a href={URLS.INTEGRATIONS.INDEX} className="btn btn-default float-right mrs">
            Sync Google Sheet
          </a>

          <LocationsSearch />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state
  }
};

export default connect(mapStateToProps, { showLocation, showGoogleSheetUneditableFlashError })(LocationsListHeader);
