import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Toggle = ({
  checked,
  setChecked,
  disabled = false,
  onChange = () => {},
}) => {
  const handleChange = () => {
    setChecked(!checked);
    onChange();
  };

  return (
    <label className="switch">
      <input
        className="toggle-input"
        type="checkbox"
        autoComplete='off'
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <span className={classNames('slider', { 'slider--disabled': disabled })} />
    </label>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Toggle;
