import PropTypes from 'prop-types';
import React, { Component } from 'react';
import apiClient from '../../services/apiClient'
import API_ENDPOINTS from '../../constants/apiEndpoints'
import URLS from '../../constants/urls'

export default class ShopifyInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount () {
    this.getShopifyDetails()
  }

  handleError (e) {
    this.setState({ errorMessage: (e && e.message) || 'Error loading ShopifyInstructions' })
  }

  getShopifyDetails () {
    this.getOauthAccounts()
    this.getScriptTags()
  }

  disconnect (id) {
    return apiClient.delete(API_ENDPOINTS.OAUTH_ACCOUNTS.DESTROY(id)).then(() => {
      this.getShopifyDetails()
    }).catch(this.handleError.bind(this))
  }

  getOauthAccounts () {
    this.setState({ isLoading: true })
    return apiClient.get(API_ENDPOINTS.OAUTH_ACCOUNTS.SHOPIFY)
      .then((resp) => {
        const { oauth_account } = resp.data
        const shopifyAccount = oauth_account || null
        this.setState({ shopifyAccount, isLoading: false })
      }).catch(this.handleError.bind(this))
  }

  createScriptTag () {
    this.setState({ isLoading: true })
    return apiClient.post(API_ENDPOINTS.SHOPIFY_SCRIPT_TAGS.CREATE)
      .then((resp) => {
        this.getShopifyDetails()
      }).catch(this.handleError.bind(this))
  }

  destroyScriptTags () {
    this.setState({ isLoading: true })
    return apiClient.delete(API_ENDPOINTS.SHOPIFY_SCRIPT_TAGS.DESTROY)
      .then((resp) => {
        this.getShopifyDetails()
      }).catch(this.handleError.bind(this))
  }

  getScriptTags () {
    this.setState({ isLoading: true })
    return apiClient.get(API_ENDPOINTS.SHOPIFY_SCRIPT_TAGS.INDEX)
      .then((resp) => {
        const { script_tags } = resp.data
        const scriptTags = script_tags || []
        this.setState({ scriptTags: scriptTags, isLoading: false })
      }).catch(this.handleError.bind(this))
  }

  get hasScriptTags () {
    const { scriptTags } = this.state
    return scriptTags && scriptTags.length
  }

  getOauthAccounts () {
    this.setState({ isLoading: true })
    return apiClient.get(API_ENDPOINTS.OAUTH_ACCOUNTS.SHOPIFY)
      .then((resp) => {
        const { oauth_account, script_tags } = resp.data
        this.setState({
          shopifyAccount: oauth_account,
          scriptTags: script_tags,
          isLoading: false,
        })
      }).catch(this.handleError.bind(this))
  }

  get canDisconnect () {
    const { currentCompany } = window.PAGEDATA
    return !(currentCompany.shopify_billing_enabled && currentCompany.shopify_billing_configured)
  }

  get renderDisconnect () {
    const { shopifyAccount } = this.state
    return (
      this.canDisconnect ?
        <span>
          <a
            href="javascript:void(0)"
            className="btn btn-sm btn-danger shopify-connected-account-disconnect"
            onClick={() => this.disconnect(shopifyAccount.id)}
          >
            Disconnect
          </a>
        </span>
      : null
    )
  }

  get renderConnect () {
    const { shopifyAccount } = this.state
    return (
      <div className="shopify-connected-account">
        Connected: {shopifyAccount.uid}
        { this.renderDisconnect }
      </div>
    )
  }

  get renderScriptTags () {
    return (
      this.hasScriptTags ?
        <div className="mbm">
          <p>
            Your Closeby account is now connected to your Shopify store.  If you'd like to use the map launcher in your theme settings, you can enable it in your theme settings now.
          </p>
          <p>
            It may take a few minutes for changes to be visible on Shopify. If you are experiencing any issues with this set up process, feel free to <a href="mailto:support@closeby.zendesk.com" target="_blank">email our team</a> for assistance.
          </p>
          <p>
            By default, the Closeby overlay script is loaded into your website. If you do not plan to use the overlay implementation, click the button below to remove the script.
          </p>
          <a
            className="btn btn-sm btn-default mbs"
            onClick={() => this.destroyScriptTags()}
          >
            Remove overlay script from Shopify
          </a>
        </div>
      :
        <div className="mbm">
          <p>
            <b>Your Shopify account is connected but the launcher is not enabled yet.</b> If you are experiencing any issues with this set up process, feel free to <a href="mailto:support@closeby.zendesk.com" target="_blank">email our team</a> for assistance.
          </p>
          <a
            className="btn btn-primary"
            onClick={() => this.createScriptTag()}
          >
            Enable Launcher on Shopify
          </a>
        </div>
    )
  }

  get renderConnectedAccount () {
    const { shopifyAccount } = this.state
    return (
      <div>
        { this.renderConnect }
        { this.renderScriptTags }
      </div>
    )
  }

  get renderConnectForm () {
    return (
      <form action={URLS.OAUTH.SHOPIFY} method="get">
        <div className="mbm">
          Connect Shopify to automatically embed your custom Closeby widget on every page. Learn more about how our Shopify integration works <a href={URLS.FAQ.SHOPIFY} target="_blank">here</a>.
        </div>
        <div className="form-group">
          <label htmlFor="shop">Enter your store's URL without the <code>https://</code>:</label>
          <div className="row">
            <div className="col-md-9 pbm pbn-md prl-md">
              <input
                type="text"
                name="shop"
                placeholder="your-shop-url.myshopify.com"
                className="form-control"
                pattern="[^\\\/]+"
                title="your-shop-url.myshopify.com"
                required
                />
            </div>
            <div className="col-md-3">
              <button type="submit" className="btn-block">Connect Shopify</button>
            </div>
          </div>
        </div>

      </form>
    )
  }

  render() {
    const { isLoading, errorMessage, shopifyAccount } = this.state
    if (errorMessage) return <div className="text-danger">{ errorMessage }</div>
    if (isLoading) {
      return (
        <div className="pbxl text-muted">
          <b>Installing on Shopify...</b>
        </div>
      )
    }
    return (
      <div className="mbxl" id="shopify-install" name="shopify-install">
        <h4>
          Shopify Overlay Installation
        </h4>
        <div>
          { shopifyAccount ? this.renderConnectedAccount : this.renderConnectForm }
        </div>
      </div>
    )
  }
}
