import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showLocation, getLocations } from '../../actions/locationActions';

import LocationsListItem from './LocationsListItem';
import LocatorSpinner from '../locator/LocatorSpinner';
import ReactPaginate from 'react-paginate';

export class LocationsList extends Component {
  componentDidMount() {
    const { page_num } = this.props.locations;
    this.props.getLocations({ page_num: page_num });
  }

  handlePaginationClick = (page) => {
    const { search } = this.props.locations;
    const pageToShow = page.selected + 1;
    this.props.getLocations({ page_num: pageToShow, search: search });
  }

  renderLoading() {
    return (
      <div className="locations-loading-state">
        <LocatorSpinner />
      </div>
    )
  }

  renderEmptyState() {
    return (
      <div className="locations-empty-state">
        <div className="locations-empty-header">
          You don't have any locations yet.
        </div>
        <div className="locations-empty-copy">
          Add them by clicking 'Add Location' or import from Google Sheets.
        </div>
      </div>
    )
  }

  renderColumnHeaders() {
    const { isFetching } = this.props.locations;
    if (isFetching) return;

    return (
      <div className="locations-column-names-section">
        <div className="locations-column-names clearfix">
          <div className="locations-column-name name">
            Name / Address
          </div>
          <div className="locations-column-name hours">
            Hours
          </div>
          <div className="locations-column-name phone">
            Phone
          </div>
          <div className="locations-column-name category">
            Categories
          </div>
          <div className="locations-column-name images">
            Images
          </div>
          <div className="locations-column-name in-stock overflow-ellipsis">
            In-Stock
          </div>
        </div>
      </div>
    );
  }

  renderLocations() {
    const { locations, isFetching } = this.props.locations;

    if (isFetching) {
      return (
        <div>
          {this.renderLoading()}
        </div>
      )
    } else if (locations.length === 0) {
      return (
        <div>
          {this.renderEmptyState()}
        </div>
      )
    } else {
      return locations.map((location, key) => {
        return (
          <LocationsListItem key={key} location={location} />
        )
      });
    }
  }

  renderPagination() {
    const { page_num, total_pages, locations, isFetching } = this.props.locations;

    if (isFetching || !locations || total_pages <= 1) return;

    return (
      <div>
        <ReactPaginate
          forcePage={page_num - 1}
          pageCount={total_pages}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={this.handlePaginationClick}
          previousLabel={'‹'}
          nextLabel={'›'}
          containerClassName={'pagination'}
          pageClassName={'pagination-page'}
          pageLinkClassName={'pagination-page-link'}
          previousClassName={'pagination-page-prev'}
          previousLinkClassName={'pagination-page-prev-link'}
          nextClassName={'pagination-page-next'}
          nextLinkClassName={'pagination-page-next-link'}
          activeClassName={'active'}
          breakClassName={'pagination-break'}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="locations-index">
        <div>
          {this.renderColumnHeaders()}

          <div className="location-items">
            {this.renderLocations()}
          </div>

          {this.renderPagination()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.locations
  }
};

export default connect(mapStateToProps, { showLocation, getLocations })(LocationsList);
