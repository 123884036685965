import PropTypes from 'prop-types';
import React, { Component } from 'react';

import URLS from '../../constants/urls'

import isValidMapboxClientKey from '../../utils/mapbox';

export default class WixInstallation extends Component {
  constructor(props) {
    super(props)
    this.state = { showIframePageEmbed: false };
  }

  handleToggleIframeCode = name => {
    return () => {
      this.setState({ [name]: !this.state[name] });
    }
  }

  get renderInstallationOptions() {
    return (
      <div className="mtxl mbxl">
        <h3>
          Manual Installation
        </h3>
        <div>
          While most users prefer to use the native Wix installation, you can also choose to manually install your map to your website if you prefer a more custom setup. Using the instructions below, you can embed your map inside your Wix shopifyMetafieldsGenerated on specific pages.
        </div>
      </div>
    )
  }

  get renderPageEmbed() {
    const { showIframePageEmbed } = this.state;
    const { EMBED_HOST, WEB_HOST } = window.PAGEDATA

    return (
      <div className="mbxl" id="page-embed-install">
        <h3>
          Page Embed Installation
        </h3>
        <div>
          <div>
            <p>
              If you wish to embed your map within the content of your web page instead of using the overlay, the installation is simple. Just add the following code to your website:
            </p>
          </div>
          <div className="code-snippet-wrapper">
            <div className="code-snippet-label">
              Paste this code to embed your map into your website.

              <div className="code-snippet-label-switch" onClick={this.handleToggleIframeCode('showIframePageEmbed')}>
                {showIframePageEmbed ? 'Use Embed Code' : 'Use iFrame Code'}
              </div>
            </div>
            {showIframePageEmbed ? (
              <pre className="code-snippet"><code>&lt;iframe src=&quot;{WEB_HOST}/embed/{this.props.mapKey}&quot; width=&quot;100%&quot; height=&quot;600&quot; style=&quot;height:600px;border:none&quot; allow=&quot;geolocation&quot;&gt;&lt;/iframe&gt;</code></pre>
            ) : (
              <pre className="code-snippet"><code>&lt;div class=&quot;closeby-embed-{this.props.mapKey}&quot;&gt;&lt;/div&gt;<br />
                &lt;script src=&quot;{EMBED_HOST}/v1.js?closeby.mapKey={this.props.mapKey}&embed=true&height=700&quot; async defer&gt;&lt;/script&gt;</code>
              </pre>
            )}
          </div>
          <div className="mtl">
            <p>
              For Wix in-page installation, use <a target="_blank" href="https://closeby.zendesk.com/hc/en-us/articles/6037237985042">this article</a> for more details instructions.
            </p>
            {showIframePageEmbed ? (
              <p>
                To restrict a map to a specific category, you can do so by adding a url param <code>?category=[CATEGORY NAME]</code> when embedding your iframe. <a href="https://closeby.zendesk.com/hc/en-us/articles/360023316974" target="_blank">Learn more</a>
              </p>
            ) : (
              <p>
                To restrict a map to a specific category, you can do so by adding the <code>category: '[CATEGORY NAME]'</code> property to the options in your embed code. <a href="https://closeby.zendesk.com/hc/en-us/articles/360023316974" target="_blank">Learn more</a>
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { mapbox_client_key: mapboxClientKey, valueKey } = this.props;
    const mapboxClientKeyValid = (mapboxClientKey && isValidMapboxClientKey(mapboxClientKey) && !valueKey) || (valueKey && isValidMapboxClientKey(valueKey));

    return mapboxClientKeyValid ? (
      <>
        <div className="page-setup-header">
          By default, your Wix store will automatically install a page for your Closeby map.
        </div>
        <div className="page-setup-header">
          Once you have activated your map above, you will be able to view your locations on your Wix website.
        </div>
        <div className="page-setup-header">
          To add locations, go to your <u><a href="/locations">locations dashboard</a></u>. To preview your map, edit your custom theming and other settings, go to your <u><a href="/maps">map dashboard</a></u>.
        </div>

        {this.renderInstallationOptions}
        <hr className="mbxl" />

        {this.renderPageEmbed}
        <hr className="mbxl" />
      </>
    ) : (
      <div className="page-setup-header">
        Provide a Mapbox Access Token above to proceed with installation instructions.
      </div>
    )
  };
};
