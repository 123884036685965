import React, { Component } from 'react';
import moment from 'moment';

import apiClient from '../../services/apiClient'
import API_ENDPOINTS from '../../constants/apiEndpoints'
import { createFlash } from '../../shared/createFlash';

export default class ShopifyReinstallation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentTheme: null,
      selectedThemeId: null,
      showingShopifyThemes: false,
    };
  }

  componentDidMount() {
    this.fetchThemes();
  }

  fetchThemes = () => {
    this.setState({ isLoading: true })

    return apiClient.get(API_ENDPOINTS.SHOPIFY_SCRIPT_TAGS.THEMES)
      .then((resp) => {
        const themes = resp.data && resp.data.themes.length ? resp.data.themes : [];
        const currentTheme = themes.find((theme) => theme.role === 'main');

        if (currentTheme) {
          this.setState({ isLoading: false, currentTheme: currentTheme, selectedThemeId: currentTheme.id });
        } else {
          this.setState({ isLoading: false });
        }
      });
  }

  setupShopifyTemplates = () => {
    this.setState({ isLoading: true })

    const params = { theme_id: this.state.selectedThemeId };

    return apiClient.post(API_ENDPOINTS.SHOPIFY_SCRIPT_TAGS.SETUP_TEMPLATES, params)
      .then((resp) => {
        location.reload();
      }).catch((e) => {
        this.setState({ isLoading: false, showingShopifyThemes: false });
        createFlash('Sorry there was a problem reinstalling Closeby into your theme.', 'error')
      });
  }

  render() {
    const { currentTheme, showingShopifyThemes } = this.state;

    if (!currentTheme) return null;

    if (showingShopifyThemes) {
      return (
        <div className="mbl">
          <div className="page-setup-option-reset-integration">
            <div className="maps-editor-label">
              Confirm Shopify Theme
              <div className="maps-editor-sublabel">
                This will reinstall Closeby into your Shopify theme code.
              </div>
            </div>
            <div className="page-setup-option-reset-integration-confirmation">
              <div>
                {currentTheme.name}
                <span className="page-setup-option-reset-integration-confirmation-current">Current Theme</span>
                <div className="page-setup-option-reset-integration-confirmation-line2">
                  Last Updated: {moment(currentTheme.updated_at).format('LLL ZZ')}
                </div>
              </div>
            </div>
            <div>
              <button
                className={`btn btn-primary btn-lg ${this.state.isLoading ? 'disabled' : ''}`}
                onClick={this.setupShopifyTemplates}
              >
                Reinstall Closeby
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="mbl">
          <div className="page-setup-option-reset-integration">
            <p>Don't see Closeby in your theme editor?</p>
            <p>Click <a onClick={() => this.setState({ showingShopifyThemes: true })}>here</a> to reinstall the Closeby integration into your Shopify theme.</p>
          </div>
        </div>
      )
    };
  }
}
