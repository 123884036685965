import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { SUPPORT_EMAIL } from '../../constants'
import URLS from '../../constants/urls'
import apiEndpoints from '../../constants/apiEndpoints'
import apiClient from '../../services/apiClient'

import LocatorSpinner from '../locator/LocatorSpinner'

export default class GoogleSheetsInstructions extends Component {
  constructor (props) {
    super(props)
    this.state = {isLoading: true, errorMessage: null}
  }

  componentDidMount () {
    this.fetchGoogleSettings()
  }

  fetchGoogleSettings () {
    this.setState({isLoading: true, errorMessage: null})
    apiClient.get(apiEndpoints.OAUTH_ACCOUNTS.GOOGLE).then((resp) => {
      this.setState({
        isLoading: false,
        errorMessage: null,
        oauthAccount: resp.data.oauth_account,
        syncedGoogleSheet: resp.data.synced_google_sheet
      })
    }).catch(this.handleError.bind(this))
  }

  disconnectGoogle () {
    const { oauthAccount } = this.state
    return apiClient
      .delete(apiEndpoints.OAUTH_ACCOUNTS.DESTROY(oauthAccount.id))
      .then(this.fetchGoogleSettings.bind(this))
      .catch(this.handleError.bind(this))
  }


  handleError (error) {
    this.setState({errorMessage: (error && error.message) || 'Error with Google integration'})
  }

  get showSyncedSheet () {
    const { syncedGoogleSheet, oauthAccount } = this.state
    return syncedGoogleSheet && oauthAccount
  }

  get showDisconnect () {
    const { syncedGoogleSheet, oauthAccount } = this.state
    return oauthAccount && syncedGoogleSheet
  }

  get showConnect () {
    const { oauthAccount } = this.state
    return !oauthAccount
  }

  get renderConnectOrDisconnectButton () {
    const { oauthAccount } = this.state
    const oAuthEmail = oauthAccount ? oauthAccount.email : null
    if (this.showDisconnect) {
      return (
        <div className="integrations-google-disconnect">
          <a
            href="javascript:void(0)"
            onClick={this.disconnectGoogle.bind(this)}
            className="btn btn-sm btn-danger"
          > Disconnect Google</a>
          <span className="text-muted plm">{ oAuthEmail ? `(Connected by Google: ${oAuthEmail})` : null }</span>
        </div>
      )
    }
    if (this.showConnect) {
      return <a
        href={URLS.OAUTH.GOOGLE}
        className="btn btn-primary"
      >Login with Google</a>
    }
  }

  forceSync () {
    this.props.onForceSync()
  }

  get renderSyncNowButton () {
    return this.props.someAreProcessing ?
      <span className="text-muted">Syncing...</span> :
      (
        <a href="javascript:void(0);" onClick={this.forceSync.bind(this)}>Sync my Google Sheet now</a>
      )
  }

  get renderSyncedSheet () {
    const { syncedGoogleSheet } = this.state
    return (
      <div className="mbl">
        <div className="mbl">
          Linked Sheet: <a href={syncedGoogleSheet.spreadsheet_url} target="_blank">{syncedGoogleSheet.spreadsheet_url}</a>
        </div>
        <div>
          <p className="integrations-instructions-subtext">
            Any changes you make to your Google Sheet will update your locations on Closeby every 15 minutes.
          </p>
          <p className="integrations-instructions-subtext">
            Did you make changes to your Google Sheet? {this.renderSyncNowButton}
          </p>
        </div>
      </div>
    )
  }

  get renderSyncInstructions () {
    return (
      <div className="mbl">
        <p>
          Connect your Google account and a new Google Sheet will be created for you.  This file will be automatically imported as your list of locations on Closeby.
        </p>
        <p className="integrations-instructions-subtext">
          Once it is set up, any changes you make to your Google Sheet will update your locations on Closeby every 15 minutes. Adding, editing and removing locations from the Closeby dashboard will be disabled.
        </p>
      </div>
    )
  }

  get renderInstructions () {
    return (
      <div className="integrations-instructions">
        { this.showSyncedSheet ? this.renderSyncedSheet : this.renderSyncInstructions }
        { this.renderConnectOrDisconnectButton }
      </div>
    )
  }

  get renderLoading () {
    return (
      <div className="integrations-instructions">
        <div className="text-center">
          <LocatorSpinner />
        </div>
      </div>
    )
  }

  render () {
    const { errorMessage, isLoading } = this.state
    const { isFree } = this.props

    if (errorMessage) return <div className="text-danger">{errorMessage}</div>

    return (
      <div>
        <h1>
          Google Sheets Integration
        </h1>
        <div className="page-section-note">
          Connect Google Sheets to automatically sync all your locations data.
          <br/>
          Limited to 10,000 locations at this time. <a href="https://closeby.zendesk.com/hc/en-us/articles/360007263714" target="_blank">Learn more</a>
        </div>
        {isFree && (
          <div className="page-section-free-notice">
            Notice: You're currently on the free plan, so no more than 5 locations will be imported.
          </div>
        )}
        { isLoading ? this.renderLoading : this.renderInstructions }
      </div>
    )
  }
}

GoogleSheetsInstructions.propTypes = {
  isFree: PropTypes.bool.isRequired,
  onForceSync: PropTypes.func.isRequired,
  someAreProcessing: PropTypes.bool.isRequired
}
