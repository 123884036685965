import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateMapsEditor } from '../../actions/mapsEditorActions';

import { MapsEditorColorPicker } from './MapsEditorColorPicker';

export class MapsEditorAdvancedTheming extends Component {
  handleColorChange = params => {
    this.props.updateMapsEditor({
      css_overrides: params,
      reset: false
    })
  }

  handleColorRemove = name => {
    return () => {
      let cssOverrides = this.props.css_overrides;
      delete cssOverrides[name];

      this.props.updateMapsEditor({ css_overrides: cssOverrides, reset: false });
      //Note: Added this because DOM wasn't redrawing after updating css_overrides
      this.forceUpdate();
    }
  }

  resetColors = () => {
    this.props.updateMapsEditor({
      css_overrides: {
        reset: true
      }
    })
  }

  renderClearLink = name => {
    if (this.props.css_overrides[name]) {
      return (
        <a onClick={this.handleColorRemove(name)} className="maps-editor-label-clear-value">
          Clear
        </a>
      )
    }
  }

  get renderListBackgroundColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Locations List Background
          <div className="maps-editor-sublabel">
            Background color of your locations list.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="list_background_color"
        />
      </div>
    )
  }

  get renderActionTextColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Button Text Color
          <div className="maps-editor-sublabel">
            Text color of your location button.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="button_text_color"
        />
      </div>
    )
  }

  get renderPopupTextColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Popup Text Color
          <div className="maps-editor-sublabel">
            Text color of your map poups.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="popup_text_color"
        />
      </div>
    )
  }

  get renderLocationTitleColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Location Title Color
          {this.renderClearLink('location_title_color')}
          <div className="maps-editor-sublabel">
            Location name color in full list and location details.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="location_title_color"
        />
      </div>
    )
  }

  get renderLocationSubtitleColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Location Subtitle Color
          {this.renderClearLink('location_subtitle_color')}
          <div className="maps-editor-sublabel">
            Location address color in full list and location details.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="location_subtitle_color"
        />
      </div>
    )
  }

  get renderLocationDetailsColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Location Details Color
          {this.renderClearLink('location_details_color')}
          <div className="maps-editor-sublabel">
            Text color of location hours and additional details.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="location_details_color"
        />
      </div>
    )
  }

  get renderFiltersBackgroundColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Filters Background
          {this.renderClearLink('filters_background_color')}
          <div className="maps-editor-sublabel">
            Background color of your location filters.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="filters_background_color"
        />
      </div>
    )
  }

  get renderFiltersPlaceholderColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Filters Placeholder Color
          {this.renderClearLink('filters_placeholder_color')}
          <div className="maps-editor-sublabel">
            Text color of your filters empty state.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="filters_placeholder_color"
        />
      </div>
    )
  }

  get renderFiltersSelectedColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Filters Selected Icon Color
          {this.renderClearLink('filters_selected_icon_color')}
          <div className="maps-editor-sublabel">
            Color for the icon on selected filters.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="filters_selected_icon_color"
        />
      </div>
    )
  }

  get renderSearchTextColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Search Text Color
          {this.renderClearLink('search_text_color')}
          <div className="maps-editor-sublabel">
            Text color of your autocomplete search.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="search_text_color"
        />
      </div>
    )
  }

  get renderSearchIconColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Search Icon Color
          {this.renderClearLink('search_icon_color')}
          <div className="maps-editor-sublabel">
            Color of your autocomplete search icon.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="search_icon_color"
        />
      </div>
    )
  }

  get renderSearchPlaceholderColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Search Placeholder Color
          {this.renderClearLink('search_placeholder_text_color')}
          <div className="maps-editor-sublabel">
            Text color of your autocomplete search placeholder.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="search_placeholder_text_color"
        />
      </div>
    )
  }

  get renderSearchBackgroundColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Search Input Background
          {this.renderClearLink('search_background_color')}
          <div className="maps-editor-sublabel">
            Background color of your autocomplete search.
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="search_background_color"
        />
      </div>
    )
  }

  get renderUserLocationColor() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          User Location Icon Color
          {this.renderClearLink('user_location_color')}
          <div className="maps-editor-sublabel">
            Color of the user's current location on the map when the default map position is set to "geolocate".
          </div>
        </div>
        <MapsEditorColorPicker
          defaultColor=""
          css={this.props.css_overrides}
          handleChange={this.handleColorChange}
          name="user_location_color"
        />
      </div>
    )
  }

  render() {
    return (
      <div className="maps-editor-form-group ptl">
        <div className="page-section-note">
          <div className="mbs">Warning: Making changes to the advanced theming options may be more difficult to revert.</div>
          <div className="page-section-note-subcopy">
            <a href="javascript:void(0)" onClick={this.resetColors}>Click here</a> to reset your customizations to their default values.
          </div>
        </div>
        <div className="row ptl">
          <div className="col-md-6 prxl-md">
            {this.renderListBackgroundColor}
            {this.renderActionTextColor}
            {this.renderPopupTextColor}
            {this.renderFiltersBackgroundColor}
            {this.renderFiltersPlaceholderColor}
            {this.renderFiltersSelectedColor}
          </div>
          <div className="col-md-6">
            {this.renderLocationTitleColor}
            {this.renderLocationSubtitleColor}
            {this.renderLocationDetailsColor}
          </div>
        </div>
        <hr/>
        <div className="row ptxl">
          <div className="col-md-6 prxl-md">
            {this.renderSearchTextColor}
            {this.renderSearchIconColor}
            {this.renderUserLocationColor}
          </div>
          <div className="col-md-6">
            {this.renderSearchPlaceholderColor}
            {this.renderSearchBackgroundColor}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorAdvancedTheming);
