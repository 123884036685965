import React, { Component } from 'react'
import URLS from '../../constants/urls'

export default class IntegrationsHeader extends Component {
  render() {
    return (
      <div className="page-header clearfix">
        <div className="page-header-title">
          Sync Locations
        </div>
        <div className="page-header-actions">
          {this.props.type === 'CSV' ? (
            <a href={URLS.INTEGRATIONS.GOOGLE_SHEETS} className="btn btn-default float-right">Sync Google Sheet</a>
          ) : (
            <a href={URLS.INTEGRATIONS.CSV} className="btn btn-default float-right">Import CSV File</a>
          )}
          <a href={URLS.LOCATIONS.INDEX} className="btn btn-default float-right mrs">Back To Locations</a>
        </div>
      </div>
    );
  }
}
