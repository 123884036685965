import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Provider } from 'react-redux';

import store, { stateInitializer } from '../../store';
import StatsHeader from './StatsHeader';
import StatsPage from './StatsPage';

export default class StatsIndex extends Component {
  constructor(props) {
    super(props);

    stateInitializer({
      stats: {
        isFetchingSuggestions: true,
        isFetchingDevices: true,
        isFetchingViews: true,
        googleAnalyticsId: props.googleAnalyticsId
      },
    });
  }

  render() {
    return (
      <Provider store={store}>
        <div>
          <StatsHeader />
          <StatsPage />
        </div>
      </Provider>
    );
  }
}

StatsIndex.propTypes = {
  googleAnalyticsId: PropTypes.string
};
