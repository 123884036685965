import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { hideLocation } from "../../actions/locationActions";

import LocationEditForm from './LocationEditForm';

export class LocationEdit extends Component {
  handleHideLocation = () => {
    this.props.hideLocation({ showing: false });
  }

  render() {

    return (
      <div>
        <div className={`location-form-overlay ${this.props.showing ? 'showing' : ''}`}>
          <LocationEditForm
            showing={this.props.showing}
            hasGoogleSheetsSyncEnabled={this.props.hasGoogleSheetsSyncEnabled}
            showCustomButtonWithDefault={this.props.showCustomButtonWithDefault}
            googleSheetsUrl={this.props.googleSheetsUrl}
          />
        </div>
        <div className={`location-form-overlay-bg ${this.props.showing ? 'showing' : ''}`} onClick={this.handleHideLocation}>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    hasGoogleSheetsSyncEnabled: state.locations.hasGoogleSheetsSyncEnabled,
    showing: state.locationForm.showing,
    showCustomButtonWithDefault: state.locationForm.showCustomButtonWithDefault
  }
};

export default connect(mapStateToProps, { hideLocation })(LocationEdit);
