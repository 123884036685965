import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TwitterPicker } from 'react-color';
import union from 'lodash.union';

import { updateMapsEditor } from '../../actions/mapsEditorActions';
import { MapsEditorMarkerImage } from './MapsEditorMarkerImage';

export class MapsEditorCategoryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: null,
      color: props.color,
      image: props.image,
      colors: union([
        props.primaryColor, '#ea704b', '#715f30', '#88852b', '#5eaf44', '#30bd68', '#406bcc', '#0a7cbd', '#8195a7', '#514fb1', '#bf1b46', '#d03434', '#cc7021', '#7d35a5', '#724f98', '#4a4a4a', '#333333'
      ])
    }
  }

  changeColorInput = (e) => {
    const newColor = e.target.value;
    this.handleColorChange(newColor);
  }

  handleColorPickerChange = (color) => {
    this.handleColorChange(color.hex);
  }

  handleColorChange = (value) => {
    const { categories_attributes, id } = this.props;

    let categoryExistsInState = false,
        newCategoryState = categories_attributes.map(category => {
          if (category && category.id === id) {
            categoryExistsInState = true;
            return { ...category, color: value };
          }
          return category;
        });
  
    if (!categoryExistsInState) {
      newCategoryState.push({
        id: this.props.id,
        color: value
      });
    }
  
    this.props.updateMapsEditor({ categories_attributes: newCategoryState });
  
    this.setState({
      showing: null,
      color: value
    });
  }

  showColorPicker = (key) => {
    return (e) => {
      e.preventDefault();
      this.setState({ showing: key });
    }
  };

  hideColorPicker = (key) => {
    return (e) => {
      e.preventDefault();
      this.setState({ showing: null });
    }
  };

  handleImageChange = (url) => {
    const { categories_attributes, id } = this.props;

    let categoryExistsInState = false;
    let newCategoryState = categories_attributes.map(category => {
      if (category && category.id === id) {
        categoryExistsInState = true;
        return { ...category, marker_background_url: url };
      }
      return category;
    });

    if (!categoryExistsInState) {
      newCategoryState.push({
        id: this.props.id,
        marker_background_url: url
      });
    }

    this.props.updateMapsEditor({ categories_attributes: newCategoryState });

    this.setState({ image: url });
  }

  render() {
    const {
      key,
      name,
      primaryColor,
      marker_style,
      s3MarkerFormData,
      s3MarkerPostUrl,
      s3MarkerPostHost,
    } = this.props;
    const markerMaxFileSize = 75000;

    return (
      <div className="row maps-editor-category-row" key={key}>
        <div className="col-8 maps-editor-category-name">
          {name}
        </div>
        <div className="col-4 maps-editor-category-inputs">
          {marker_style === 'marker_custom' ? (
            <MapsEditorMarkerImage
              name={`category-image-${key}`}
              value={this.state.image}
              s3MarkerFormData={s3MarkerFormData}
              s3MarkerPostUrl={s3MarkerPostUrl}
              s3MarkerPostHost={s3MarkerPostHost}
              handleChange={this.handleImageChange}
              maxFileSize={markerMaxFileSize}
            />
          ) : (
            <React.Fragment>
              <label className="maps-editor-category-color-preview" style={{ backgroundColor: this.state.color }} htmlFor={`color-input-${key}`} onClick={this.state.showing === key ? this.hideColorPicker() : this.showColorPicker(key)} />
              <div
                className="form-control cursor-pointer"
                onClick={this.state.showing === key ? this.hideColorPicker() : this.showColorPicker(key)}
              >
                {this.state.color ? this.state.color : 'Default'}
              </div>
              <div className={`maps-editor-category-color-picker ${this.state.showing === key ? 'showing' : ''}`}>
                <TwitterPicker
                  width={212}
                  color={ this.state.color ? this.state.color : primaryColor }
                  onChangeComplete={ this.handleColorPickerChange }
                  triangle={ 'top-right' }
                  colors={this.state.colors}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorCategoryForm);
