import React, { Component } from 'react'
import { connect } from 'react-redux'
import URLS from '../../constants/urls'

import { updateLocationsList, getLocations } from '../../actions/locationActions'

export class LocationsSearch extends Component {
  handleInputChange = (event) => {
    this.props.updateLocationsList({
      isFetching: true,
      locations: [],
      page_num: 1,
      search: event.currentTarget.value,
    });
    this.props.getLocations({ page_num: 1, search: event.currentTarget.value });
  }

  render() {
    return (
      <div className="page-search">
        <input
          type="text"
          className="form-control page-search-input"
          id="page_search"
          name="search"
          onChange={this.handleInputChange}
          value={this.props.search}
        />
        <label htmlFor="page_search" className="page-search-label"><i className="ion-ios-search"></i></label>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.locations
  }
};

export default connect(mapStateToProps, { updateLocationsList, getLocations })(LocationsSearch);
