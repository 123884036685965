import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SUPPORT_EMAIL } from '../../constants';
import { mapboxStyles } from '../../constants/mapThemeOptions';

import HowItWorks from './HowItWorks';

export default class MarketingFeatures extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount () {
    Track.event('Features page viewed');
  }

  get renderStyles() {
    return mapboxStyles.map((style, key) => {
      return (
        <div className="col-sm-4 features-style-option" key={key}>
          <div className="features-style-option-in">
            <img src={style.image} className="features-style-img" />
            <div className="features-style-name">
              {style.name}
            </div>
          </div>
        </div>
      )
    });
  }

  render() {
    return (
      <div className={this.props.showEmbedOptions ? 'page-top-padding' : ''}>
        <div className="features-page">
          {this.props.showEmbedOptions && (
            <>
              <div className="wrapper-800 pll-xs pln-sm prl-xs prn-sm">
                <h2 className="mbxl">
                  Map Embed Options

                  <div className="features-list-subheader">
                    Embed your map on any website.
                  </div>
                </h2>
              </div>
              <HowItWorks
                overlayImage={this.props.overlayImage}
                pageEmbedImage={this.props.pageEmbedImage}
              />
            </>
          )}
          <div className="features-list-section">
            <div className="wrapper-1000">
              <h2 className="mbxl">
                Features Overview
                <div className="features-list-subheader">
                  We're always adding new features! If you have any questions, feel free to <a href={`mailto:${SUPPORT_EMAIL}?subject=Closeby%20Onboarding`}>send an email</a> to our team.
                </div>
              </h2>
              <div className="row">
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Custom details per location
                  <div className="features-item-desc">
                    Add details to your locations with basic formatting options and automatic link creation.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Open hours and contact details
                  <div className="features-item-desc">
                    Add location hours, phone number and email to a location to help people get in touch.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Add custom categories per location
                  <div className="features-item-desc">
                    Set custom tags for each location, whether it's type of vendor or product category.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Automatic links for directions
                  <div className="features-item-desc">
                    Getting directions are just one click away with immediate directions on Google Maps.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Set default map positioning
                  <div className="features-item-desc">
                    Choose to set a default map position or position the map by visitors' IP address.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Search and sort by distance
                  <div className="features-item-desc">
                    Let customers search by city name, state, zip code and more.
                  </div>
                </div>

                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Custom map theming options
                  <div className="features-item-desc">
                    Choose from a variety of robust themes that go far beyond other locators on the market.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Custom color and font for your brand
                  <div className="features-item-desc">
                    Set a custom color and font family and the rest of the map and location pins will use it.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Set miles or kilometers on your map
                  <div className="features-item-desc">
                    In a country using kilometers?  Easily change map settings to use the metric system instead.
                  </div>
                </div>
                 <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Custom HTML links to open the map
                  <div className="features-item-desc">
                    Use HTML links and other custom triggers to best works with your website user experience.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Track location and device views
                  <div className="features-item-desc">
                    Integrate with your Google Analytics account or simply use the built-in usage tracking.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Add custom delivery links
                  <div className="features-item-desc">
                    If your in the business of delivery, add links to UberEats, Doordash, Postmates and more.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Customize the call-to-action
                  <div className="features-item-desc">
                    Link to other stores, products or leave the call-to-action as a "Get Directions" link.
                  </div>
                </div>
                <div className="col-sm-6 features-item-col">
                  <i className="ion-ios-location-outline"></i>
                  Sync with Google Sheets
                  <div className="features-item-desc">
                    If using the web app is too complicated for your team, simply share a new Google Sheet (beta).
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="features-list-section">
            <div className="wrapper-1000">
              <h2 className="mbxl">
                Custom Themes
                <div className="features-list-subheader">
                  Choose from a variety of theming options, from black & white to something completely your own.
                </div>
              </h2>
              <div className="row">
                {this.renderStyles}

                <div className="col-sm-4 features-style-option">
                  <div className="features-style-option-in">
                    <div className="features-style-placeholder-name">
                      Design your own in <a href="https://docs.mapbox.com/studio-manual/overview/" target="_blank">Mapbox Studio</a> & we'll import it for you.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MarketingFeatures.propTypes = {
  overlayImage: PropTypes.string.isRequired,
  pageEmbedImage: PropTypes.string.isRequired,
  showEmbedOptions: PropTypes.bool.isRequired,
};
