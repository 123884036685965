import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SUPPORT_EMAIL } from '../../constants'

export default class MarketingAbout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount () {
    Track.event('About page viewed');
  }

  render() {
    return (
      <div className="page-top-padding">
        <div className="about-page">
          <div className="wrapper-800">
            <div>
              <div className="page-overlay-note">
                Though it is often overlooked by companies, we see store locators as one of the most important ways to convert your online visitors into offline business.
              </div>
              <div className="mbxl">
                <p>
                  If a business wants a locator, usually they will either pay thousands of dollars for an engineer and designer to create one or they will use a pre-built widget (like Closeby) and pay a subscription per month.
                </p>
                <p>
                  The benefit of using a widget is not only the time and cost savings, but also the reassurance that you know what you're buying beforehand and that it'll be maintained by a team for the foreseeable future.
                </p>
              </div>
              <div className="about-letter">
                <p>
                  At Closeby, we strive to offer the best-in-class locator for brands by using modern technology and UX design to help them capture more potential business.
                </p>
                <p>
                  We're creators and hackers.  Over the years, we've helped build websites for Fortune 500 companies, Top 40 artists, online brands and even our own startups.  We can relate and we know the importance of creating delightful user experiences.
                </p>
                <p>
                  So, if you have any questions or feature requests, feel free to reach out and we'd love to hear from you.
                </p>
                <p>
                  Closeby Team
                  <br/>
                  <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
