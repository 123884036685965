import PropTypes from 'prop-types';
import React, { Component } from 'react';
import differenceby from 'lodash.differenceby';

import TagsInput from 'react-tagsinput'
import Autosuggest from 'react-autosuggest'

class CategoriesPicker extends Component {
  constructor (props) {
    super(props)
  }

  handleChange = (categories) => {
    this.props.updateLocation({ categories });
  }

  currentCategoriesAsSelectOptions = () => {
    const { categories } = this.props;
    return categories.map((category)=> {
      return { name: category, value: category }
    })
  }

  render () {
    const { categories } = this.props;
    const currentCategoriesArray = categories.length ? this.currentCategoriesAsSelectOptions() : []

    function autocompleteRenderInput ({addTag, ...props}) {
      const handleOnChange = (e, {newValue, method}) => {
        if (method === 'enter') {
          e.preventDefault()
        } else {
          props.onChange(e)
        }
      }

      const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
      const inputLength = inputValue.length
      const suggestions = differenceby(props.suggestions, props.categories, 'value')

      return (
        <Autosuggest
          ref={props.ref}
          suggestions={suggestions}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
          inputProps={{...props, onChange: handleOnChange}}
          onSuggestionSelected={(e, {suggestion}) => {
            addTag(suggestion.name)
          }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={() => {}}
        />
      )
    }

    return (
      <div>
        <TagsInput
          value={this.props.categories}
          onChange={this.handleChange}
          onlyUnique
          addOnBlur
          inputProps={{
            placeholder: 'Add a category',
            categories: currentCategoriesArray,
            suggestions: this.props.categories_available
          }}
          renderInput={autocompleteRenderInput}
        />
      </div>
    )
  }
}

CategoriesPicker.propTypes = {
  updateLocation: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  categories_available: PropTypes.array.isRequired,
}

export default CategoriesPicker
