import PropTypes from "prop-types";
import React, { Component } from "react";
import { Provider } from "react-redux";

import store, { stateInitializer } from "../../store";
import PaymentBannerNotice from "../subscriptions/PaymentBannerNotice";
import LocationsListHeader from "./LocationsListHeader";
import LocationsList from "./LocationsList";
import LocationEdit from "./LocationEdit";

export default class LocationsIndex extends Component {
  constructor(props) {
    super(props);

    stateInitializer({
      locations: {
        isFetching: true,
        page_num: 1,
        search: '',
        hasGoogleSheetsSyncEnabled: props.hasGoogleSheetsSyncEnabled,
      },
      locationForm: {
        buttonSetting: props.buttonSetting,
        categories_available: props.categories_available,
        mapboxClientKey: props.mapboxClientKey,
        s3BannerProps: {
          s3FormData: props.s3BannerFormData,
          s3PostUrl: props.s3BannerPostUrl,
          s3PostHost: props.s3BannerPostHost
        },
        showing: false,
      }
    });
  }

  render() {
    const { isFree, isTrialing } = this.props

    return (
      <Provider store={store}>
        <div>
          <LocationsListHeader
            googleSheetsUrl={this.props.googleSheetsUrl}
          />
          {this.props.showPaymentMethodBanner ? <PaymentBannerNotice {...this.props} /> : null}
          <LocationsList />
          <LocationEdit
            googleSheetsUrl={this.props.googleSheetsUrl}
          />
        </div>
      </Provider>
    );
  }
}

LocationsIndex.propTypes = {
  buttonSetting: PropTypes.string.isRequired,
  showPaymentMethodBanner: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  isTrialing: PropTypes.bool.isRequired,
  hasWixConnected: PropTypes.bool.isRequired,
};
