import StreetsImage from 'images/themes/streets.png';
import LightGreyscaleImage from 'images/themes/light-greyscale.png';
import DarkGreyscaleImage from 'images/themes/dark-greyscale.png';
import BasicChilledImage from 'images/themes/basic-chilled.png';
import BasicOvercastImage from 'images/themes/basic-overcast.png';
import BasicSeashoreImage from 'images/themes/basic-seashore.png';
import BasicSpringImage from 'images/themes/basic-spring.png';
import MonochromeMidnightImage from 'images/themes/monochrome-midnight.png';
import MonochromeGoldenImage from 'images/themes/monochrome-golden.png';
import MoonlightImage from 'images/themes/moonlight.png';
import LeShineImage from 'images/themes/le-shine.png';
import DecimalImage from 'images/themes/decimal.png';
import NightTimeImage from 'images/themes/night-time.png';
import LightPinkImage from 'images/themes/light-pink.png';
import LightBlueImage from 'images/themes/light-blue.png';
import LightGreenImage from 'images/themes/light-green.png';
import WhaamImage from 'images/themes/whaam.png';
import Century20thImage from 'images/themes/20th-century.png';
import NauticoImage from 'images/themes/nautico.png';
import BlueprintImage from 'images/themes/blueprint.png';
import FrankImage from 'images/themes/frank.png';
import SatelliteStreetsImage from 'images/themes/satellite-streets.png';

export const mapboxStyles = [
  {
    name: 'Streets',
    url: 'mapbox://styles/mapbox/streets-v10',
    image: StreetsImage
  },
  {
    name: 'Light Greyscale',
    url: 'mapbox://styles/mapbox/light-v9',
    image: LightGreyscaleImage
  },
  {
    name: 'Dark Greyscale',
    url: 'mapbox://styles/mapbox/dark-v9',
    image: DarkGreyscaleImage
  },
  {
    name: 'Chilled',
    url: 'mapbox://styles/closeby/ckle9sw1k0y2i17o8bycwa6dl',
    image: BasicChilledImage
  },
  {
    name: 'Overcast',
    url: 'mapbox://styles/closeby/ckleb0vt933uq17ryx9l3i4tc',
    image: BasicOvercastImage
  },
  {
    name: 'Seashore',
    url: 'mapbox://styles/closeby/ckleb5n4r23ex17l4pl624s4l',
    image: BasicSeashoreImage
  },
  {
    name: 'Spring',
    url: 'mapbox://styles/closeby/cklebamtu0zgf17rxtktxx12e',
    image: BasicSpringImage
  },
  {
    name: 'Midnight',
    url: 'mapbox://styles/closeby/ckledhh0c2nuz17q9yo727kwv',
    image: MonochromeMidnightImage
  },
  {
    name: 'Decimal',
    url: 'mapbox://styles/closeby/cjh6zk4k65eol2rrxx6v3y7cw',
    image: DecimalImage
  },
  {
    name: 'Night Time',
    url: 'mapbox://styles/closeby/cjh8g930f03yf2sqt1gnmerse',
    image: NightTimeImage
  },
  {
    name: 'Le Shine',
    url: 'mapbox://styles/closeby/cjh5jct764cor2rn0aadvgx7w',
    image: LeShineImage
  },
  {
    name: '20th Century',
    url: 'mapbox://styles/closeby/cjh8hqgho0rcc2rp61kjcs71a',
    image: Century20thImage
  },
  {
    name: 'Nautico',
    url: 'mapbox://styles/closeby/cjh8i3chp1dba2rmkvs00hy1u',
    image: NauticoImage
  },
  {
    name: 'Frank',
    url: 'mapbox://styles/closeby/ckcfnaa380at41ho5rd9tz4s3',
    image: FrankImage
  },
  {
    name: 'Moonlight',
    url: 'mapbox://styles/closeby/ckleecl3j4ycd18p6pv36sbdn',
    image: MoonlightImage
  },
  {
    name: 'Blueprint',
    url: 'mapbox://styles/closeby/ckcfn1aa50akp1ho5bxao4n5q',
    image: BlueprintImage
  },
  {
    name: 'Light Pink',
    url: 'mapbox://styles/closeby/cjh5ms05v2iqm2rmsoqbb5w73',
    image: LightPinkImage
  },
  {
    name: 'Light Blue',
    url: 'mapbox://styles/closeby/cjh5mvt4m45532rrxy07o5emc',
    image: LightBlueImage
  },
  {
    name: 'Light Green',
    url: 'mapbox://styles/closeby/ckcfnj6lq0ave1imvro33jyau',
    image: LightGreenImage
  },
  {
    name: 'Golden',
    url: 'mapbox://styles/closeby/ckledpcxf129417qww4r2yjqx',
    image: MonochromeGoldenImage
  },
  {
    name: 'Whaam!',
    url: 'mapbox://styles/closeby/cjh8grq7e06532smsvqnuhelx',
    image: WhaamImage
  },
  {
    name: 'Satellite',
    url: 'mapbox://styles/closeby/cklee0uv52ihx17jy2wq01p0c',
    image: SatelliteStreetsImage
  },
]
