import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PostsList extends Component {
  get renderListItems() {
    return (
      <div className="container">
        <div className="row pages-post-items">
          <div className="col-md-6 pages-post-item">
            <div className="pages-post-item-in">
              Test
            </div>
          </div>
          <div className="col-md-6 pages-post-item">
            <div className="pages-post-item-in">
              Test
            </div>
          </div>
          <div className="col-md-6 pages-post-item">
            <div className="pages-post-item-in">
              Test
            </div>
          </div>
          <div className="col-md-6 pages-post-item">
            <div className="pages-post-item-in">
              Test
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="pages-show-recent-posts">
        <h2>
          Recent Posts
        </h2>
        {this.renderListItems}
      </div>
    );
  }
}

PostsList.propTypes = {
  posts: PropTypes.array
};
