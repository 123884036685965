import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState, createFromBlockArray, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { getLocations, hideLocation, updateLocation, saveLocation, deleteLocation, showLocation } from '../../actions/locationActions';
import { createFlash } from '../../shared/createFlash';

import LocationBannerUploader from './LocationBannerUploader';
import AddressOnelineInput from './AddressOnelineInput';
import HoursPicker from './HoursPicker';
import CategoriesPicker from './CategoriesPicker';
import LocationEditSecondaryImages from './LocationEditSecondaryImages';

export class LocationEditForm extends Component {
  constructor(props) {
    super(props)
    this.state = { isSaving: false }
  }

  handleHideLocation = () => {
    this.props.hideLocation({ showing: false });
  }

  handleChange = (name, normalizer = value => value) => {
    return event => {
      const { target: { value } } = event;
      this.props.updateLocation({ [name]: normalizer(value) });
    }
  }

  handleCheckboxChange = name => {
    return event => {
      if (name === 'status') {
        this.props.updateLocation({ [name]: event.target.checked ? 'out_of_stock' : 'in_stock' });
      } else {
        this.props.updateLocation({ [name]: event.target.checked });
      }
    }
  }

  hangleEditorChange = (updatedContent) => {
    this.props.updateLocation({ welcome_text_json: updatedContent });
  }

  handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()

    this.setState({ isSaving: true });

    let stateToSave = Object.assign({}, this.props.form)
    let wysiwygContent = this.props.form.welcome_text_json.getCurrentContent()
    stateToSave.welcome_text_json = convertToRaw(wysiwygContent)
    stateToSave.welcome_text = draftToHtml(stateToSave.welcome_text_json)

    this.props.saveLocation(this.props.form.id, { location: stateToSave })
    .then(json => {
      if (json.status === 'error') {
        createFlash(json.error_message, 'error')
      } else {
        if (json.new_record) {
          createFlash('New location has been saved!', 'success');
        } else {
          createFlash('Your location changes have been saved!', 'success');
        }
        this.props.hideLocation({ showing: false });
        this.props.getLocations({ page_num: this.props.listPageNum });
      }

      this.setState({ isSaving: false });
    }).catch((error) => {
      let msg = 'Something went wrong trying to save these changes please try again.'
      if (error.response && error.response && error.response.data.error_message) {
        msg = error.response.data.error_message
      }
      createFlash(msg, 'error')
      this.setState({ isSaving: false });
    })
  }

  handleDelete = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (this.props.hasGoogleSheetsSyncEnabled) {
      createFlash('Adding, editing and deleting locations is disabled when google sheets syncing is turned on.', 'error');
      return;
    }

    this.props.deleteLocation(this.props.form.id)
    .then(json => {
      createFlash('Your location has been deleted.', 'notice')
      this.props.hideLocation({ 'showing': false });
      this.props.getLocations({ page_num: this.props.listPageNum });
    }).catch((error) => {
      createFlash('Something went wrong. Please try again.', 'error')
    })
  }

  get renderSampleDeleteNotion() {
    if (!this.props.form.sample) return;

    return (
      <div className="location-edit-form-sample-banner">
        <div>This is just an example location :)</div>
        <a className="location-edit-form-sample-delete" onClick={this.handleDelete}>
          Delete Location
        </a>
      </div>
    )
  }

  get renderOutOfStockNotice() {
    if (this.props.form.sample) return;
    if (this.props.form.status === 'in_stock') return;

    return (
      <div className="location-edit-form-outofstock-banner">
        This location has been marked "Out-Of-Stock"
      </div>
    )
  }

  get renderBannerImage() {
    const { form: { banner_url }, s3BannerProps } = this.props;
    const { isSaving } = this.state;
    const url = banner_url && banner_url.length && banner_url.includes('//') ? banner_url : '';

    return (
      <div className={`form-group ${isSaving ? 'pointer-none' : ''}`}>
        <LocationBannerUploader
          {...s3BannerProps}
          fileUrl={banner_url || ''}
          onBannerChange={this.props.updateLocation}
          onRemoveBanner={this.props.updateLocation}
          name="banner_url"
        />
      </div>
    )
  }

  get renderTitle() {
    return (
      <div className="form-group">
        <label className="form-label">
          Location Name <span className="form-label-required">*</span>
        </label>
        <input type="text" className="form-control" placeholder="Mountain View HQ" value={this.props.form.title} onChange={this.handleChange('title')} required disabled={this.props.hasGoogleSheetsSyncEnabled} />
      </div>
    )
  }

  get renderPhoneNumber() {
    return (
      <div className="form-group">
        <label className="form-label">
          Phone Number
        </label>
        <input type="text" className="form-control" placeholder="(805) 123-1234" value={this.props.form.phone_number} onChange={this.handleChange('phone_number')} />
      </div>
    )
  }

  get renderAddress() {
    return (
      <div className="form-group location-edit-form-group form-address">
        <label className="form-label">
          Address <span className="form-label-required">*</span>
        </label>
        <AddressOnelineInput
          addressFull={this.props.form.address_full}
          latitude={this.props.form.latitude}
          longitude={this.props.form.longitude}
          mapboxClientKey={this.props.mapboxClientKey}
          onChange={this.props.updateLocation}
        />
      </div>
    )
  }

  get renderWebsiteAndEmail() {
    return (
      <div className="row location-edit-form-group">
        <div className="col-md-6 prs-md">
          <div className="form-group">
            <label className="form-label">
              Website
            </label>
            <input type="text" className="form-control" placeholder="https://mystore.com" value={this.props.form.website} onChange={this.handleChange('website')} />
          </div>
        </div>
        <div className="col-md-6 pls-md">
          <div className="form-group">
            <label className="form-label">
              Email
            </label>
            <input type="text" className="form-control" placeholder="contact@email.com" value={this.props.form.email} onChange={this.handleChange('email')} />
          </div>
        </div>
      </div>
    )
  }

  get renderCategories () {
    return (
      <div className="form-group location-edit-form-group form-categories">
        <label className="form-label">
          Categories
          <small className="float-right"><i>Note: Press enter to set a new category</i></small>
        </label>
        <CategoriesPicker
          categories={this.props.form.categories}
          categories_available={this.props.form.categories_available}
          updateLocation={this.props.updateLocation}
        />
      </div>
    )
  }

  get renderHours () {
    return (
      <div className="form-group location-edit-form-group form-hours">
        <label className="form-label">
          Open Hours
        </label>
        <HoursPicker
          onChange={this.props.updateLocation}
          locationHours={this.props.form.location_hours}
        />
      </div>
    )
  }

  get renderWelcomeMessage() {
    return (
      <div className="form-group location-edit-form-group welcome-message">
        <label className="form-label">
          Description
        </label>
        <div className="pages-edit-form-welcome-text">
          <Editor
            editorState={this.props.form.welcome_text_json}
            toolbarClassName="wysiwyg-editor-toolbar"
            wrapperClassName="wysiwyg-editor-wrapper"
            editorClassName="wysiwyg-editor-content"
            onEditorStateChange={this.hangleEditorChange}
            handlePastedText={(text) => {
              const blockMap = ContentState.createFromText(text.trim()).blockMap;
              const newState = Modifier.replaceWithFragment(this.props.form.welcome_text_json.getCurrentContent(), this.props.form.welcome_text_json.getSelection(), blockMap);
              this.hangleEditorChange(EditorState.push(this.props.form.welcome_text_json, newState, 'insert-fragment'));
              return true;
            }}
            toolbar={{
              options: ['inline', 'list', 'link'],
              inline: {
                inDropdown: true,
                options: ['bold', 'italic', 'underline', 'strikethrough']
              },
              list: { inDropdown: true }
            }}
          />
        </div>
      </div>
    )
  }

  get renderOutOfStock() {
    const { status } = this.props.form;

    return (
      <div className="mbm">
        <label className="form-checkbox-label">
          <input
            type="checkbox"
            name="status"
            className="form-checkbox-input"
            checked={ status === 'out_of_stock' }
            onChange={this.handleCheckboxChange('status')}
          />
          <div className="form-checkbox-label-copy">
            Out-of-stock: will not appear on public map&nbsp;&nbsp;<i className="ion-alert-circled text-danger"/>
          </div>
        </label>
      </div>
    )
  }

  get renderPriorityLocation() {
    const { priority } = this.props.form;

    return (
      <div className="mbm">
        <label className="form-checkbox-label">
          <input
            type="checkbox"
            name="priority"
            className="form-checkbox-input"
            checked={priority}
            onChange={this.handleCheckboxChange('priority')}
          />
          <div className="form-checkbox-label-copy">
            Priority: will appear first when shown in location list
          </div>
        </label>
      </div>
    )
  }

  get renderAdvancedOptions() {
    const { buttonSetting } = this.props;
    const { custom_button_enabled } = this.props.form;

    const showCustomButtonWithDefault = buttonSetting === 'custom_button_overrides_directions' ? true : false;

    return (
      <div>
        <div className="location-edit-form-delivery-header">
          Advanced Options
        </div>
        {this.renderPriorityLocation}

        {this.renderOutOfStock}
      </div>
    )
  }

  get renderCustomButton() {
    const { buttonSetting } = this.props;

    return (
      <div>
        <div className="row mtm">
          <div className="col-6 prs">
            <label className="form-label">
              Button Text
            </label>
            <input type="text" className="form-control" placeholder="View Products" value={this.props.form.custom_button_text} onChange={this.handleChange('custom_button_text')} />
          </div>
          <div className="col-6 pls">
            <label className="form-label">
              Custom URL
            </label>
            <input type="text" className="form-control" placeholder="https://shopify.com/collection" value={this.props.form.custom_button_url} onChange={this.handleChange('custom_button_url')} />
          </div>
        </div>
        <div className="location-edit-form-custom-button-note">
          Note: If you would like to always show directions or always hide the "Get Directions" button, update your button settings in your theme options.
        </div>
      </div>
    )
  }

  get renderDeliveryLinks() {
    return (
      <div className="location-edit-form-delivery-links">
        <div className="location-edit-form-delivery-header">
          Delivery/Integration Links
          <a href="https://closeby.zendesk.com/hc/en-us/articles/360013122039" target="_blank" className="location-edit-form-delivery-header-link">Learn More</a>
        </div>
        <div className="row location-edit-form-group">
          <div className="col-md-6 prs-md">
            <div className="form-group">
              <label className="form-label">
                Custom Button Text
              </label>
              <input type="text" className="form-control" placeholder="View Products" value={this.props.form.custom_button_text} onChange={this.handleChange('custom_button_text')} />
            </div>
          </div>
          <div className="col-md-6 pls-md">
            <div className="form-group">
              <label className="form-label">
                Custom Button URL
              </label>
              <input type="text" className="form-control" placeholder="https://shopify.com/collection" value={this.props.form.custom_button_url} onChange={this.handleChange('custom_button_url')} />
            </div>
          </div>
        </div>
        <div className="row location-edit-form-group">
          <div className="col-md-6 prs-md">
            <div className="form-group">
              <label className="form-label">
                UberEats
              </label>
              <input type="text" className="form-control" placeholder="https://ubereats.com/my-restaurant" value={this.props.form.uber_eats} onChange={this.handleChange('uber_eats')} />
            </div>
          </div>
          <div className="col-md-6 pls-md">
            <div className="form-group">
              <label className="form-label">
                Postmates
              </label>
              <input type="text" className="form-control" placeholder="https://postmates.com/my-restaurant" value={this.props.form.postmates} onChange={this.handleChange('postmates')} />
            </div>
          </div>
        </div>
        <div className="row location-edit-form-group">
          <div className="col-md-6 prs-md">
            <div className="form-group">
              <label className="form-label">
                DoorDash
              </label>
              <input type="text" className="form-control" placeholder="https://doordash.com/my-restaurant" value={this.props.form.doordash} onChange={this.handleChange('doordash')} />
            </div>
          </div>
          <div className="col-md-6 pls-md">
            <div className="form-group">
              <label className="form-label">
                GrubHub
              </label>
              <input type="text" className="form-control" placeholder="https://grubhub.com/my-restaurant" value={this.props.form.grubhub} onChange={this.handleChange('grubhub')} />
            </div>
          </div>
        </div>
        <div className="row location-edit-form-group">
          <div className="col-md-6 prs-md">
            <div className="form-group">
              <label className="form-label">
                Rappi
              </label>
              <input type="text" className="form-control" placeholder="https://rappi.com/my-restaurant" value={this.props.form.rappi} onChange={this.handleChange('rappi')} />
            </div>
          </div>
          <div className="col-md-6 pls-md">
            <div className="form-group">
              <label className="form-label">
                Didi
              </label>
              <input type="text" className="form-control" placeholder="https://didi.com/my-restaurant" value={this.props.form.didi} onChange={this.handleChange('didi')} />
            </div>
          </div>
        </div>
        <div className="row location-edit-form-group">
          <div className="col-md-6 prs-md">
            <div className="form-group">
              <label className="form-label">
                Swiggy
              </label>
              <input type="text" className="form-control" placeholder="https://swiggy.com/my-restaurant" value={this.props.form.swiggy} onChange={this.handleChange('swiggy')} />
            </div>
          </div>
          <div className="col-md-6 pls-md">
            <div className="form-group">
              <label className="form-label">
                Zomato
              </label>
              <input type="text" className="form-control" placeholder="https://zomato.com/my-restaurant" value={this.props.form.zomato} onChange={this.handleChange('zomato')} />
            </div>
          </div>
        </div>
        <div className="row location-edit-form-group">
          <div className="col-md-6 prs-md">
            <div className="form-group">
              <label className="form-label">
                Deliveroo
              </label>
              <input type="text" className="form-control" placeholder="https://deliveroo.com/my-restaurant" value={this.props.form.deliveroo} onChange={this.handleChange('deliveroo')} />
            </div>
          </div>
          <div className="col-md-6 pls-md">
            <div className="form-group">
              <label className="form-label">
                JustEat
              </label>
              <input type="text" className="form-control" placeholder="https://justeat.com/my-restaurant" value={this.props.form.just_eat} onChange={this.handleChange('just_eat')} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  get submitFormBtn() {
    const { isSaving } = this.state;

    return (
      <div className="location-edit-form-bottom-submit">
        <a className={`btn btn-primary ${isSaving ? 'disabled' : ''}`} onClick={this.handleSubmit}>Save Changes</a>
        <a onClick={this.handleHideLocation} className="btn btn-default float-left">Cancel</a>
      </div>
    )
  }

  render() {
    const { hasGoogleSheetsSyncEnabled, googleSheetsUrl } = this.props;

    return (
      <form className="location-edit-form" onSubmit={this.handleSubmit}>

        <div className="location-edit-form-in">
          {this.renderSampleDeleteNotion}
          {this.renderOutOfStockNotice}
          <div className="row">
            <h2 className="location-edit-form__title">Images</h2>
          </div>
          {this.renderBannerImage}
          <LocationEditSecondaryImages
            hasImages={this.props.form.secondary_images.length > 0}
            imageCounts={this.props.form.secondary_images.length}
            images={this.props.form.secondary_images}
            onImagesUpload={this.props.updateLocation}
            showing={this.props.showing}
          />
          <div className="row">
            <h2 className="location-edit-form__title">Location data</h2>
          </div>
          <div className="row">
            <div className="col-md-7 prs-sm">
              {this.renderTitle}
            </div>
            { !hasGoogleSheetsSyncEnabled && (
              <div className="col-md-5 pls-sm">
                {this.renderPhoneNumber}
              </div>
            )}
          </div>

          {hasGoogleSheetsSyncEnabled ? (
            <div className="row">
              <p className="location-edit-form__google-sheet-title">
                You can edit your location data anytime via your{' '}
                <a href={googleSheetsUrl} target="_blank">
                  Google Sheet
                </a>.
              </p>
            </div>
          ) : (
            <>
              {this.renderAddress}
              {this.renderWebsiteAndEmail}
              {this.renderWelcomeMessage}
              {this.renderCategories}
              {this.renderHours}
              {this.renderAdvancedOptions}
              {this.renderDeliveryLinks}
              <div className="location-edit-form-delete">
                <a className="location-edit-form-delete-link" onClick={this.handleDelete}>
                  Delete Location
                </a>
              </div>
            </>
          )}
        </div>
        <div className="location-edit-form-actions">
          {this.submitFormBtn}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  let wysiwygState,
      formValues;

  if (state.locationForm.welcome_text_json && state.locationForm.welcome_text_json.hasOwnProperty('_immutable')) {
    wysiwygState = state.locationForm.welcome_text_json
  } else if (state.locationForm.id && (state.locationForm.welcome_text_json || state.locationForm.welcome_text)) {
    // Converting from database welcome_text_json or welcome_text format
    const sanitizedHtml = (state.locationForm.welcome_text || '').replace(/[\r\n]+/g, '');
    const blocksFromHTML = htmlToDraft(sanitizedHtml);
    wysiwygState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    wysiwygState = EditorState.createWithContent(wysiwygState)
  } else {
    wysiwygState = EditorState.createEmpty()
  }

  formValues = {
    address_full: state.locationForm.address_full || '',
    banner_url: state.locationForm.banner_url || '',
    categories: state.locationForm.categories || [],
    categories_available: state.locationForm.categories_available,
    custom_button_enabled: state.locationForm.custom_button_enabled,
    custom_button_text: state.locationForm.custom_button_text || '',
    custom_button_url: state.locationForm.custom_button_url || '',
    didi: state.locationForm.didi || '',
    deliveroo: state.locationForm.deliveroo || '',
    doordash: state.locationForm.doordash || '',
    email: state.locationForm.email || '',
    grubhub: state.locationForm.grubhub || '',
    id: state.locationForm.id || '',
    just_eat: state.locationForm.just_eat || '',
    latitude: state.locationForm.latitude || '',
    longitude: state.locationForm.longitude || '',
    location_hours: state.locationForm.location_hours || [],
    phone_number: state.locationForm.phone_number || '',
    postmates: state.locationForm.postmates || '',
    priority: state.locationForm.priority || false,
    rappi: state.locationForm.rappi || '',
    sample: state.locationForm.sample,
    short_description: state.locationForm.short_description || '',
    secondary_images: state.locationForm.secondary_images || [],
    status: state.locationForm.status || 'in_stock',
    swiggy: state.locationForm.swiggy || '',
    title: state.locationForm.title || '',
    website: state.locationForm.website || '',
    welcome_text_json: wysiwygState,
    uber_eats: state.locationForm.uber_eats || '',
    zomato: state.locationForm.zomato || '',
  }

  return {
    form: formValues,
    mapboxClientKey: state.locationForm.mapboxClientKey,
    s3BannerProps: state.locationForm.s3BannerProps,
    showLocationPath: state.locationForm.showLocationPath,
    listPageNum: state.locationForm.listPageNum,
  }
};

export default connect(mapStateToProps, {
  getLocations,
  hideLocation,
  updateLocation,
  saveLocation,
  deleteLocation
})(LocationEditForm);
