import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SUPPORT_EMAIL } from '../../constants'

export default class HowItWorks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeOption: 'page'
    }
  }

  toggleOption = (option) => {
    return () => {
      this.setState({ activeOption: option })
    }
  }

  get renderOverlayDetails() {
    if (this.state.activeOption !== 'overlay') return;

    return (
      <div className="how-it-works-details">
        <div className="wrapper-800">
          <div className="how-it-works-details-content">
            <div className="how-it-works-details-graphic">
              <div className="how-it-works-details-graphic-in">
                <img src={this.props.overlayImage} className="how-it-works-details-img" />
              </div>
            </div>
            <p>
              Once installed, you can use the default launcher in the corner of your website to open your map.  The launcher text and color is customizable, so you can make sure it fits your brand well.
            </p>
            <p>
              You can also hide the launcher and manually add <a href="javascript:closeby-open">html links</a> that open your map as well, giving you the ultimate flexibility on how you want to integrate the widget.
            </p>
          </div>
        </div>
      </div>
    )
  }

  get renderPageEmbedDetails() {
    if (this.state.activeOption !== 'page') return;

    return (
      <div className="how-it-works-details">
        <div className="wrapper-800">
          <div className="how-it-works-details-content">
            <div className="how-it-works-details-graphic">
              <div className="how-it-works-details-graphic-in">
                <img src={this.props.pageEmbedImage} className="how-it-works-details-img" />
              </div>
            </div>
            <p>
              You can easily embed your map within your website content without an overlay, giving you better control on how the map appears to your visitors.
            </p>
            <p>
              This can be useful if you want to have a specific page for finding your locations or plan to create sections on your website that show your locations without requiring any clicks by your user.
            </p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { activeOption } = this.state

    return (
      <div className="how-it-works">
        <div className="wrapper-800">
          <div className="features-page-section mbl">
            <div className="page-overlay-note">
              You can choose to embed your map in 2 different ways: as an <b>overlay</b> or an <b>in-page embed</b> within your website content.
            </div>
          </div>
        </div>
        <div className="wrapper-800">
          <div className="how-it-works-options clearfix">
            <a href="javascript:void(0)" className={`how-it-works-option ${activeOption === 'page' ? 'active' : ''}`} onClick={this.toggleOption('page')}>
              In-Page Embed
            </a>
            <a href="javascript:void(0)" className={`how-it-works-option ${activeOption === 'overlay' ? 'active' : ''}`} onClick={this.toggleOption('overlay')}>
              Overlay Embed
            </a>
          </div>
        </div>
        {this.renderPageEmbedDetails}
        {this.renderOverlayDetails}
      </div>
    );
  }
}
