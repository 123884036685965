import PropTypes from "prop-types";
import React, { Component } from "react";
import mapboxgl, { Marker } from 'mapbox-gl';

class MapPreview extends Component {
  render () {
    return (
      <div>
        <div className="location-edit-form-map" ref={node => this.mapNode = node}>
        </div>
      </div>
    )
  }

  get center () {
    const { latitude, longitude } = this.props
    return [longitude, latitude]
  }

  onDragEnd = () => {
    const lngLat = this.marker.getLngLat();
    this.props.onChange({
      latitude: lngLat.lat,
      longitude: lngLat.lng
    })
  }

  setMarker () {
    if (this.map) {
      this.marker = new Marker({
        draggable: true
      }).setLngLat(this.center)
        .addTo(this.map)

      this.marker.on('dragend', this.onDragEnd);
    }
  }

  componentDidUpdate () {
    if (this.marker) this.marker.remove()

    if (this.map) {
      this.map.setCenter(this.center)
      this.setMarker()
    }
  }

  componentDidMount () {
    if (this.mapNode) {
      mapboxgl.accessToken = this.props.mapboxClientKey;

      this.map = new mapboxgl.Map({
        attributionControl: false,
        container: this.mapNode,
        style: 'mapbox://styles/mapbox/streets-v9',
        minZoomNumber: 10,
        maxZoomNumber: 20,
        zoom: 15,
        center: this.center,
        interactive: true
      });
      this.map.scrollZoom.disable();
      this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
      this.setMarker();
    }
  }
}

export default MapPreview
