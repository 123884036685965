import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { DEFAULT_BROWSER_FONTS } from '../../constants/defaultBrowserFonts';

import { fetchGoogleFonts, updateMapsEditor } from '../../actions/mapsEditorActions';

export class MapFontFamilySelector extends Component {
  constructor(props) {
    const { GOOGLE_FONTS_KEY } =  window.PAGEDATA
    super(props);
    this.state = { loading: true };

    this.props.fetchGoogleFonts(GOOGLE_FONTS_KEY).then(() => {
      this.setState({ loading: false });
    })
  }

  googleFontFamilies = () => {
    let fontOptions = DEFAULT_BROWSER_FONTS;

    if (this.props.googleFontFamilies && this.props.googleFontFamilies.length) {
      fontOptions = this.props.googleFontFamilies
        .concat(DEFAULT_BROWSER_FONTS)
        .sort((a, b) => (a.label > b.label ? 1 : -1));
    }

    return fontOptions;
  }

  handleChange = option => {
    if (option) {
      const { font_family_400_url, font_family_500_url, font_family_600_url } = this.setFontWeights(option.files)

      this.props.updateMapsEditor({
        googleFontSelection: option,
        font_family: option.value,
        font_family_400_url: font_family_400_url,
        font_family_500_url: font_family_500_url,
        font_family_600_url: font_family_600_url
      })
    } else {
      this.props.updateMapsEditor({
        googleFontSelection: null,
        font_family: null,
        font_family_400_url: null,
        font_family_500_url: null,
        font_family_600_url: null
      })
    }
  }

  fontSelection = () => {
    if (this.props.font_family && this.googleFontFamilies().length) {
      return this.googleFontFamilies().find(option => option.value === this.props.font_family)
    } else {
      return null;
    }
  }

  setFontWeights = files => {
    const isGoogleFont = Object.keys(files).length > 0;

    if (isGoogleFont) {
      const fallback = files['regular'] || files['500'] || files[keys(files)[0]];

      return {
        font_family_400_url: files['400'] || fallback,
        font_family_500_url: files['500'] || fallback,
        font_family_600_url: files['600'] || fallback
      }
    } else {
      return {
        font_family_400_url: null,
        font_family_500_url: null,
        font_family_600_url: null
      }
    }
  }

  render() {
    const { font_family } = this.props;

    return (
      <div>
        <div className="maps-editor-label">
          Font Family
          <div className="maps-editor-sublabel">
            Set a custom font for your map to best match your company branding.
          </div>
        </div>
        <Select
          name="fontFamily"
          className="dropdown-md dropdown-select"
          options={this.googleFontFamilies()}
          placeholder="Select a font..."
          value={this.fontSelection()}
          onChange={this.handleChange}
          isClearable={true}
          isSearchable={true}
          menuShouldScrollIntoView
          menuPlacement={'auto'}
          isDisabled={this.state.loading}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { fetchGoogleFonts, updateMapsEditor })(MapFontFamilySelector);
