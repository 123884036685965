import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash.find';

import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState, createFromBlockArray, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { SUPPORT_EMAIL } from '../../constants';

import { updateMapsEditor } from '../../actions/mapsEditorActions';

export class MapsEditorTextOverrides extends Component {
  handleChange = name => {
    return event => {
      const { text_overrides } = this.props;
      const { target: { value } } = event;

      let newState = {...text_overrides };
      newState[name] = value;

      if (name === 'no_locations_json') {
        let wysiwygContent = this.props.form.welcome_text_json.getCurrentContent()
        newState.welcome_text_json = convertToRaw(wysiwygContent)
        newState.welcome_text = draftToHtml(newState.welcome_text_json)
      }

      this.props.updateMapsEditor({ text_overrides: newState });
    }
  }

  handleEditorChange = json_name => {
    return updatedContent => {
      const { text_overrides } = this.props;
      let newState = { ...text_overrides };
      newState[json_name] = updatedContent;

      this.props.updateMapsEditor({ text_overrides: newState });
    }
  }

  get renderLocationsLimit() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Locations Limit
          <div className="maps-editor-sublabel">
            When more locations than the limit is found (ie "Showing 200 of 621 locations. Zoom in or use the search...").
          </div>
        </div>
        <div className="row">
          <input
            type="text"
            name="text_overrides"
            value={this.props.text_overrides['locations_limit']}
            onChange={this.handleChange('locations_limit')}
            className="form-control"
            placeholder="Zoom in or use the search bar to see more locations"
            maxLength="80"
          />
        </div>
      </div>
    )
  }

  get renderLocationWebsiteLabel() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Location Website Link
          <div className="maps-editor-sublabel">
            When locations have a URL set, this is the text that will be displayed for the link.
          </div>
        </div>
        <div className="row">
          <input
            type="text"
            name="text_overrides"
            value={this.props.text_overrides['website_label']}
            onChange={this.handleChange('website_label')}
            className="form-control"
            placeholder="Visit Website"
            maxLength="80"
          />
        </div>
      </div>
    )
  }

  get renderMobileToggles() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Mobile List Toggles
          <div className="maps-editor-sublabel">
            The text to hide or show the locations list on mobile browsers. Only applicable when "Full Map" is enabled as the mobile theme style.
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={this.props.text_overrides['mobile_show_list']}
              onChange={this.handleChange('mobile_show_list')}
              className="form-control"
              placeholder="Show List"
              maxLength="12"
            />
          </div>
          <div className="col-md-6">
            <input
              type="text"
              name="text_overrides"
              value={this.props.text_overrides['mobile_hide_list']}
              onChange={this.handleChange('mobile_hide_list')}
              className="form-control"
              placeholder="View Map"
              maxLength="12"
            />
          </div>
        </div>
      </div>
    )
  }

  get renderOpenHourDays() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Open Hour Days
          <div className="maps-editor-sublabel">
            The labels for each day of the week when displaying open hours.
          </div>
        </div>
        <div className="row pbm">
          <div className="col-md-8 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['sunday_full_name'] }
              onChange={ this.handleChange('sunday_full_name') }
              className="form-control"
              placeholder="Sunday"
              maxLength="20"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['sunday_short_name'] }
              onChange={ this.handleChange('sunday_short_name') }
              className="form-control"
              placeholder="Sun"
              maxLength="6"
            />
          </div>
        </div>
        <div className="row pbm">
          <div className="col-md-8 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['monday_full_name'] }
              onChange={ this.handleChange('monday_full_name') }
              className="form-control"
              placeholder="Monday"
              maxLength="20"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['monday_short_name'] }
              onChange={ this.handleChange('monday_short_name') }
              className="form-control"
              placeholder="Mon"
              maxLength="6"
            />
          </div>
        </div>
        <div className="row pbm">
          <div className="col-md-8 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['tuesday_full_name'] }
              onChange={ this.handleChange('tuesday_full_name') }
              className="form-control"
              placeholder="Tuesday"
              maxLength="20"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['tuesday_short_name'] }
              onChange={ this.handleChange('tuesday_short_name') }
              className="form-control"
              placeholder="Tue"
              maxLength="6"
            />
          </div>
        </div>
        <div className="row pbm">
          <div className="col-md-8 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['wednesday_full_name'] }
              onChange={ this.handleChange('wednesday_full_name') }
              className="form-control"
              placeholder="Wednesday"
              maxLength="20"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['wednesday_short_name'] }
              onChange={ this.handleChange('wednesday_short_name') }
              className="form-control"
              placeholder="Wed"
              maxLength="6"
            />
          </div>
        </div>
        <div className="row pbm">
          <div className="col-md-8 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['thursday_full_name'] }
              onChange={ this.handleChange('thursday_full_name') }
              className="form-control"
              placeholder="Thursday"
              maxLength="20"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['thursday_short_name'] }
              onChange={ this.handleChange('thursday_short_name') }
              className="form-control"
              placeholder="Thu"
              maxLength="6"
            />
          </div>
        </div>
        <div className="row pbm">
          <div className="col-md-8 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['friday_full_name'] }
              onChange={ this.handleChange('friday_full_name') }
              className="form-control"
              placeholder="Friday"
              maxLength="20"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['friday_short_name'] }
              onChange={ this.handleChange('friday_short_name') }
              className="form-control"
              placeholder="Fri"
              maxLength="6"
            />
          </div>
        </div>
        <div className="row pbm">
          <div className="col-md-8 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['saturday_full_name'] }
              onChange={ this.handleChange('saturday_full_name') }
              className="form-control"
              placeholder="Saturday"
              maxLength="20"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['saturday_short_name'] }
              onChange={ this.handleChange('saturday_short_name') }
              className="form-control"
              placeholder="Sat"
              maxLength="6"
            />
          </div>
        </div>
        <hr/>
        <div className="row pbm">
          <div className="col-md-8 prl-md pts">
            Closed Day Text (if enabled in theming)
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={this.props.text_overrides['closed_day_label']}
              onChange={this.handleChange('closed_day_label')}
              className="form-control"
              placeholder="Closed"
              maxLength="15"
            />
          </div>
        </div>
      </div>
    )
  }

  get renderOpenHourDayRange() {
    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Open Hours Day Range
          <div className="maps-editor-sublabel">
            The text when indicating a range between open days. For example, "Open Mon-Fri" or "Monday to Friday".
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 prl-md">
            <input
              type="text"
              name="text_overrides"
              value={this.props.text_overrides['open_hours_available']}
              onChange={this.handleChange('open_hours_available')}
              className="form-control"
              placeholder="Open"
              maxLength="7"
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="text_overrides"
              value={ this.props.text_overrides['open_hours_day_range'] }
              onChange={ this.handleChange('open_hours_day_range') }
              className="form-control"
              placeholder="to"
              maxLength="4"
            />
          </div>
        </div>
      </div>
    )
  }

  get renderTextOptions() {
    return (
      <div className="maps-editor-form-group">
        <div className="row">
          <div className="col-md-6 prxl-md">
            <div className="pbl">
              <div className="maps-editor-label">
                Language Customization FAQ
                <div className="maps-editor-sublabel">
                  Visit <a href="https://closeby.zendesk.com/hc/en-us/articles/360017905280-Can-I-change-the-language-of-my-map-" target="_blank">this article</a> to learn more about customizing your map language.
                </div>
              </div>
            </div>
            <div className="pbxl">
              <div className="maps-editor-label">
                Search Placeholder
                <div className="maps-editor-sublabel">
                  Placeholder text shown inside the map search box.
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="text_overrides"
                  value={ this.props.text_overrides['search_placeholder'] }
                  onChange={ this.handleChange('search_placeholder') }
                  className="form-control"
                  placeholder="Search..."
                  maxLength="30"
                  required
                />
              </div>
            </div>
            <div className="pbxl">
              <div className="maps-editor-label">
                Filters Options
                <div className="maps-editor-sublabel">
                  The label to show the category filters (if being used).
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="text_overrides"
                  value={ this.props.text_overrides['show_categories'] }
                  onChange={ this.handleChange('show_categories') }
                  className="form-control"
                  placeholder="Filter Locations"
                  maxLength="30"
                  required
                />
              </div>
            </div>
            <div className="pbxl">
              <div className="maps-editor-label">
                No Locations Found
                <div className="maps-editor-sublabel">
                  The text to show the customer when no locations are found.
                </div>
              </div>
              <div className="form-control-editor">
                <Editor
                  editorState={ this.props.text_overrides['no_locations_json'] || {} }
                  toolbarClassName="wysiwyg-editor-toolbar"
                  wrapperClassName="wysiwyg-editor-wrapper"
                  editorClassName="wysiwyg-editor-content"
                  onEditorStateChange={ this.handleEditorChange('no_locations_json') }
                  toolbar={{
                    options: ['inline', 'list', 'textAlign', 'link'],
                    inline: {
                      inDropdown: true,
                      options: ['bold', 'italic', 'underline', 'strikethrough']
                    },
                    textAlign: {
                      inDropdown: true,
                      options: ['left', 'center', 'right', 'justify'],
                    },
                    list: { inDropdown: true }
                  }}
                />
              </div>
            </div>
            {this.renderLocationsLimit}
            {this.renderLocationWebsiteLabel}
            {this.renderMobileToggles}
          </div>
          <div className="col-md-6">
            <div className="pbxl">
              <div className="maps-editor-label">
                Directions Button
                <div className="maps-editor-sublabel">
                  The link automatically created to show directions to a location.
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="text_overrides"
                  value={ this.props.text_overrides['directions_button'] }
                  onChange={ this.handleChange('directions_button') }
                  className="form-control"
                  placeholder="Get Directions"
                  maxLength="20"
                  required
                />
              </div>
            </div>
            <div className="pbxl">
              <div className="maps-editor-label">
                Refresh Search
                <div className="maps-editor-sublabel">
                  The link that appears to refresh the search after moving the map.
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="text_overrides"
                  value={ this.props.text_overrides['refresh_search'] }
                  onChange={ this.handleChange('refresh_search') }
                  className="form-control"
                  placeholder="Search this area"
                  maxLength="30"
                  required
                />
              </div>
            </div>
            <div className="pbxl">
              <div className="maps-editor-label">
                Open 24 Hours
                <div className="maps-editor-sublabel">
                  The text that is displayed when a location is open 24 hours.
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="text_overrides"
                  value={ this.props.text_overrides['open_24_hours'] }
                  onChange={ this.handleChange('open_24_hours') }
                  className="form-control"
                  placeholder="Open 24 hours"
                  maxLength="30"
                />
              </div>
            </div>

            {this.renderOpenHourDays}
            {this.renderOpenHourDayRange}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="maps-editor-form-group">
        {this.renderTextOptions}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let wysiwygState,
      formValues;

  let text_overrides = state.mapsEditor.text_overrides;

  if (text_overrides['no_locations_json'] && text_overrides['no_locations_json'].hasOwnProperty('_immutable')) {
    wysiwygState = text_overrides['no_locations_json'];
  } else if (text_overrides['no_locations']) {
    const sanitizedHtml = text_overrides['no_locations'] ? text_overrides['no_locations'].replace(/[\r\n]+/g, '') : '<p></p>';
    const blocksFromHTML = htmlToDraft(sanitizedHtml);
    wysiwygState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    wysiwygState = EditorState.createWithContent(wysiwygState)
  } else {
    wysiwygState = EditorState.createEmpty();
  }

  text_overrides['no_locations_json'] = wysiwygState;

  return {
    ...state.mapsEditor,
    text_overrides: {
      ...text_overrides
    }
  }
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorTextOverrides);
