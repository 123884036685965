import React, { Component } from "react";
import Geosuggest from 'react-geosuggest';
import MapPreview from './MapPreview'

const suggestionHasRequiredData = ({ gmaps, location }) =>
  gmaps?.formatted_address && location?.lat && location?.lng;

class AddressOnelineInput extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  onSuggestSelect = (suggest) => {
    if (this.geoSuggestNode) this.geoSuggestNode.blur();
    if (!suggest) return;
    if (this.state.error) this.setState({ error: null });
    
    if (!suggestionHasRequiredData(suggest)) {
      return this.setError(new Error('Missing data for this address, please try again'));
    }
  
    const { lat: latitude, lng: longitude } = suggest.location;
  
    this.props.onChange({
      address_full: suggest.gmaps.formatted_address,
      latitude,
      longitude,
    });
  };

  onInputChange = (value) => {
    this.props.onChange({
      address_full: value,
    });
  }
  
  setError = (error) => {
    this.setState({
      error: error.message || 'Error with this address selection',
    });
  }

  render () {
    const { addressFull, latitude, longitude, mapboxClientKey, onChange } = this.props
    const { error } = this.state
    return (
      <div>
        { error && <div className='text-danger'>{error}</div> }
        <Geosuggest
          initialValue={addressFull}
          placeholder='320 Pioneer Way, Mountain View, CA 94041, USA'
          inputClassName='form-control'
          onSuggestSelect={this.onSuggestSelect}
          onChange={this.onInputChange}
          ref={this.geoSuggestNode}
        />
        { (latitude && longitude) ? (
          <MapPreview 
            latitude={latitude}
            longitude={longitude}
            mapboxClientKey={mapboxClientKey}
            onChange={onChange}
          /> 
        ) : (
          <div className="location-edit-form-map-empty">
            No location set. Please select an address to save this location.
          </div>
        )}
      </div>
    )
  }
}

export default AddressOnelineInput
