import PropTypes from "prop-types"
import React, { Component } from "react"
import API_ENDPOINTS from "../../constants/apiEndpoints"
import apiClient from "../../services/apiClient"
import { createFlash } from "../../shared/createFlash"

export default class StripeCustomerDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: props.stripeCustomer.description
    };
  };

  updateStripeCustomer = () => {
    apiClient.post(API_ENDPOINTS.SUBSCRIPTIONS.UPDATE_CUSTOMER, {
      description: this.state.description
    })
      .then(response => createFlash(response.data.message, 'notice'));
  };

  handleChange = (e) => {
    this.setState({ description: e.target.value })
  };

  render() {
    return (
      <div>
        <div className="mbl">
          <textarea name="description" className="form-control" value={this.state.description} onChange={this.handleChange}/>
        </div>
        <div>
          <a className="btn btn-primary" onClick={this.updateStripeCustomer}>
            Save Invoice Details
          </a>
        </div>
      </div>
    );
  }
}

StripeCustomerDetails.propTypes = {
  stripeCustomer: PropTypes.object.isRequired,
}
