import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash.find';

import { mapboxStyles } from '../../constants/mapThemeOptions';

import { updateMapsEditor } from '../../actions/mapsEditorActions';

const MAP_STYLE_OPTIONS = [
  { value: 'standard_list_style', label: 'Standard Location List (default)' },
  { value: 'image_list_style', label: 'Location & Image List' },
  { value: 'column_list_style', label: '2-Column Location Image List' },
];

const MOBILE_STYLE_OPTIONS = [
  { value: 'mobile_map_list_style', label: 'Small Map & List View (default)' },
  { value: 'mobile_map_search_style', label: 'Full Map & Search View' },
];

export class MapsEditorStylePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customStyleUrl: this.defaultStyleIsSelected(props.style) ? '' : props.style,
      customStyleUrlSaved: false,
      usingCustomStyle: this.defaultStyleIsSelected(props.style) ? false : true,
    }
  }

  defaultStyleIsSelected = (styleUrl) => {
    return find(mapboxStyles, { url: styleUrl });
  }

  handleDefaultStyleChange = (style) => {
    return () => {
      this.props.updateMapsEditor({ style: style })
      this.setState({ usingCustomStyle: false, customStyleUrl: '' });
    }
  }

  handleStyleTextFieldChange = () => {
    return (e) => {
      this.setState({ customStyleUrl: e.target.value })
    }
  }

  handleChange = (name) => {
    return event => {
      const { target: { value } } = event;
      this.props.updateMapsEditor({ [name]: value });
    }
  }

  setCustomStyle = () => {
   this.setState({ usingCustomStyle: true, customStyleUrlSaved: true });
   this.props.updateMapsEditor({ style: this.state.customStyleUrl });
  }

  renderStyles = () => {
    return mapboxStyles.map((style, key) => {
      const activeStyle = !this.state.usingCustomStyle && this.props.style === style.url;

      return (
        <div className="col-sm-3 maps-editor-style-option" key={key}>
          <label className={`maps-editor-style-option-in ${activeStyle ? 'active' : ''}`}>
            <input
              className="d-none"
              type="radio"
              name="style"
              value={style.url}
              checked={activeStyle}
              onChange={this.handleDefaultStyleChange(style.url)} />

            <img src={style.image} className="maps-editor-style-img" />
            <div className="maps-editor-style-name">
              {style.name}
            </div>
          </label>
        </div>
      )
    });
  }

  renderCustomStyleOption = () => {
    const { mapbox_client_key } = this.props;
    const mapboxClientKeyExists = mapbox_client_key && mapbox_client_key.length > 50 && mapbox_client_key.includes('pk.');

    if (!mapboxClientKeyExists) return;

    return (
      <div className="col-sm-6 maps-editor-style-option">
        <div className={`maps-editor-style-option-in no-pointer ${this.state.usingCustomStyle ? 'active' : ''}`}>
          <div className="pal">
            <div className="maps-editor-style-option-custom-details">
              <div><b>Use Custom Mapbox Style</b></div>
              <div className="maps-editor-style-option-custom-desc">
                <p>Add the Style URL from your Mapbox account to use a custom style. Learn more <a href="https://closeby.zendesk.com/hc/en-us/articles/360015805299" target="_blank">here</a>.</p>
              </div>
            </div>

            <input
              type="text"
              value={this.state.customStyleUrl}
              onChange={this.handleStyleTextFieldChange()}
              className="form-control mbm"
              placeholder="mapbox://styles..."
            />

            {this.state.customStyleUrl !== this.props.style ? (
              <a className="btn btn-default" onClick={this.setCustomStyle}>
                Enable Style
              </a>
            ) : (
              <a className="btn btn-default disabled" disabled>
                {this.state.customStyleUrlSaved ? 'Custom Style Set.' : 'Enable Custom Style'}
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }

  renderLayoutSetting = () => {
    const { list_style } = this.props;

    return (
      <div className="maps-editor-form-group">
        <div className="form-radio-group">
          {
            MAP_STYLE_OPTIONS.map((option) => {
              return (
                <label className="form-radio-label" key={option.value}>
                  <input
                    type="radio"
                    name={option.value}
                    value={option.value}
                    checked={list_style === option.value}
                    onChange={this.handleChange('list_style')}
                    className="form-radio-input"
                  />
                  <div className="form-radio-label-copy">
                    {option.label}
                  </div>
                </label>
              )
            })
          }
        </div>
        <div className="maps-editor-default-position-notice">
          <div className={`maps-editor-layout-web-preview ${list_style}`} />
        </div>
      </div>
    )
  }

  renderMobileLayoutSetting = () => {
    const { mobile_style } = this.props;

    return (
      <div className="maps-editor-form-group">
        <div className="form-radio-group">
          {
            MOBILE_STYLE_OPTIONS.map((option) => {
              return (
                <>
                  <label className="form-radio-label" key={option.value}>
                    <input
                      type="radio"
                      name={option.value}
                      value={option.value}
                      checked={mobile_style === option.value}
                      onChange={this.handleChange('mobile_style')}
                      className="form-radio-input"
                    />
                    <div className="form-radio-label-copy">
                      {option.label}
                    </div>
                  </label>
                  {option.value === 'mobile_map_search_style' && (
                    <div className="maps-editor-layout-mobile-note">
                      Note: Users can toggle the still list view when viewing the map
                    </div>
                  )}
                </>
              )
            })
          }
        </div>
        <div className="maps-editor-default-position-notice">
          <div className={`maps-editor-layout-web-preview ${mobile_style}`} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6 prl">
            <div className="maps-editor-label">
              Full Layout
            </div>
            {this.renderLayoutSetting()}
          </div>
          <div className="col-sm-6 pll">
            <div className="maps-editor-label">
              Mobile Layout
            </div>
            <div>
              {this.renderMobileLayoutSetting()}
            </div>
          </div>
        </div>
        <div className="maps-editor-form-group">
          <div className="maps-editor-label">
            Map Style
            <div className="maps-editor-sublabel">
              Select an underlying style to use for your map. If you'd like to use your own custom style using <a href="https://www.mapbox.com/mapbox-studio" target="_blank">Mapbox Studio</a>, you can learn more <a href="https://closeby.zendesk.com/hc/en-us/articles/360015805299" target="_blank">here</a>.
            </div>
          </div>
          <div className="container-fluid">
            <div className="row maps-editor-style-row">
              {this.renderStyles()}
              {this.renderCustomStyleOption()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorStylePicker);
