import queryString from 'query-string'

const URLS = {
  INTEGRATIONS: {
    INDEX: '/integrations',
    CSV: '/integrations/csv',
    GOOGLE_SHEETS: '/integrations/google_sheets',
    CSV_TEMPLATE: '/integrations/csv_template',
    CSV_TEMPLATE_SAMPLE: '/integrations/csv_template_sample'
  },
  LOCATIONS: {
    INDEX: '/locations'
  },
  MAPS: {
    INDEX: '/maps',
    EDIT: key => `/maps/${key}/edit`,
    INSTALL: '/maps/install'
  },
  MARKETING: {
    FEATURES: '/features'
  },
  FAQ: {
    INDEX: 'https://closeby.zendesk.com/hc/en-us',
    SETUP: 'https://closeby.zendesk.com/hc/en-us/sections/360001472694-Setting-Up-Your-Map',
    SHOPIFY: 'https://closeby.zendesk.com/hc/en-us/articles/360006991453-Shopify-store-instructions'
  },
  OAUTH: {
    SHOPIFY: '/users/auth/shopify',
    GOOGLE: '/users/auth/google_oauth2'
  },
  SHOPIFY_SUBSCRIPTIONS: {
    ENABLE: '/shopify_subscriptions/enable'
  },
  SUBSCRIPTIONS: {
    INDEX: (params = {}) => `/subscriptions?${queryString.stringify(params)}`
  },
  SELECT_PLAN: {
    INDEX: '/subscriptions/select_plan',
  },
}

export default URLS;
