import React, { useState } from 'react';

import { saveMapboxKey } from '../../actions/mapsEditorActions';
import { createFlash } from '../../shared/createFlash';

const MapsMapboxContent = (props) => {
  const [state, setState] = useState({
    showing: !props.mapbox_client_key,
    showingOverageFees: false,  
    mapbox_client_key: props.mapbox_client_key,
  });

  const { setKey } = props;

  const handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()

    saveMapboxKey(props.mapId, {
      map: {
        mapbox_client_key: state.mapbox_client_key
      }
    })
    .then(() => {
      createFlash('Your map changes have been saved!', 'success');
      setKey(state.mapbox_client_key);
      setState((prevState) => ({ ...prevState, showing: false }));
    }).catch((error) => {
      if (error.response && error.response.status === 422) {
        const validationErrorMessage = error.response.data.message || 'Please provide a valid Mapbox Access Token to proceed with installation instructions.';
        createFlash(validationErrorMessage, 'error');
      } else {
        createFlash('Sorry, there was a problem saving your changes.', 'error');
      };
    });
  };

  const handleChange = (text) => {
    setState((prevState) => ({ ...prevState, mapbox_client_key: text }))
  };

  const toggleContent = () => {
    setState((prevState) => ({ ...prevState, showing: !state.showing }))
  };

  // pending implementation
  // const toggleOverageFees = () => {
  //   this.setState((prevState) => ({ ...prevState, showingOverageFees: !this.state.showingOverageFees }))
  // };

  const {
    mapbox_client_key
  } = props

  return (
    <div className={`page-setup-section clearfix ${state.showing ? 'showing' : 'collapsed'}`}>
      <div className="page-setup-content">
        <h2 className="page-setup-section-title">
          <span className="page-setup-section-title-copy" onClick={toggleContent}>
            Activate Your Map
            </span>
          <i className={`page-setup-section-title-toggle ion-chevron-up ${state.showing ? '' : 'active'}`} onClick={toggleContent} />
          <i className={`page-setup-section-title-completed ion-checkmark-circled ${state.mapbox_client_key && state.mapbox_client_key.length && state.mapbox_client_key.startsWith('pk.ey') ? '' : 'incomplete'}`} />
        </h2>
        {state.showing && (
          <div className="page-setup-content-section-border">
            <div>
              <div className="page-setup-header">
                Set your Mapbox access token to use your map publicly.
              </div>
              <div className="mbl">
                <p>
                  Create an account with <a href="https://mapbox.com" target="_blank">Mapbox</a> and save your access token below to activate your map. This will let allow you to use your map publicly on your own website.
                  &nbsp;<a href="https://closeby.zendesk.com/hc/en-us/articles/360016168700" target="_blank">Learn More</a>
                </p>
              </div>
              <div>
                <form onSubmit={handleSubmit}>
                  <label htmlFor="mapbox_client_key">
                    Mapbox Access Token <span className="form-label-required">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-9 pbm pbn-md prl-md">
                      <div>
                        <input type="text" className="form-control" name="mapbox_client_key" placeholder="pk.eyJ1Iji2..." value={state.mapbox_client_key} onChange={(e) =>{ console.log(e); handleChange(e.target.value)}} required />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <button type="submit" className="btn-block btn btn-primary">
                        {mapbox_client_key && mapbox_client_key.length ? 'Save Changes' : 'Activate Map'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="page-setup-field-note">
                Note: If you do not add a valid Mapbox token to your account, you will be billed for overage fees in addition to your base plan.{' '}
                <a onClick={toggleOverageFees}>
                  {state.showingOverageFees ? 'Hide Overage Fees' : 'View Overage Fees'}
                </a>

                {this.state.showingOverageFees && (
                  <div className="page-setup-overage-fees">
                    <p>With a valid Mapbox access token, you will only be charged the base price of your subscription.</p>
                    <p>Without a Mapbox access token, your base subscription has up to 100 locations and 1000 public map views per month. For volume beyond that, overage fees apply at +{window.PAGEDATA.currentExpansionPrice} per additional 100 locations or 1000 public map views per month. <a href="https://closeby.zendesk.com/hc/en-us/articles/360006914654" target="_blank">Learn More</a></p>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapsMapboxContent;
