import PropTypes from "prop-types";
import React, { Component } from "react";
import mapboxgl from 'mapbox-gl';
import { MAPS_EDITOR_MODAL_ID } from '../../constants/index'

class MapsEditorDefaultMap extends Component {
  componentDidUpdate () {
    this.setMapBoundsByCoordinates()
  }

  componentDidMount () {
    if (this.mapNode) {
      mapboxgl.accessToken = this.props.appMapboxKey;

      this.map = new mapboxgl.Map({
        attributionControl: false,
        container: this.mapNode,
        style: 'mapbox://styles/mapbox/streets-v9',
        interactive: false
      });
      this.map.scrollZoom.disable();

      this.map.resize()
      this.setMapBoundsByCoordinates()
    }
  }

  setMapBoundsByCoordinates = () => {
    if (this.boundsCoordinatesArePresent()) {
      const mapBounds = [this.props.southwestCoordinates, this.props.northEastCoordinates];
      this.map.fitBounds(mapBounds, { duration: 0 })
    }
  }

  boundsCoordinatesArePresent = () => {
    return this.props.southwestCoordinates &&
           typeof this.props.southwestCoordinates[0] !== 'undefined' &&
           this.props.southwestCoordinates[0] !== ''
  }

  render () {
    return (
      <div className="maps-editor-default-map-wrapper">
        <div>
          <div className={`maps-editor-default-map ${this.boundsCoordinatesArePresent() ? '' : 'disabled'}`} ref={node => this.mapNode = node}>
          </div>
        </div>
      </div>
    )
  }
}

MapsEditorDefaultMap.propTypes = {
  southwestCoordinates: PropTypes.array,
  northEastCoordinates: PropTypes.array
}

export default MapsEditorDefaultMap
