import PropTypes from 'prop-types';
import React, { Component, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';

import { useClickAway } from '../../shared/useClickAway.js'

export const MapsEditorColorPicker = (props) => {
  const activeColor = props.css[props.name] || props.defaultColor;

  const [state, setState] = useState({
    showing: null,
    color: props.css[props.name],
    colors: [
      props.defaultColor,
      '#ea704b',
      '#715f30',
      '#88852b',
      '#5eaf44',
      '#30bd68',
      '#406bcc',
      '#0a7cbd',
      '#FFFFFF',
      '#514fb1',
      '#E96464',
      '#d03434',
      '#cc7021',
      '#7d35a5',
      '#724f98',
      '#000000',
    ],
    showingPicker: false,
  });

  const colorPickerRef = useRef(null);

  const handleChangeComplete = color => {
    if (!props.name) return;

    const newColor =
      color.rgb && color.rgb.a != 1
        ? `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
        : color.hex;

    let newProps = { ...props.css };
    newProps[props.name] = newColor;

    setState({ ...state, color: newColor, showingPicker: true });
    props.handleChange(newProps);
  };

  const openPicker = () => {
    setState({ ...state, showingPicker: true });
  }

  const closePicker = () => {
    setState({ ...state, showingPicker: false });
  }

  // useClickAway(colorPickerRef, () => {
  //   if (state.showingPicker) closePicker();
  // })

  return (
    <div className="relative" ref={colorPickerRef}>
      <div>
        {state.showingPicker ? (
          <div className="relative">
            <div
              className="form-field-uneditable form-color-picker"
              onClick={openPicker}
            >
              <div
                className="form-color-picker-preview"
                style={{ backgroundColor: activeColor }}
              ></div>
              {activeColor}
            </div>
            <a onClick={closePicker}>
              <i className="ion-close form-color-picker-close" />
            </a>
          </div>
        ) : (
          <div
            className="form-field-uneditable form-color-picker"
            onClick={openPicker}
          >
            <div
              className="form-color-picker-preview"
              style={{ backgroundColor: activeColor }}
            ></div>
            {activeColor}
          </div>
        )}

      </div>
      <div className="form-color-picker-menu">
        {state.showingPicker && (
          <SketchPicker
            width={200}
            color={activeColor}
            onChangeComplete={handleChangeComplete}
            presetColors={state.colors}
          />
        )}
      </div>
    </div>
  );
}
