import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TwitterPicker } from 'react-color';
import Select from 'react-select';

import { updateMapsEditor } from '../../actions/mapsEditorActions';
import MapsEditorCategoryForm from './MapsEditorCategoryForm';
import MapsEditorLocationImageForm from './MapsEditorLocationImageForm';
import MapFontFamilySelector from './MapFontFamilySelector';
import { MapsEditorMarkerImage } from './MapsEditorMarkerImage';

export class MapsEditorBrandSettings extends Component {
  handleColorChange = (color) => {
    this.props.updateMapsEditor({ primary_color: color.hex })
  }

  handleChange = (name) => {
    return event => {
      const { target: { value } } = event;
      this.props.updateMapsEditor({ [name]: value });
    }
  }

  handleSelectChange = (name) => {
    return (selectedOption) => {
      this.props.updateMapsEditor({ [name]: selectedOption.value });
    }
  }

  handleCheckboxChange = (name) => {
    return event => {
      this.props.updateMapsEditor({ [name]: event.target.checked });
    }
  }

  handleEditorChange = (name) => {
    return value => {
      this.props.updateMapsEditor({ [name]: value });
    }
  }

  handleMarkerChange = backgroundUrl => {
    this.props.updateMapsEditor({
      marker_background_url: backgroundUrl
    })
  }

  handleImageChange = locationDefaultImage => {
    this.props.updateMapsEditor({
      location_default_image: locationDefaultImage
    })
  }

  handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()

    this.props.saveMapEditor(this.props.mapId, { map: this.props })
      .then(json => {
        createFlash('Your theming changes have been saved!', 'success')
      }).catch((error) => {
        createFlash('Sorry, there was a problem saving your changes.', 'error')
      })
  }

  removeDefaultViewport = () => {
    this.props.updateMapsEditor({
      default_viewport_label: '',
      default_viewport_sw_longitude: '',
      default_viewport_sw_latitude: '',
      default_viewport_ne_longitude: '',
      default_viewport_ne_latitude: ''
    })
  }

  get renderButtonStyle() {
    const { button_style } = this.props;
    const options = [
      { value: 'button_rounded', label: 'Completely rounded buttons (default)' },
      { value: 'button_rectangular_sharp', label: 'Rectangular buttons with sharp edges' },
      { value: 'button_rectangular_rounded', label: 'Rectangular buttons with rounded edges' }
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Button Style
          <div className="maps-editor-sublabel">
            This style applies to the "Directions" button and any custom button you add.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="button_style"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === button_style)}
            onChange={this.handleSelectChange('button_style')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
      </div>
    );
  }

  get renderCategories() {
    const { category_colors_enabled, category_images_enabled, marker_style } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          { marker_style !== 'marker_custom' ? 'Category Colors' : 'Category Images' }
          <div className="maps-editor-sublabel">
            Set specific { marker_style !== 'marker_custom' ? 'colors' : 'images'} for each category to display for your visitors. Location pins on your map will use the { marker_style !== 'marker_custom' ? 'colors' : 'images'} of the first category added to it. Category { marker_style !== 'marker_custom' ? 'colors' : 'images'} will not be used if zoomed out and locations are clustered.
          </div>
        </div>
        <div>
          <div>
          { marker_style !== 'marker_custom' ? 
            <label className="form-checkbox-label">
              <input
                type="checkbox"
                name="category_colors_enabled"
                className="form-checkbox-input"
                checked={category_colors_enabled}
                onChange={this.handleCheckboxChange('category_colors_enabled')}
              />
              <div className="form-checkbox-label-copy">
                Enable category-specific colors
              </div>
            </label>
            :
            <label className="form-checkbox-label">
              <input
                type="checkbox"
                name="category_images_enabled"
                className="form-checkbox-input"
                checked={category_images_enabled}
                onChange={this.handleCheckboxChange('category_images_enabled')}
              />
              <div className="form-checkbox-label-copy">
                Enable category-specific images
              </div>
            </label>
          }
          </div>
          {( (marker_style !== 'marker_custom' && category_colors_enabled) || (marker_style == 'marker_custom' && category_images_enabled)) && (
            <div className="maps-editor-categories-list">
              {this.renderCategoryColors}
            </div>
          )}
        </div>
      </div>
    )
  }

  get renderCategoryColors() {
    const { categories, categories_attributes, primary_color } = this.props;
    if (!categories || categories.length === 0) return;

    return categories.map((category, i) => {
      return (
        <MapsEditorCategoryForm id={category.id} name={category.name} primaryColor={primary_color} image={category.marker_background_url} color={category.color} key={i} categories_attributes={categories_attributes} />
      )
    })
  }

  get renderCategoriesLocationImages() {
    const { category_location_images_enabled } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Category Location Images
          <div className="maps-editor-sublabel">
            Set a default location image for each category. Location images will be displayed in the location popup when a user clicks on a location pin.
          </div>
        </div>
        <div>
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="category_location_images_enabled"
              className="form-checkbox-input"
              checked={category_location_images_enabled}
              onChange={this.handleCheckboxChange('category_location_images_enabled')}
            />
            <div className="form-checkbox-label-copy">
              Enable category-specific location images
            </div>
          </label>
          {category_location_images_enabled && (
            <div className="maps-editor-categories-list">
              {this.renderCategoryLocationImages}
            </div>
          )}
        </div>
      </div>
    )
  }

  get renderCategoryLocationImages() {
    const { categories, categories_attributes } = this.props;
    if (!categories?.length) return;
  
    return categories.map(({ id, name, location_image }, i) => (
      <MapsEditorLocationImageForm
        key={i}
        id={id}
        name={name}
        location_image={location_image}
        categories_attributes={categories_attributes}
      />
    ));
  }

  get renderMarkerIconImage() {
    const { marker_style } = this.props;
    const markerMaxFileSize = 75000;

    if (marker_style !== 'marker_custom') return;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Default Custom Marker Icon
          <div className="maps-editor-sublabel">
            Upload a default custom icon image 60px x 80px to use as the marker icon on your map. PNG (recommended), JPG and JPEG formats supported. This will override any category colors you set for your location pins.
          </div>
        </div>
        <MapsEditorMarkerImage
          name="marker_background_url"
          value={this.props.marker_background_url}
          handleChange={this.handleMarkerChange}
          s3MarkerFormData={this.props.s3MarkerFormData}
          s3MarkerPostUrl={this.props.s3MarkerPostUrl}
          s3MarkerPostHost={this.props.s3MarkerPostHost}
          maxFileSize={markerMaxFileSize}
        />
      </div>
    )
  }

  get renderLocationDefaultImage() {
    const imageMaxFileSize = 10000000;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Default Location Image
          <div className="maps-editor-sublabel">
            Upload a default image to use for locations that don't have an image set. PNG (recommended), JPG and JPEG formats supported.
          </div>
        </div>
        <MapsEditorMarkerImage
          name="location_default_image"
          value={this.props.location_default_image}
          handleChange={this.handleImageChange}
          s3MarkerFormData={this.props.s3MarkerFormData}
          s3MarkerPostUrl={this.props.s3MarkerPostUrl}
          s3MarkerPostHost={this.props.s3MarkerPostHost}
          maxFileSize={imageMaxFileSize}
        />
      </div>
    )
  }

  get renderMarkerStyle() {
    const { marker_style } = this.props;
    const options = [
      { value: 'marker_default', label: 'Default Map Pin' },
      { value: 'marker_circle', label: 'Circle Icon' },
      { value: 'marker_custom', label: 'Custom Image' },
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Marker Style
          <div className="maps-editor-sublabel">
            This style applies to the location pins on your map.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="marker_style"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === marker_style)}
            onChange={this.handleSelectChange('marker_style')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>

        {marker_style === 'marker_default' && (
          <div className="maps-editor-form-marker-preview default-pin" />
        )}

        {marker_style === 'marker_circle' && (
          <div className="maps-editor-form-marker-preview circle-pin" />
        )}
      </div>
    );
  }

  get renderGeneral() {
    const {
      list_style,
      primary_color,
      suggestion_range,
      suggestion_strict,
      suggestion_type,
      mapbox_client_key,
    } = this.props;

    return (
      <div className="maps-editor-form-group">
        <div className="row">
          <div className="col-md-6 prxl-md">
            <div className="maps-editor-form-group">
              <div className="maps-editor-label">
                Primary Color
                <div className="maps-editor-sublabel">
                  Darker shade recommended.  This color is used for your location's buttons and links, map markers and popups.
                </div>
              </div>

              <TwitterPicker
                color={primary_color}
                onChangeComplete={this.handleColorChange}
                triangle={'hide'}
                colors={['#ea704b', '#715f30', '#88852b', '#5eaf44', '#30bd68', '#406bcc', '#0a7cbd', '#8195a7', '#514fb1', '#bf1b46', '#d03434', '#cc7021', '#7d35a5', '#724f98', '#4a4a4a']}
              />
            </div>

            {this.renderButtonStyle}
            {this.renderHideLocationCategories}
            {this.renderMarkerStyle}
            {this.renderMarkerIconImage}
            {this.renderLocationDefaultImage}
          </div>
          <div className="col-md-6">
            <div className="maps-editor-form-group">
              <MapFontFamilySelector />
            </div>
            {this.renderCategories}
            {this.renderCategoriesLocationImages}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderGeneral}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorBrandSettings);
