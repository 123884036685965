import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import { SUPPORT_EMAIL } from '../../constants'

import LocatorSpinner from '../locator/LocatorSpinner'

class ImportTaskRow extends Component {
  componentDidMount () {
    if (this.isInPollingState) {
      this.pollForProcessing()
    }
  }

  get isInPollingState () {
    return this.isProcessing && !this.isStalled
  }

  pollForProcessing () {
    if (!this.isInPollingState) return
    setTimeout(() => {
      this.props.updateImportTask(this.props.task.id).then(() => {
        this.pollForProcessing()
      })
    }, 5000)
  }

  get isStalled () { return this.props.task.state === 'stalled' }
  get isSomeSuccess () { return this.props.task.state === 'success_some' }
  get isAllSuccess () { return this.props.task.state === 'success_all' }
  get isFatalError () { return this.props.task.state === 'fatal_error' }
  get isProcessing () { return ['uploaded', 'processing'].includes(this.props.task.state) }

  get renderSpinner () {
    if (this.isInPollingState) {
      return (
        <div className="mtl mbl text-center">
          <LocatorSpinner />
        </div>
      )
    }
  }

  get renderStatusTag () {
    let className
    let label
    if (this.isAllSuccess) {
      className = 'badge-success'
      label = 'Success!'
    }
    if (this.isSomeSuccess) {
      className = 'badge-warning'
      label = 'Some rows failed'
    }
    if (this.isProcessing) {
      className = 'badge-info'
      label = 'Processing...'
    }
    if (this.isFatalError) {
      className = 'badge-danger'
      label = 'Error'
    }
    if (className && label) {
      return <span className={`integrations-import-task-status badge ${className}`}>{label}</span>
    }
  }

  get renderMsgs () {
    return (
      <div>
        { this.renderSuccessMsg }
        { this.renderErrors }
      </div>
    )
  }

  get renderErrors () {
    const { problems } = this.props.task
    if (problems.fatal) {
      return (
        <div className="text-danger p1-1">
          { problems.fatal }
        </div>
      )
    }
    if (problems.unprocessable) {
      return (
        <div className="text-danger p1-1">
          {problems.unprocessable.includes('and') && (
            <>
              <p>
                Your current plan does not support that many locations, and your Mapbox key has not been set.
              </p>
              <ul>
                <li>
                  <span>
                    Add a Mapbox key{' '}
                    <a
                      href="/maps/install"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-danger__link"
                    >
                      here
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    Upgrade your plan{' '}
                    <a
                      href="/subscriptions/select_plan"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-danger__link"
                    >
                      here
                    </a>
                  </span>
                </li>
              </ul>
            </>
          )}
          {problems.unprocessable.includes('mapbox key') && !problems.unprocessable.includes('and') && (
            <>
              <p>Your Mapbox key has not been set.</p>
              <ul>
                <li>
                  <span>
                    Add a Mapbox key{' '}
                    <a
                      href="/maps/install"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-danger__link"
                    >
                      here
                    </a>
                  </span>
                </li>
              </ul>
            </>
          )}
          {problems.unprocessable.includes('plan') && !problems.unprocessable.includes('and') && (
            <>
              <p>Your current plan does not support that many locations.</p>
              <ul>
                <li>
                  <span>
                    Upgrade your plan{' '}
                    <a
                      href="/subscriptions/select_plan"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-danger__link"
                    >
                      here
                    </a>
                  </span>
                </li>
              </ul>
            </>
          )}
        </div>
      )
    }
    if (problems.rows) {
      return Object.keys(problems.rows).map((rowNumber) => {
        return <div key={rowNumber} className="text-danger">Problem with row {rowNumber}: { problems.rows[rowNumber] }</div>
      })
    }
  }

  get renderSuccessMsg () {
    const { success } = this.props.task
    return success.message ? <div className="text-success">{ success.message }</div> : null
  }

  get renderStalled () {
    if (this.isStalled) {
      return <div>Oops! This import seems to be taking too long. We most likely ran into an unexpected issue. Please reach out to <a href={`mailto:${SUPPORT_EMAIL}`}>our support</a> so we can help.</div>
    }
  }

  get renderTitle () {
    const { task } = this.props
    switch (task.type) {
      case 'GOOGLE_SHEETS': {
        return 'Google Sheets Import'
      }
      case 'CSV': {
        return 'New CSV file Imported -'
      }
    }
  }

  render () {
    const { task, hidingUploadForm, taskIndex } = this.props
    const createdAt = moment(task.created_at)

    return (
      <div className="integrations-import-task">
        <div className="integrations-import-task-title overflow-ellipsis">
          { this.renderTitle }&nbsp;
          <a
            href={`/import_tasks/${task.id}/download_file`}
            target="_blank"
            className="overflow-ellipsis"
          >
            {task.original_filename}
          </a>
        </div>
        <div className="integrations-import-task-timestamp overflow-ellipsis" title={createdAt.toDate()}>
          {createdAt.fromNow()}
          {task.success_count ? ` - ${task.success_count} rows imported` : null}
        </div>
        { this.renderStalled }
        { this.renderStatusTag }
        { this.renderSpinner }
        { this.renderMsgs }
      </div>
    )
  }
}

function ImportTasksList ({ importTasks = [], hidingUploadForm, showUploadForm, updateImportTask }) {
  return importTasks.map((task, index) => {
    return <ImportTaskRow
             key={task.id}
             taskIndex={index}
             task={task}
             hidingUploadForm={hidingUploadForm}
             showUploadForm={showUploadForm}
             updateImportTask={updateImportTask}
           />
  })
}

export default ImportTasksList
