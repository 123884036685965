import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { createFlash } from "../../shared/createFlash";

export class MapsEditorMarkerImage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false
    }
  }

  componentDidMount() {
    const {
      s3MarkerFormData,
      s3MarkerPostUrl,
      s3MarkerPostHost,
      handleChange,
      maxFileSize,
    } = this.props;

    $(this.fileInput).fileupload({
      fileInput: this.fileInput,
      url: s3MarkerPostUrl,
      type: 'POST',
      autoUpload: true,
      formData: s3MarkerFormData,
      paramName: 'file',      //  S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType: 'XML',        //  S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      start: (e) => {
        this.setState({ uploading: true });
      },
      submit: function (e, data) {
        $.each(data.files, function(i, file) {
          
          if (file.size > maxFileSize) {
            createFlash(`File size exceeds the maximum limit of ${maxFileSize === 75000 ? '75KB' : '10MB'}.`, 'error');
            return false;
          }

          file.uploadName = encodeURI(file.name.replace(/\s+/g, '_').replace(/\+/g, '-'));
        });
      },
      done: (e, data) => {
        //  Extract key and generate URL from response
        $.each(data.files, function(i, file) {
          if (file.size < maxFileSize) {
            const uploaderKey = s3MarkerFormData.key.replace('${filename}', '');
            let key = $(data.jqXHR.responseXML).find('Key').text();
            const url = encodeURI(`//${s3MarkerPostHost}/${key}`);
            const filename = key.replace(uploaderKey, '');
            handleChange(url);
          }
        });
        this.setState({ uploading: false });
      },
      fail: (e, data) => {
        createFlash('Sorry there was a problem uploading your image.', 'error')
      }
    });
  }

  handleRemoveBackground = (e) => {
    const { handleChange } = this.props;

    e.preventDefault()
    handleChange(null);
    this.fileInput.value = '';
  }

  valueExists = () => {
    const { value } = this.props;
    return value && value.length;
  }

  coercedValue = () => {
    const { value } = this.props;

    if (value && value.length && value.startsWith('//')) {
      return `https:${value}`;
    }

    return value;
  }

  render() {
    const {
      name,
      value
    } = this.props;

    return (
      <div className={`maps-editor-marker-image ${ this.valueExists() ? 'with-image' : '' }`}>
        {this.valueExists() ? (
          <a
            href="javascript:void(0)"
            className="maps-editor-marker-image-delete clearfix"
            onClick={ this.handleRemoveBackground }
          >
            <span className="ion-android-close"></span>
          </a>
        ) : (
          <div className="maps-editor-marker-image-placeholder">
            Upload
          </div>
        )}

        <a
          href="javascript:void(0)"
          className="maps-editor-marker-image-display clearfix"
          onClick={() => { this.fileInput.click() }}
        >
          <img src={this.coercedValue()} alt="" />
        </a>

        {this.state.uploading && (
          <div className="maps-editor-marker-image-uploading">
            <div className="maps-editor-marker-image-uploading-copy">...</div>
          </div>
        )}

        <input
          type="file"
          name={ name }
          className="hidden"
          ref={(input) => { this.fileInput = input }}
        />
      </div>
    );
  }
}
